<script>
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: this.title ? true : false,
        text: this.title,
      },
      legend: {
        position: "right",
      },
      onClick: this.handleClick, // Registering click event handler
    };
    this.renderChart(this.chartData, options);
  },
  methods: {
    handleClick(event, items) {
      if (items.length > 0) {
        // Extract the data that was clicked
        const clickedDataIndex = items[0]._index;

        // Get the ID associated with the clicked segment
        const clickedId = this.chartData.datasets[0].ids[clickedDataIndex];

        console.log("ID:", clickedId);
        // Navigate to another page with the clicked data
        // this.$router.push({ name: 'details', params: { data: clickedData, id: clickedId }});
      }
    },
  },
};
</script>
