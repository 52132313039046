import { computed, ref } from "vue";
import { apolloWatchQuery } from "../../../apollo-client/helper";
import { MANAGEMENT_DASHBOARD, MONTHLY_SUBSCRIPTIONS } from "./graphql";
import {
  DoughnutChartBgColors,
  calculateTotalMonthlySubscriptions,
  conversionRate,
  getLast12Months,
} from "./helper";
import { LIST_ORGANIZATION_DESCENDANTS } from "../../../graphql/organizations";
import { facilityData } from "./data";

const incidentInfo = ref({});
const dashboardData = ref([]);
const dashboardLoading = ref(false);
const monthlyNewSubscriptionsLoading = ref(false);
const monthlyNewSubscriptions = ref([]);

const organizationDescendantsLoading = ref(false);
const regions = ref([]);
const facilities = ref([]);
const selectedRegionsId = ref([]);
const selectedFacilityIds = ref([]);

const last12Months = getLast12Months();

const filteredFacilities = computed(() => facilityData);

export function computeTotal(key) {
  return computed(() =>
    dashboardData.value?.reduce((acc, current) => (acc += current[key] || 0), 0)
  );
}

export default function useDashboard() {
  const totalCurrentActiveSubscriptions = computeTotal(
    "currentActiveSubscriptionMTD"
  );
  const totalNumberOfMoveIns = computeTotal("totalMoveInMTD");
  const avgSubscriptionDuration = computed(
    () =>
      computeTotal("avgSubscriptionDuration").value /
      selectedFacilityIds.value.length
  );
  const subscriptionPrice = computed(
    () =>
      computeTotal("subscriptionPrice").value / selectedFacilityIds.value.length
  );

  const totalUnits = computeTotal("totalUnit");
  const activeSubscriptions = computeTotal("activeSubscription");

  const totalLifeTimeRevenueThisMonth = computed(() => {
    if (
      avgSubscriptionDuration.value === 0 ||
      subscriptionPrice.value === 0 ||
      totalCurrentActiveSubscriptions.value === 0
    )
      return 0;
    const revenue =
      avgSubscriptionDuration.value *
      subscriptionPrice.value *
      totalCurrentActiveSubscriptions.value;
    return Math.round(revenue);
  });

  const totalMissedLifeTimeRevenueThisMonth = computeTotal(
    "missedLifetimeRevenue"
  );
  const totalPenetrationRate = computed(() => {
    if (activeSubscriptions.value === 0 || totalUnits.value === 0) return 0;
    const penetrationRate =
      (activeSubscriptions.value / totalUnits.value) * 100;

    return Math.round(penetrationRate);
  });

  const totalAnnualizedLifetimeRevenue = computeTotal("annualizedRevenue");
  const totalConversionsRateOfThisMonth = computed(() =>
    Math.round(
      conversionRate(totalCurrentActiveSubscriptions, totalNumberOfMoveIns)
    )
  );
  const totalConversion = computed(
    () =>
      `${totalCurrentActiveSubscriptions.value}/${totalNumberOfMoveIns.value}`
  );

  //  *********************************Dummy*************************************
  //  *********************************Dummy*************************************
  const conversionTable = computed(() => ({
    headers: [
      { text: "Facility Name", value: "facilityName" },
      {
        text: "Current Active Subscriptions (MTD)",
        value: "newSubscriptionsMTD",
      },
      { text: "Total Move-Ins (MTD)", value: "totalMoveInMTD" },
      { text: "Conversion %", value: "conversion" },
      { text: "Last Month Conversion", value: "lastMonthConversion" },
      {
        text: "Previous Month Conversion (Last Year)",
        value: "previousMonthConversion",
      },
      { text: "3-Months Rolling Average", value: "threeMonthsRollingAverage" },
    ],
    body: dashboardData.value.map((facilityData) => ({
      facilityName: facilityData.name,
      newSubscriptionsMTD: facilityData.currentActiveSubscriptionMTD,
      totalMoveInMTD: facilityData.totalMoveInMTD,
      conversion: facilityData.conversion,
      lastMonthConversion: facilityData.lastMonthConversion,
      previousMonthConversion: facilityData.previousMonthConversion,
      threeMonthsRollingAverage: facilityData.threeMonthsRollingAverage,
    })),
  }));

  const lifetimeRevenueAddsTable = computed(() => ({
    headers: [
      { text: "Facility Name", value: "facilityName" },
      { text: "Added Subscriptions (MTD)", value: "subscriptions" },
      { text: "Missed Subscriptions (MTD)", value: "missedSubscriptions" },
      { text: "Total Move-Ins (MTD)", value: "totalMoveInMTD" },
      {
        text: "Average Subscription Duration (Months)",
        value: "avgSubscriptionDuration",
      },
      { text: "Lifetime Adds ($)", value: "lifetimeRevenueAdds" },
      { text: "Missed Potential Revenue ($)", value: "missedPotentialRevenue" },
    ],
    body: dashboardData.value.map((facilityData) => ({
      facilityName: facilityData.name,
      subscriptions: facilityData.currentActiveSubscriptionMTD,
      missedSubscriptions: facilityData.missedSubscriptions,
      totalMoveInMTD: facilityData.totalMoveInMTD,
      avgSubscriptionDuration: facilityData.avgSubscriptionDuration,
      lifetimeRevenueAdds: facilityData.lifetimeRevenueAdds,
      missedPotentialRevenue: facilityData.missedLifetimeRevenue,
    })),
  }));

  const missedPotentialRevenue = computed(() => ({
    headers: [
      { text: "Facility Name", value: "facilityName" },
      { text: "Missed Subscriptions (MTD)", value: "missedSubscriptions" },
      { text: "Total Move-Ins (MTD)", value: "totalMoveInMTD" },
      { text: "Avg Subscription (Month)", value: "subscriptionMTD" },
      { text: "Missed Potential Revenue ($)", value: "missedLifetimeRevenue" },
    ],
    body: dashboardData.value.map((facilityData) => ({
      facilityName: facilityData.name,
      missedSubscriptions: facilityData.missedSubscriptions,
      totalMoveInMTD: facilityData.totalMoveInMTD,
      subscriptionMTD: facilityData.subscriptionMTD,
      missedLifetimeRevenue: facilityData.missedLifetimeRevenue,
    })),
  }));

  const penetrationTable = computed(() => ({
    headers: [
      { text: "Facility Name", value: "facilityName" },
      { text: "Current Subscriptions", value: "activeSubscription" },
      { text: "Total Units", value: "totalUnit" },
      { text: "Facility Penetrations (%)", value: "facilityPenetrations" },
      { text: "Annualized Lifetime ($)", value: "annualizedRevenue" },
    ],
    body: dashboardData.value.map((facilityData) => ({
      facilityName: facilityData.name,
      activeSubscription: facilityData.activeSubscription,
      totalUnit: facilityData.totalUnit,
      facilityPenetrations: Math.round(facilityData.facilityPenetrations),
      annualizedRevenue: facilityData.annualizedRevenue,
    })),
  }));
  //  *********************************Dummy*************************************
  //  *********************************Dummy*************************************

  const totalMonthlyNewSubscriptionsChartData = computed(() => {
    const { months } = calculateTotalMonthlySubscriptions(
      monthlyNewSubscriptions.value
    );
    const ids =
      monthlyNewSubscriptions.value?.map(({ facilityId }) => facilityId) ?? [];
    return {
      labels: months,
      datasets: [
        {
          label: "Monthly New Subscriptions",
          backgroundColor: "rgba(77, 85, 202, 0.8)",
          data: [323, 333, 339, 346, 345, 365, 366, 370, 385, 400, 410, 420],
          ids,
        },
      ],
    };
  });

  const MTDNewSubscribersChartData = computed(() => {
    const monthlySubscriptions = monthlyNewSubscriptions.value ?? [];
    // const { labels, datasetData } = categorizeData(
    //   monthlySubscriptions,
    //   7,
    //   "subscriptionsCount",
    //   "facilityName"
    // );

    return {
      labels: [
        "222 Self Storage",
        "3 Key Self Storage",
        "38 Key Storage",
        "A Better Self Storage Fountain",
        "A Better Self Storage West",
        "Others",
      ],
      datasets: [
        {
          label: "MTD New Subscribers",
          backgroundColor: DoughnutChartBgColors,
          data: [0, 0, 2, 2, 0],
        },
      ],
    };
  });

  const MTDNewSubscribersTableData = computed(() => {
    const dessertsMap = {};

    // Aggregate subscriptions count for each facility name
    monthlyNewSubscriptions.value?.forEach((item) => {
      const { facilityName, subscriptionsCount } = item;
      dessertsMap[facilityName] =
        (dessertsMap[facilityName] || 0) + subscriptionsCount;
    });

    // Transform aggregated data into array of objects
    const data = Object.keys(dessertsMap).map((name, index) => ({
      rowNumber: index + 1,
      name,
      subscriptions: dessertsMap[name],
    }));

    // Calculate total subscriptions
    const totalSubscriptions = data.reduce(
      (total, { subscriptions }) => total + subscriptions,
      0
    );
    return {
      headers: [
        { text: "", align: "start", sortable: false, value: "rowNumber" },
        { text: "Facility", align: "start", sortable: false, value: "name" },
        { text: "Subscriptions", value: "subscriptions" },
      ],

      data: [
        {
          name: "A Better Self Storage Fountain",
          subscriptions: 2,
          id: "909a1553-74ac-40dc-b33b-490b44218161b",
        },
        {
          name: "38 Key Storage",
          subscriptions: 2,
          id: "909a1553-74ac-40dc-b33b-490b44218161a",
        },
        {
          name: "222 Self Storage",
          subscriptions: 0,
          id: "70640695-811d-48bd-9c39-6dc78ff9014d",
        },
        {
          name: "3 Key Self Storage",
          subscriptions: 0,
          id: "909a1553-74ac-40dc-b33b-490b44218161",
        },
        {
          name: "A Better Self Storage West",
          subscriptions: 0,
          id: "909a1553-74ac-40dc-b33b-490b44218161q",
        },
      ],
      totalSubscriptions: 4,
    };
  });

  const allActiveSubscriptionsChartData = computed(() => {
    const dashboardDataValue = dashboardData.value ?? [];

    return {
      labels: [
        "222 Self Storage",
        "3 Key Self Storage",
        "38 Key Storage",
        "A Better Self Storage Fountain",
        "A Better Self Storage West",
        "Others",
      ],
      datasets: [
        {
          label: "Active Subscriptions",
          backgroundColor: DoughnutChartBgColors,
          data: [32, 53, 88, 130, 110, 5, 4],
        },
      ],
    };
  });

  const allActiveSubscriptionsTableData = computed(() => {
    const data =
      dashboardData.value?.map((item, index) => ({
        rowNumber: index + 1,
        name: item.name,
        subscriptions: item.totalActiveSubscriptions,
      })) ?? [];

    const totalSubscriptions = data.reduce(
      (total, { subscriptions }) => (total += subscriptions),
      0
    );
    return {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "rowNumber",
        },
        {
          text: "Facility",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Subscriptions", value: "subscriptions" },
      ],
      data: [
        {
          name: "A Better Self Storage Fountain",
          subscriptions: 130,
          id: "909a1553-74ac-40dc-b33b-490b44218161b",
        },
        {
          name: "A Better Self Storage West",
          subscriptions: 110,
          id: "909a1553-74ac-40dc-b33b-490b44218161q",
        },
        {
          name: "38 Key Storage",
          subscriptions: 88,
          id: "909a1553-74ac-40dc-b33b-490b44218161a",
        },
        {
          name: "3 Key Self Storage",
          subscriptions: 53,
          id: "909a1553-74ac-40dc-b33b-490b44218161",
        },
        {
          name: "222 Self Storage",
          subscriptions: 32,
          id: "70640695-811d-48bd-9c39-6dc78ff9014d",
        },
        {
          name: "Frisco Self Storage",
          subscriptions: 5,
          id: "268900b0-08b4-4acd-aae6-fe8097cb544d",
        },
        {
          name: "Ideal Self Storage",
          subscriptions: 4,
          id: "909a1553-74ac-40dc-b33b-490b44218161s",
        },
      ],
      totalSubscriptions: 410,
    };
  });

  const totalRevenueThisYear = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Revenue ($)",
        backgroundColor: "rgba(255, 196, 196, 0.8)",
        data: [4356, 4440, 4620, 4572, 4920, 4956],
        borderColor: "#d50d41",
        tension: 0.1,
      },
    ],
  };

  const averageDurationPerVisitsChart = {
    labels: [">20 mins", "10 - 20 mins", "2 - 10 mins", "<2 mins"],
    datasets: [
      {
        label: "Average Duration Per Visit (MTD)",
        data: [1, 1, 1, 1],
        realData: [40, 20, 30, 50],
        backgroundColor: [
          "rgb(119,93,208)",
          "rgb(254,176,25)",
          "rgb(0,227,150)",
          "rgb(255,69,96)",
        ],
      },
    ],
  };

  const averageVisitChart = {
    labels: [">40", "20 - 40", "1 - 20", "<1"],
    datasets: [
      {
        label: "Average no.of Visit (MTD)",
        data: [1, 1, 1, 1],
        realData: [40, 20, 30, 50],
        backgroundColor: [
          "rgb(119,93,208)",
          "rgb(254,176,25)",
          "rgb(0,227,150)",
          "rgb(255,69,96)",
        ],
      },
    ],
  };

  const MTDSubscribersPieChart = {
    labels: ["Today", "Yesterday"],
    datasets: [
      {
        label: "MTD New Subscribers",
        data: [1, 1],
        realData: [40, 20],
        backgroundColor: ["rgb(119,93,208)", "rgb(255,69,96)"],
      },
    ],
  };

  const MTDSubscribersPieChartTableData = {
    headers: [
      { text: "", align: "start", sortable: false, value: "rowNumber" },
      { text: "Facility", align: "start", sortable: false, value: "name" },
      { text: "Today", value: "today" },
      { text: "Yesterday", value: "yesterday" },
    ],

    data: [
      {
        name: "38 Key Storage",
        today: 2,
        yesterday: 2,
        id: "909a1553-74ac-40dc-b33b-490b44218161a",
      },
      {
        name: "A Better Self Storage Fountain",
        today: 2,
        yesterday: 2,
        id: "909a1553-74ac-40dc-b33b-490b44218161b",
      },
      {
        name: "222 Self Storage",
        today: 0,
        yesterday: 0,
        id: "70640695-811d-48bd-9c39-6dc78ff9014d",
      },
      {
        name: "3 Key Self Storage",
        today: 0,
        yesterday: 0,
        id: "909a1553-74ac-40dc-b33b-490b44218161",
      },
      {
        name: "A Better Self Storage West",
        today: 0,
        yesterday: 0,
        id: "909a1553-74ac-40dc-b33b-490b44218161q",
      },
    ],
    todayTotalSubscriptions: 4,
    yesterdayTotalSubscriptions: 4,
  };

  const averageConversionPercentage = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Conversion (%)",
        backgroundColor: "rgba(196, 200, 255, 0.8)",
        data: [24, 33, 33, 31, 35, 46],
        borderColor: "#323487",
        tension: 0.6,
      },
    ],
  };

  async function fetchDashboardData(apolloClient, ids) {
    if (ids.length === 0) return;

    fetchMonthlyNewSubscriptions(apolloClient, ids);
    dashboardLoading.value = true;
    apolloWatchQuery(
      apolloClient,
      {
        query: MANAGEMENT_DASHBOARD,
        variables: {
          ids,
        },
      },
      (data, queryLoading) => {
        dashboardLoading.value = queryLoading;
        if (data?.dashboard) {
          dashboardData.value = data.dashboard;
          dashboardLoading.value = false;
        }
      }
    );
  }

  async function fetchMonthlyNewSubscriptions(apolloClient, ids) {
    if (ids.length === 0) return;
    monthlyNewSubscriptionsLoading.value = false;
    apolloWatchQuery(
      apolloClient,
      {
        query: MONTHLY_SUBSCRIPTIONS,
        variables: {
          ids,
          month: last12Months.recentMonthsISO,
        },
      },
      (data, queryLoading) => {
        monthlyNewSubscriptionsLoading.value = queryLoading;
        if (data?.monthlySubscriptions) {
          monthlyNewSubscriptions.value = data.monthlySubscriptions;
          monthlyNewSubscriptionsLoading.value = false;
        }
      }
    );
  }

  async function fetchOrganizationDescendants(apolloClient, id, types) {
    organizationDescendantsLoading.value = true;
    apolloWatchQuery(
      apolloClient,
      {
        query: LIST_ORGANIZATION_DESCENDANTS,
        variables: {
          id,
          types,
        },
      },
      ({ organizationDescendants }, queryLoading) => {
        organizationDescendantsLoading.value = queryLoading;
        if (organizationDescendants) {
          // Separate regions and facilities
          regions.value = organizationDescendants.filter(
            (descendant) => descendant.type === "region"
          );
          facilities.value = organizationDescendants.filter(
            (descendant) => descendant.type === "facility"
          );
          selectedRegionsId.value = regions.value[0].id;
          organizationDescendantsLoading.value = false;
        }
      }
    );
  }

  return {
    fetchDashboardData,
    dashboardData,
    dashboardLoading,
    totalConversionsRateOfThisMonth,
    totalConversion,
    totalLifeTimeRevenueThisMonth,
    totalMissedLifeTimeRevenueThisMonth,
    totalPenetrationRate,
    totalAnnualizedLifetimeRevenue,
    fetchOrganizationDescendants,
    regions,
    facilities,
    selectedRegionsId,
    selectedFacilityIds,
    organizationDescendantsLoading,
    filteredFacilities,
    allActiveSubscriptionsChartData,
    allActiveSubscriptionsTableData,
    totalRevenueThisYear,
    averageConversionPercentage,
    monthlyNewSubscriptions,
    monthlyNewSubscriptionsLoading,
    totalMonthlyNewSubscriptionsChartData,
    MTDNewSubscribersChartData,
    MTDNewSubscribersTableData,
    conversionTable,
    lifetimeRevenueAddsTable,
    missedPotentialRevenue,
    penetrationTable,
    averageDurationPerVisitsChart,
    averageVisitChart,
    MTDSubscribersPieChart,
    MTDSubscribersPieChartTableData,
    incidentInfo,
  };
}
