<template>
  <LayoutPage
    :title="['Smart Zones', organization.name]"
    :loading="smartZoneLoading"
  >
    <v-row dense>
      <v-col lg="12">
        <CardLayout
          v-model="editingZone"
          title="Smart Zone Information"
          header-type="edit"
          :loading="smartZoneLoading"
        >
          <template #edit>
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(save)">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xs="12" md="3">
                      <ValidatedTextField
                        v-model="organization.name"
                        autofocus
                        name="Smart Zone Name"
                        rules="required|min:2|max:250"
                      />
                    </v-col>

                    <v-col cols="12" xs="12" md="9">
                      <v-row justify="end">
                        <v-col v-if="entryConfig" cols="12" xs="12" md="4">
                          <EntryConfigWidget :config="entryConfig" editing />
                        </v-col>
                        <v-col
                          v-if="serviceRequestConfig"
                          cols="12"
                          xs="12"
                          md="4"
                        >
                          <ServiceRequestConfigWidget
                            :config="serviceRequestConfig"
                            editing
                          />
                        </v-col>
                        <v-col v-if="motionConfig" cols="12" xs="12" md="4">
                          <MotionConfigWidget :config="motionConfig" editing />
                        </v-col>
                        <v-col v-if="trafficConfig" cols="12" xs="12" md="4">
                          <PeopleCountingConfigWidget
                            v-model="trafficConfig"
                            editing
                          />
                        </v-col>
                        <v-col
                          v-if="organization?.profile?.climateConfig"
                          cols="12"
                          xs="12"
                          md="4"
                        >
                          <ClimateConfigWidget
                            v-model="organization.profile.climateConfig"
                            editing
                          />
                        </v-col>
                        <v-col v-if="videoConfig" cols="12" xs="12" md="4">
                          <VideoConfigWidget :config="videoConfig" editing />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-btn depressed tile small color="success" type="submit">
                    Save Smart Zone
                  </v-btn>
                </v-card-text>
              </v-form>
            </ValidationObserver>
          </template>

          <v-row>
            <v-col cols="12" xs="12" md="3">
              <dl>
                <dt>Smart Zone</dt>
                <dd>{{ organization.name }}</dd>

                <template v-if="climateConfigEnabled">
                  <dt>Temperature</dt>
                  <dd>{{ currentTemp || "--" }} °F</dd>

                  <dt>Humidity</dt>
                  <dd>{{ currentHumidity || "--" }}%</dd>
                </template>
              </dl>

              <div v-if="videoConfig && !videoConfig.disabled">
                <VideoViewer small :video-config="videoConfig" />
              </div>
            </v-col>

            <v-col cols="12" xs="12" md="9">
              <v-row justify="end">
                <v-col v-if="entryConfig" cols="12" xs="12" md="4">
                  <EntryConfigWidget :config="entryConfig" />
                </v-col>
                <v-col v-if="serviceRequestConfig" cols="12" xs="12" md="4">
                  <ServiceRequestConfigWidget :config="serviceRequestConfig" />
                </v-col>
                <v-col v-if="motionConfig" cols="12" xs="12" md="4">
                  <MotionConfigWidget :config="motionConfig" />
                </v-col>
                <v-col v-if="trafficConfig" cols="12" xs="12" md="4">
                  <PeopleCountingConfigWidget :value="trafficConfig" />
                </v-col>
                <v-col
                  v-if="organization?.profile?.climateConfig"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ClimateConfigWidget
                    :value="organization.profile.climateConfig"
                  />
                </v-col>
                <v-col v-if="videoConfig" cols="12" xs="12" md="4">
                  <VideoConfigWidget :config="videoConfig" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </CardLayout>
      </v-col>

      <v-col lg="6" class="mt-2">
        <CardZoneResponders />
      </v-col>

      <v-col lg="6" class="mt-2">
        <CardLayout title="Assigned Devices" :loading="smartZoneLoading">
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>Device #</th>
                  <th>Device Health</th>
                  <th>Last Motion</th>
                </tr>
              </thead>
              <tbody v-if="smartZoneLoading">
                <tr>
                  <td colspan="3">
                    <em>Loading assigned devices...</em>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="hasDevices">
                <tr v-for="device in organization.devices" :key="device.id">
                  <td style="width: 40%; white-space: nowrap">
                    {{ device.shortId || device.externalId }}
                  </td>
                  <td style="width: 40%; white-space: nowrap">
                    <DeviceGaugeCluster :device="device" />
                  </td>
                  <td style="width: 20%; white-space: nowrap">
                    {{ deviceLastMotion(device) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3">
                    <em>No devices are assigned</em>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </CardLayout>
      </v-col>

      <v-col lg="12" class="mt-2">
        <v-tabs>
          <v-tab>Zone Incidents</v-tab>
          <v-tab-item>
            <CardZoneIncidentTable
              :incidents="incidents || []"
              :loading="incidentsLoading"
            />
          </v-tab-item>

          <v-tab>Zone Graphs</v-tab>
          <v-tab-item v-if="!smartZoneLoading">
            <v-card outline flat tile>
              <div :style="`background-color: white; height: 18px`"></div>
              <v-card-title class="subtitle-1">
                Zone Graphs
                <v-spacer />
              </v-card-title>
              <v-card-text>
                <template v-if="hasDevices">
                  <TelemetryDisplay
                    :device-id="organization.devices[0].id"
                    :date-range="dateRange"
                    :config-profile="configProfile"
                  />
                </template>
                <template v-else>
                  No Devices Assigned to this Smart Zone.
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-spacer />
          <DateTimeFilter
            v-model="dateRange"
            :time-zone="facilityTimeZone"
            :ranges="['1w', '2w', '1m']"
            default="2w"
            max-range="6m"
            custom
          />
        </v-tabs>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import {
  leanOrganization,
  zoneIncidents,
} from "../../graphql/organizations/zone";

import LayoutPage from "@layout/LayoutPage.vue";
import ClimateConfigWidget from "../fixtures/ClimateConfigWidget.vue";
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import EntryConfigWidget from "../fixtures/EntryConfigWidget.vue";
import MotionConfigWidget from "../fixtures/MotionConfigWidget.vue";
import PeopleCountingConfigWidget from "../fixtures/PeopleCountingConfigWidget.vue";
import ServiceRequestConfigWidget from "../fixtures/ServiceRequestConfigWidget.vue";
import TelemetryDisplay from "../fixtures/TelemetryDisplay.vue";
import ValidatedTextField from "../utils/ValidatedTextField.vue";
import VideoConfigWidget from "../fixtures/VideoConfigWidget.vue";
import VideoViewer from "../fixtures/VideoViewer.vue";
import CardZoneIncidentTable from "./CardZoneIncidentTable.vue";
import CardZoneResponders from "./CardZoneResponders.vue";
import CardLayout from "@layout/CardLayout.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

import { celsiusToFahrenheit } from "../utils/converters";
import { inputifyProfile } from "../utils/organizations";
import { titleCase } from "@tod-ui/helpers/strings";
import { deviceLastMotion } from "../common/helpers/devices";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import { UPDATE_SMART_ZONE } from "./graphql";

export default {
  name: "PageSmartZoneDrilldown",
  components: {
    ClimateConfigWidget,
    DeviceGaugeCluster,
    EntryConfigWidget,
    MotionConfigWidget,
    PeopleCountingConfigWidget,
    ServiceRequestConfigWidget,
    TelemetryDisplay,
    ValidationObserver,
    ValidatedTextField,
    VideoConfigWidget,
    VideoViewer,
    CardZoneIncidentTable,
    CardZoneResponders,
    DateTimeFilter,
    LayoutPage,
    CardLayout,
  },
  setup() {
    const { facilityTimeZone } = useFacility();
    const { addAlert } = useAlerts();
    return { facilityTimeZone, addAlert };
  },
  data: () => ({
    organization: {},
    incidents: [],
    dateRange: { after: undefined },
    editingZone: "",
  }),
  computed: {
    smartZoneLoading() {
      if (!this.organization) return true;
      return this.$apollo.queries.organization.loading;
    },
    hasDevices() {
      return this.organization?.devices?.length;
    },
    incidentsLoading() {
      if (!this.incidents) return true;
      return this.$apollo.queries.incidents.loading;
    },
    climateConfigEnabled() {
      return (
        this.organization?.profile?.climateConfig &&
        !this.organization.profile.climateConfig.disabled
      );
    },
    currentHumidity() {
      if (!this.climateConfigEnabled) return null;
      if (!this.hasDevices) return null;

      // find the most recent device that has humidity
      const devices = this.organization.devices
        .filter((d) => d.humidity > 0)
        .sort(
          (a, b) =>
            Date.parse(b.lastContactedAt) - Date.parse(a.lastContactedAt)
        );

      if (devices.length) return devices[0].humidity;
      return null;
    },
    currentTemp() {
      if (!this.climateConfigEnabled) return null;
      if (!this.hasDevices) return null;

      // find the most recent device that has temperature
      const devices = this.organization.devices
        .filter((d) => d.temperature > 0)
        .sort(
          (a, b) =>
            Date.parse(b.lastContactedAt) - Date.parse(a.lastContactedAt)
        );

      if (devices.length) {
        const temp = devices[0].temperature;
        return Math.round(celsiusToFahrenheit(temp));
      }
      return null;
    },
    configProfile() {
      return this.organization?.profile;
    },
    entryConfig() {
      return this.organization?.profile?.entryConfig;
    },
    motionConfig() {
      const motionConfigs = this.organization?.profile?.motionConfigs;
      if (motionConfigs?.length) {
        return motionConfigs.find((c) => c.mode === "motion");
      }

      return null;
    },
    serviceRequestConfig() {
      return this.organization?.profile?.serviceRequestConfig;
    },
    trafficConfig: {
      get() {
        const motionConfigs = this.organization?.profile?.motionConfigs;
        if (motionConfigs?.length) {
          return motionConfigs.find((c) => c.mode === "people_counting");
        }

        return null;
      },
      set(value) {
        const configIndex =
          this.organization?.profile?.motionConfigs?.findIndex(
            (c) => c.mode === "people_counting"
          );
        if (configIndex !== undefined && configIndex !== -1) {
          this.organization.profile.motionConfigs[configIndex] = value;
        }
      },
    },
    videoConfig() {
      return this.organization?.profile?.videoConfig;
    },
  },
  apollo: {
    organization: {
      query: leanOrganization,
      // ensure we have the latest data, or allow refresh to cancel changes
      fetchPolicy: "no-cache",
      variables() {
        return { id: this.$route.params.smart_zone_id };
      },
      update({ organization }) {
        return organization;
      },
    },
    incidents: {
      query: zoneIncidents,
      variables() {
        return {
          organizationId: this.$route.params.smart_zone_id,
          dateTimeFilter: this.dateRange,
          sort: "startedAt desc",
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
    },
  },
  watch: {
    async editingZone(newVal, oldVal) {
      if (newVal === "" && oldVal === "editing") {
        // ensure we have the latest data, or reset if user cancels the changes
        await this.$apollo.queries.organization.refresh();
      }
    },
  },
  methods: {
    async save() {
      const { id, name, type, subtype, timeZone } = this.organization;
      // we have to detach the profile from vue's reactivity
      const profile = JSON.parse(JSON.stringify(this.organization.profile));

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_SMART_ZONE,
          variables: {
            id,
            name,
            subtype,
            timeZone,
            profile: inputifyProfile(profile),
          },
        });

        this.addAlert({
          type: "success",
          message: "Smart Zone updated successfully",
          timeout: 15,
        });
        this.editingZone = "";
      } catch (error) {
        console.error(error);
        this.addAlert({
          type: "error",
          message: "There was an error updating the Smart Zone Profile",
        });
      }
    },
    titleCase,
    deviceLastMotion,
  },
};
</script>

<style lang="scss" scoped>
dl {
  dt {
    float: left;
    clear: left;
    width: 85px;
    text-align: right;
  }

  dt::after {
    content: ":";
  }

  dd {
    margin: 0 0 0 95px;
    padding: 0 0 0.5em 0;
    font-weight: bold;
  }
}

.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
