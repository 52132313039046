<template>
  <LayoutPage title="Smart Zones" :loading="smartZonesLoading">
    <template #actions>
      <div class="mr-4" style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </template>

    <v-data-table
      :headers="headers"
      :items="zonesWithTraffic"
      :loading="smartZonesLoading || trafficLoading"
      loading-text="Loading Smart Zones..."
      :search="search"
      :custom-filter="customFilter"
      :custom-sort="customSort"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 500] }"
      :sort-by="['name']"
    >
      <template #header.traffic>
        {{ `${facilityYesterdayDOW()}'s Activity` }}
      </template>

      <template #item.actions="{ item }">
        <ButtonView @click="gotoZone(item.id)" />
      </template>

      <template #item.climate="{ item: { devices, incidents, profile } }">
        <v-row dense>
          <v-col md="2">
            <ZoneStatusCluster
              class="pa-2"
              :profile="profile"
              :devices="devices"
              :incidents="incidents"
              small
            />
          </v-col>
          <v-col v-if="hasClimateEnabled(profile)" md="4">
            <ZoneClimateCluster
              class="pa-2"
              :profile="profile"
              :devices="devices"
              small
            />
          </v-col>
          <v-col v-if="hasVideoEnabled(profile)" md="4" class="pt-2">
            <VideoViewer small :video-config="profile.videoConfig" />
          </v-col>
        </v-row>
      </template>

      <template #item.profile="{ item: { profile } }">
        <template v-if="hasMotionEnabled(profile)">
          <v-icon color="purple darken-3" class="mr-4" title="Motion Enabled">
            mdi-motion-sensor
          </v-icon>
        </template>
        <template v-else-if="hasMotionDisabled(profile)">
          <v-icon color="grey lighten-1" class="mr-4" title="Motion Disabled">
            mdi-motion-sensor
          </v-icon>
        </template>

        <template v-if="hasEntryEnabled(profile)">
          <v-icon
            small
            color="purple darken-3"
            class="mr-4"
            title="Entry Enabled"
          >
            fas fa-door-open
          </v-icon>
        </template>
        <template v-else-if="hasEntryDisabled(profile)">
          <v-icon
            small
            color="grey lighten-1"
            class="mr-4"
            title="Entry Disabled"
          >
            fas fa-door-open
          </v-icon>
        </template>

        <template v-if="hasPeopleCountingEnabled(profile)">
          <v-icon
            small
            color="purple darken-3"
            class="mr-4"
            title="Traffic Enabled"
          >
            fas fa-users-class
          </v-icon>
        </template>
        <template v-else-if="hasPeopleCountingDisabled(profile)">
          <v-icon
            small
            color="grey lighten-1"
            class="mr-4"
            title="Traffic Disabled"
          >
            fas fa-users-class
          </v-icon>
        </template>

        <template v-if="hasClimateEnabled(profile)">
          <v-icon
            small
            color="purple darken-3"
            class="mr-4"
            title="Climate Enabled"
          >
            fas fa-temperature-hot
          </v-icon>
        </template>
        <template v-else-if="hasClimateDisabled(profile)">
          <v-icon
            small
            color="grey lighten-1"
            class="mr-4"
            title="Climate Disabled"
          >
            fas fa-temperature-hot
          </v-icon>
        </template>

        <template v-if="hasServiceRequestsEnabled(profile)">
          <v-icon
            color="purple darken-3"
            class="mr-4"
            title="Service Requests Enabled"
          >
            mdi-gesture-tap
          </v-icon>
        </template>
        <template v-else-if="hasServiceRequestsDisabled(profile)">
          <v-icon
            color="grey lighten-1"
            class="mr-4"
            title="Service Requests Disabled"
          >
            mdi-gesture-tap
          </v-icon>
        </template>

        <template v-if="hasVideoEnabled(profile)">
          <v-icon
            small
            color="purple darken-3"
            class="mr-4"
            title="Video Enabled"
          >
            fas fa-cctv
          </v-icon>
        </template>
        <template v-else-if="hasVideoDisabled(profile)">
          <v-icon
            small
            color="grey lighten-1"
            class="mr-4"
            title="Video Disabled"
          >
            fas fa-cctv
          </v-icon>
        </template>
      </template>

      <template #item.traffic="{ item }">
        <template v-if="item.traffic === 'N/A'">
          <span class="grey--text">{{ item.traffic }}</span>
        </template>
        <template v-else>{{ item.traffic }}</template>
      </template>
    </v-data-table>
  </LayoutPage>
</template>

<script>
import { yesterdayStartedAt, yesterdayEndedAt } from "@tod-ui/helpers/datetime";

import LayoutPage from "@layout/LayoutPage.vue";
import SearchField from "@atoms/SearchField.vue";
import VideoViewer from "../fixtures/VideoViewer.vue";
import ButtonView from "@atoms/ButtonView.vue";
import ZoneClimateCluster from "../fixtures/ZoneClimateCluster.vue";
import ZoneStatusCluster from "../fixtures/ZoneStatusCluster.vue";

import { organizationSmartZones } from "../../graphql/organizations";
import { zoneEntryCountingEvents } from "../../graphql/organizations/smartZones";
import { zonePeopleCountingEvents } from "../../graphql/organizations/smartZones";
import { zoneServiceRequestCountingEvents } from "../../graphql/organizations/smartZones";
import useFacility from "../authentication/useFacility";

export default {
  name: "PageSmartZones",
  components: {
    SearchField,
    VideoViewer,
    ButtonView,
    ZoneClimateCluster,
    ZoneStatusCluster,
    LayoutPage,
  },
  setup() {
    const { currentFacilityId, facilityTimeZone, facilityYesterdayDOW } =
      useFacility();
    return { currentFacilityId, facilityTimeZone, facilityYesterdayDOW };
  },
  data: () => ({
    smartZones: [],
    zoneTraffic: [],
    zoneEntry: [],
    zoneServiceRequests: [],
    search: "",
    headers: [
      {
        text: "Details",
        align: "start",
        filterable: false,
        sortable: false,
        value: "actions",
        width: "5%",
      },
      {
        text: "Smart Zone",
        value: "name",
        width: "20%",
      },
      {
        text: "Zone Status",
        filterable: false,
        sortable: false,
        value: "climate",
        width: "40%",
      },
      {
        text: "Yesterday's Traffic",
        align: "center",
        filterable: false,
        value: "traffic",
        width: "15%",
      },
      {
        text: "Active Sensors",
        filterable: false,
        sortable: false,
        value: "profile",
        width: "20%",
      },
    ],
  }),
  computed: {
    smartZonesLoading() {
      return this.$apollo.queries.smartZones.loading;
    },
    trafficLoading() {
      return this.$apollo.queries.zoneTraffic.loading;
    },
    zonesWithTraffic() {
      if (this.smartZoneLoading || this.trafficLoading) return [];

      return this.smartZones.map((zone) => {
        return {
          ...zone,
          traffic: this.yesterdaysTrafficCount(zone),
        };
      });
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          incident_statuses: ["started", "muted"],
          incident_resolution: null,
        };
      },
      // don't cache so we get the most real-time values for climate, etc.
      fetchPolicy: "no-cache",
      pollInterval: 10000,
      // filter out units w/o devices
      // TODO: What about video-only?
      update: ({ smartZones }) =>
        smartZones.filter((o) => o.devices.length > 0),
    },
    zoneEntry: {
      query: zoneEntryCountingEvents,
      variables() {
        return {
          parentId: this.currentFacilityId,
          dateTimeFilter: {
            after: yesterdayStartedAt(this.facilityTimeZone),
            before: yesterdayEndedAt(this.facilityTimeZone),
          },
          aggregate: "day",
        };
      },
    },
    zoneServiceRequests: {
      query: zoneServiceRequestCountingEvents,
      variables() {
        return {
          parentId: this.currentFacilityId,
          dateTimeFilter: {
            after: yesterdayStartedAt(this.facilityTimeZone),
            before: yesterdayEndedAt(this.facilityTimeZone),
          },
          aggregate: "day",
        };
      },
    },
    zoneTraffic: {
      query: zonePeopleCountingEvents,
      variables() {
        return {
          parentId: this.currentFacilityId,
          dateTimeFilter: {
            after: yesterdayStartedAt(this.facilityTimeZone),
            before: yesterdayEndedAt(this.facilityTimeZone),
          },
          aggregate: "day",
        };
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `organization`
      let _search = search.toLowerCase();

      // Filter on `name`
      const name = `${item.name}`;
      if (name.toLowerCase().indexOf(_search) >= 0) return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0];

      items.sort((a, b) => {
        if (_sortBy === "name") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          if (!_sortDesc) return aName < bName ? -1 : 1;
          return bName < aName ? -1 : 1;
        } else if (_sortBy === "traffic") {
          if (!_sortDesc) return a.traffic < b.traffic ? -1 : 1;
          return b.traffic < a.traffic ? -1 : 1;
        } else {
          // Default sort comparison
          if (!_sortDesc) return a[_sortBy] < b[_sortBy] ? -1 : 1;
          return b[_sortBy] < a[_sortBy] ? -1 : 1;
        }
      });

      return items;
    },

    // Climate Config
    hasClimateEnabled({ climateConfig }) {
      return climateConfig && !climateConfig.disabled;
    },
    hasClimateDisabled({ climateConfig }) {
      return climateConfig?.disabled;
    },
    // Door/Entry Config
    hasEntryEnabled({ entryConfig }) {
      return entryConfig && !entryConfig.disabled;
    },
    hasEntryDisabled({ entryConfig }) {
      return entryConfig?.disabled;
    },
    // Motion Config
    hasMotionEnabled({ motionConfigs }) {
      const motion = motionConfigs?.find((c) => c.mode === "motion");
      return motion && !motion.disabled;
    },
    hasMotionDisabled({ motionConfigs }) {
      const motion = motionConfigs?.find((c) => c.mode === "motion");
      return motion?.disabled;
    },
    // People Counting/Traffic Config
    hasPeopleCountingEnabled({ motionConfigs }) {
      const counting = motionConfigs?.find((c) => c.mode === "people_counting");
      return counting && !counting.disabled;
    },
    hasPeopleCountingDisabled({ motionConfigs }) {
      const counting = motionConfigs?.find((c) => c.mode === "people_counting");
      return counting?.disabled;
    },
    // Service Request Config
    hasServiceRequestsEnabled({ serviceRequestConfig }) {
      return serviceRequestConfig && !serviceRequestConfig.disabled;
    },
    hasServiceRequestsDisabled({ serviceRequestConfig }) {
      return serviceRequestConfig?.disabled;
    },
    // Video Config
    hasVideoEnabled({ videoConfig }) {
      return videoConfig && !videoConfig.disabled;
    },
    hasVideoDisabled({ videoConfig }) {
      return videoConfig?.disabled;
    },

    // navigational methods
    gotoZone(zoneId) {
      this.$router.push({
        name: "ViewSmartZone",
        params: {
          smart_zone_id: zoneId,
        },
      });
    },
    yesterdaysTrafficCount({ id, profile }) {
      // priority of traffic/count is...
      // 1. Entry
      // 2. Service Requests
      // 3. People Counting/Motion
      if (this.hasEntryEnabled(profile)) {
        // find matching record in zoneEntry
        const traffic = this.zoneEntry.find(
          ({ organization }) => organization.id === id
        );
        return traffic?.count || 0;
      }

      if (this.hasServiceRequestsEnabled(profile)) {
        // find matching record in zoneServiceRequests
        const traffic = this.zoneServiceRequests.find(
          ({ organization }) => organization.id === id
        );
        return traffic?.count || 0;
      }

      if (this.hasPeopleCountingEnabled(profile)) {
        // find matching record in zoneTraffic
        const traffic = this.zoneTraffic.find(
          ({ organization }) => organization.id === id
        );
        return traffic?.count || 0;
      }

      return "N/A";
    },
  },
};
</script>
