<template>
  <v-card
    style="min-height: 130px; max-height: 190px"
    :elevation="isShadow ? 5 : 0"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div v-if="heading" class="mb-3 text-overline">{{ heading }}</div>
        <p v-if="topTitle" class="mb-2 text-overline">
          {{ topTitle }}
          <v-tooltip v-if="topTooltip" bottom color="black">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon small>mdi-information</v-icon>
              </span>
            </template>
            <span>{{ topTooltip }}</span>
          </v-tooltip>
        </p>
        <div :class="arrowAlignClass">
          <div
            v-if="topValue"
            class="text-h5 cursor-pointer mb-2"
            @click="emitTopClickEvent"
          >
            {{ topValueWithUnit }}
          </div>
          <span v-if="topValueChange" class="ml-3">
            <IconArrow
              :color="topValueChange === 'up' ? '#2AAA8A' : '#FB9E85'"
              :direction="topValueChange"
            >
              <span v-if="topValueChangePercent">
                {{ topValueChangePercent }}%
              </span>
            </IconArrow>
          </span>
        </div>
        <div v-if="bottomTitle" class="mb-2 text-overline">
          {{ bottomTitle }}
          <v-tooltip v-if="bottomTooltip" bottom color="black">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon small>mdi-information</v-icon>
              </span>
            </template>
            <span>{{ bottomTooltip }}</span>
          </v-tooltip>
        </div>
        <div :class="arrowAlignClass" @click="emitBottomClickEvent">
          <div v-if="bottomValue" class="text-h5 cursor-pointer">
            {{ bottomValueWithUnit }}
          </div>
          <span v-if="bottomValueChange" class="ml-3">
            <IconArrow
              :color="bottomValueChange === 'up' ? '#2AAA8A' : '#FB9E85'"
              :direction="bottomValueChange"
            />
            <span v-if="bottomValueChangePercent">
              {{ bottomValueChangePercent }}%
            </span>
          </span>
        </div>
      </v-list-item-content>
      <v-list-item-avatar v-if="avatarColor" size="70" :color="avatarColor">
        <IconRevenue v-if="avatarName === 'revenue'" />
        <IconConversion v-if="avatarName === 'conversion'" />
        <IconPenetration v-if="avatarName === 'penetration'" />
      </v-list-item-avatar>
    </v-list-item>
    <div
      v-if="bottomTitle"
      :style="{ height: '10px', backgroundColor: avatarColor }"
    ></div>
  </v-card>
</template>

<script>
import useDashboard from "./useDashboard";
import IconArrow from "@atoms/IconArrow.vue";
import IconRevenue from "@atoms/IconRevenue.vue";
import IconConversion from "@atoms/IconConversion.vue";
import IconPenetration from "@atoms/IconPenetration.vue";

export default {
  name: "CardDashboardTile",
  components: {
    IconArrow,
    IconRevenue,
    IconConversion,
    IconPenetration,
  },
  props: {
    heading: {
      type: String,
      default: "",
    },
    isShadow: {
      type: Boolean,
      default: true,
    },
    topTitle: {
      type: String,
      default: "",
    },
    bottomTitle: {
      type: String,
      default: "",
    },
    topTooltip: {
      type: String,
      default: "",
    },
    bottomTooltip: {
      type: String,
      default: "",
    },
    topValue: {
      type: [String, Number],
      default: "",
    },
    bottomValue: {
      type: [String, Number],
      default: "",
    },
    topUnit: {
      type: String,
      default: "",
    },
    bottomUnit: {
      type: String,
      default: "",
    },
    topValueChange: {
      type: String,
      default: "",
    },
    topValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    bottomValueChange: {
      type: String,
      default: "",
    },
    bottomValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    topClickEvent: {
      type: String,
      default: "",
    },
    bottomClickEvent: {
      type: String,
      default: "",
    },
    avatarColor: {
      type: String,
      default: "",
    },
    avatarName: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { dashboardLoading, organizationDescendantsLoading } = useDashboard();
    return {
      dashboardLoading,
      organizationDescendantsLoading,
    };
  },
  computed: {
    topValueWithUnit() {
      return this.topUnit === "$"
        ? `${this.topUnit} ${this.topValue}`
        : `${this.topValue} ${this.topUnit}`;
    },
    bottomValueWithUnit() {
      return this.bottomUnit === "$"
        ? `${this.bottomUnit} ${this.bottomValue}`
        : `${this.bottomValue} ${this.bottomUnit}`;
    },
    arrowAlignClass() {
      return this.topValueChangePercent
        ? "arrowAlign-column"
        : "arrowAlign-row";
    },
  },
  methods: {
    emitTopClickEvent() {
      let value = this.topTitle ? this.topTitle : this.heading;
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("topClickEvent", value);
    },
    emitBottomClickEvent() {
      let value = this.bottomTitle;
      value = value.replace(/\s/g, "").toLowerCase;
      this.$emit("bottomClickEvent", value);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.tile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrowAlign-column {
  display: flex;
  /* flex-direction: column-reverse;   */
}

.arrowAlign-row {
  display: flex;
}

/* .gradient {
  height: 12px;
  background-color: #d50d41;
  background-image: linear-gradient(160deg, #d50d41 0%, #323487 100%);
} */
</style>
