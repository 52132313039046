<template>
  <v-card v-if="editing" outlined tile>
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left>fas fa-cctv</v-icon>
      Zone Video
      <v-spacer />
      <v-switch
        v-model="config.disabled"
        title="Enable/Disable"
        hide-details
        inset
        :false-value="true"
        :true-value="false"
      />
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <ValidatedTextField
        v-model="config.videoUrl"
        label="Video URL"
        name="Video URL"
        rules="required|max:1024"
      ></ValidatedTextField>
    </v-card-text>
  </v-card>

  <v-card v-else outlined tile :disabled="config.disabled">
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left :color="config.disabled ? null : 'success'"
        >fas fa-cctv</v-icon
      >
      Zone Video
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!config.disabled" class="text-center">
      <VideoViewer :video-config="config" />
    </v-card-text>
    <v-card-text v-else> Disabled </v-card-text>
  </v-card>
</template>

<script>
import ValidatedTextField from "../utils/ValidatedTextField";
import VideoViewer from "./VideoViewer";

export default {
  name: "VideoConfigWidget",
  components: {
    ValidatedTextField,
    VideoViewer,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
