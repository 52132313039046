<template>
  <CardLayout title="Vacant Unit Hygiene">
    <v-row align="center">
      <v-col cols="3">
        <h3>Move-Out Hygiene Compliance</h3>
        <div class="subtitle-2">Post-Move Out Cleaning Interval</div>
      </v-col>
      <v-col cols="1">
        <v-text-field v-model="openIncidentDays" type="number" suffix="Days" />
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="3">
        <h3>Ongoing Vacant Unit Hygiene</h3>
        <div class="subtitle-2">Unit Cleaning Recheck Interval</div>
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model="inProgressIncidentDays"
          type="number"
          suffix="Days"
        />
      </v-col>
    </v-row>
    <v-spacer />
    <v-btn depressed tile class="success" type="submit"> Save Changes </v-btn>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import { useRoute } from "vue-router/composables";

export default {
  components: {
    CardLayout,
  },

  setup() {
    const route = useRoute();
    const status = computed(() => {
      return route.params.status;
    });

    return {};
  },
  data() {
    return {
      openIncidentDays: 7,
      inProgressIncidentDays: 7,
    };
  },
};
</script>

<style lang="scss" scoped></style>
