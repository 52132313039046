import { titleCase } from "./strings";

let lastExternalId = null;
export function deviceQRScanDecode(inputStr) {
  const split_string = inputStr.split(" ");
  if (split_string.length <= 1) {
    throw `Not a valid device QR code`;
  }
  const shortId = split_string[0];
  if (!shortId || shortId.length < 7 || shortId.length > 8) {
    throw `Invalid Alias (Short ID) in QR code`;
  }
  const externalId = normalizeLoraId(split_string[1]);
  if (!validateLoraId(externalId)) {
    throw `Invalid External ID in QR code`;
  }
  if (externalId === lastExternalId) {
    throw `Device ${shortId} was already scanned`;
  }
  lastExternalId = externalId;
  setTimeout(() => (lastExternalId = null), 10 * 1000); // reset last scanned after 10sec

  return {
    type: "xp",
    status: "ok",
    disposition: "in_service",
    subtype: "defender",
    externalId: externalId,
    shortId: shortId,
    name: `XP #${shortId}`,
  };
}

let gatewayExternalId = null;
let gatewaySerial = null;
export function gatewayLabelDecode(inputStr) {
  const externalId = normalizeLoraId(inputStr);
  if (validateLoraId(externalId)) {
    gatewayExternalId = externalId;
    setTimeout(() => (gatewayExternalId = null), 30 * 1000); // reset value after 30s
  } else if (validateGatewaySerial(inputStr)) {
    gatewaySerial = inputStr;
    lastExternalId = null;
    setTimeout(() => (gatewaySerial = null), 30 * 1000); // reset value after 30s
  } else {
    throw "Not a serial number or external ID";
  }
  // console.log("lora:", gatewayExternalId, "serial", gatewaySerial);
  if (gatewayExternalId && gatewaySerial) {
    if (gatewayExternalId === lastExternalId) {
      throw `Gateway was already scanned`;
    }
    lastExternalId = gatewayExternalId;
    setTimeout(() => (lastExternalId = null), 60 * 1000); // reset last scanned after 1min

    const gateway = {
      type: "xp",
      status: "ok",
      disposition: "in_service",
      externalId: gatewayExternalId,
      serialNumber: gatewaySerial,
      name: gatewaySerial,
    };
    gatewayExternalId = null;
    gatewaySerial = null;

    return gateway;
  }
  return gatewayExternalId || gatewaySerial ? true : false;
}

function normalizeLoraId(str) {
  return str.replace(/[_:]/g, "-").toLowerCase();
}
function validateLoraId(id) {
  return /^[0-9a-f]{2}(?:-[0-9a-f]{2}){7}/.test(id);
}
function validateGatewaySerial(str) {
  return /^[0-9]{8}$/.test(str);
}

export function deviceConfigHasFastHeartbeat(configProfile) {
  const heartbeat = configProfile?.heartbeat?.hb;
  return heartbeat
    ? heartbeat / (1000 * 60) <= 30 //less or equal 30 min
    : false;
}

export function gatewayDisplayIds(gateway, displayGatewayOrganization = false) {
  if (!gateway) return "---";
  return (
    `${gateway.serialNumber || gateway.name} (...${gateway.externalId.slice(
      -9
    )})` +
    (displayGatewayOrganization && gateway.organization?.name
      ? ` @ ${gateway.organization.name}`
      : "")
  );
}

export function rmaRequestType(deviceRmaRequest) {
  return deviceRmaRequest?.startsWith("return")
    ? "return"
    : deviceRmaRequest?.startsWith("replace")
    ? "replace"
    : deviceRmaRequest;
}

export function rmaRequestText(deviceRmaRequest) {
  return deviceRmaRequest
    ? `Pre-approved for ${titleCase(rmaRequestType(deviceRmaRequest))}`
    : "";
}
