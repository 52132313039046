<template>
  <LayoutPage>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
      ><template #item.details>
        <ButtonView title="View" @click="gotoZone" />
      </template>
    </v-data-table>
  </LayoutPage>
</template>

<script>
import ButtonView from "@atoms/ButtonView.vue";
import LayoutPage from "@components/layout/LayoutPage.vue";

export default {
  components: {
    ButtonView,
    LayoutPage,
  },
  setup() {
    return {};
  },
  data() {
    return {
      notification: {
        id: 1,
        message: "This is a test notification",
        hasResolution: true,
        isDismissible: true,
        resolutionRoute: "/resolution-route",
      },
      headers: [
        { text: "Details", value: "details" },
        { text: "Smart Unit", value: "smart_unit" },
        { text: "Type", value: "incident_type" },
        { text: "Renter Details", value: "renter_details" },
        { text: "Started On", value: "started_on" },
        { text: "Incident Duration", value: "incident_duration" },
        { text: "Status", value: "status" },
      ],
      tableData: [
        {
          details: "Incident 1 details",
          smart_unit: "B251",
          organization: "The Storage Assert Management",
          incident_type: "Day Time Extended Motion",
          incident_disposition: "Open",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "60 minutes",
          status: "Ongoing day time extended motion",
        },
        {
          details: "Incident 2 details",
          smart_unit: "E511",
          organization: "The Storage Assert Management",
          incident_type: "Humidity",
          incident_disposition: "Closed",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "<60 minutes",
          status: "Ongoing humidity",
        },
        {
          details: "Incident 3 details",
          smart_unit: "E515",
          organization: "The Storage Assert Management",
          incident_type: "Humidity",
          incident_disposition: "Closed",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "120 minutes",
          status: "Ended",
        },
        {
          details: "Incident 3 details",
          smart_unit: "D426",
          organization: "The Storage Assert Management",
          incident_type: "Active Motion ",
          incident_disposition: "Open",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "120 minutes",
          status: "Ended",
        },
        {
          details: "Incident 1 details",
          smart_unit: "E561",
          organization: "The Storage Assert Management",
          incident_type: "Day Time Extended Motion",
          incident_disposition: "In Progress",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "60 minutes",
          status: "Ended Authorized",
        },
        {
          details: "Incident 2 details",
          smart_unit: "E515",
          organization: "The Storage Assert Management",
          incident_type: "Humidity",
          incident_disposition: "In Review",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "<60 minutes",
          status: "Ongoing humidity",
        },
        {
          details: "Incident 3 details",
          smart_unit: "E561",
          organization: "The Storage Assert Management",
          incident_type: "Humidity",
          incident_disposition: "Closed",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "120 minutes",
          status: "Ended",
        },
        {
          details: "Incident 3 details",
          smart_unit: "E562",
          organization: "The Storage Assert Management",
          incident_type: "Active Motion ",
          incident_disposition: "Open",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "120 minutes",
          status: "Ended",
        },
        {
          details: "Incident 1 details",
          smart_unit: "B253",
          organization: "The Storage Assert Management",
          incident_type: "Day Time Extended Motion",
          incident_disposition: "In Review",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "60 minutes",
          status: "Ended",
        },
        {
          details: "Incident 2 details",
          smart_unit: "B252",
          organization: "The Storage Assert Management",
          incident_type: "Humidity",
          incident_disposition: "Closed",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "<60 minutes",
          status: "Ended",
        },
        {
          details: "Incident 3 details",
          smart_unit: "B561",
          organization: "The Storage Assert Management",
          incident_type: "Humidity",
          incident_disposition: "Closed",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "120 minutes",
          status: "Ended",
        },
        {
          details: "Incident 3 details",
          smart_unit: "E611",
          organization: "The Storage Assert Management",
          incident_type: "Active Motion ",
          incident_disposition: "Open",
          renter_details: "---",
          started_on: "Tue, May 24 2024, 5:27 p.m.",
          incident_duration: "120 minutes",
          status: "Ended",
          action: "open_incidence",
        },
      ],
    };
  },
  methods: {
    gotoZone() {
      this.$router.push({
        name: "IncidentInfo",
      });
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}
</style>
