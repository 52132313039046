<template>
  <div>
    <v-btn-toggle
      v-model="selectedBtn"
      mandatory
      tile
      :class="{ inverted: inverted }"
    >
      <v-btn
        v-for="(range, index) in ranges"
        :key="range"
        outlined
        tile
        small
        @click="setRange(range, index)"
      >
        {{ range }}
      </v-btn>
      <v-btn
        v-if="custom"
        ref="customBtn"
        outlined
        tile
        small
        @click="toggleCustom"
      >
        {{ customBtn }}
      </v-btn>
    </v-btn-toggle>
    <v-menu
      ref="datePicker"
      v-model="customSelect"
      :close-on-content-click="false"
      transition="scroll-y-transition"
      :position-x="customX"
      :position-y="customY"
      offset-overflow
      min-width="auto"
    >
      <v-date-picker
        v-model="customRange"
        no-title
        range
        scrollable
        color="warning"
        :max="maxCustomDate"
        :min="minCustomDate"
      >
        <div class="custom-dates warning--text">
          {{ customTitle }}
        </div>
        <v-spacer />
        <v-btn text color="primary" @click="cancel"> Cancel </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="customRange.length < 2"
          @click="setCustomRange"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { formatDate } from "../helpers/datetime";
import { getRangeAgo, getRangeUpfront, utcDate } from "../helpers/datetime";
export default {
  name: "DateTimeFilter",
  props: {
    value: {
      validator: (v) => typeof v === "object" && v.hasOwnProperty("after"),
      required: true,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    ranges: {
      type: Array,
      default: () => ["Today", "2d", "1w", "1m"],
    },
    maxRange: {
      type: String,
      default: undefined,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: new Date().toISOString(),
    },
    default: {
      type: String,
      default: "1w",
    },
    timeZone: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      customSelect: false,
      customRange: [],
      selectedBtn: 0,
      prevBtn: 0,
      prevRange: [],
      customX: 0,
      customY: 0,
    };
  },
  computed: {
    maxDateTime() {
      return this.max ? utcDate(this.max, true, this.timeZone) : undefined;
    },
    minDateTime() {
      return this.min ? utcDate(this.min, false, this.timeZone) : undefined;
    },
    dateTimeRanges() {
      return Object.fromEntries(
        this.ranges.map((range) => [
          range,
          getRangeAgo(range, "today", this.timeZone),
        ])
      );
    },
    from() {
      return this.customRange[0];
    },
    utcFrom() {
      return this.from && utcDate(this.from, false, this.timeZone);
    },
    to() {
      return this.customRange[1];
    },
    utcTo() {
      return this.to && utcDate(this.to, false, this.timeZone);
    },
    customTitle() {
      const from = this.from ? formatDate(this.from, this.timeZone) : "";
      const to = this.to ? formatDate(this.to, this.timeZone) : "";
      return !from && !to
        ? this.maxRange
          ? `Max Range: ${this.maxRange}`
          : ""
        : from && !to
        ? `${from} -`
        : !from && to
        ? `- ${to}`
        : this.utcFrom < this.utcTo
        ? `${from} - ${to}`
        : `${to} - ${from}`;
    },
    customBtn() {
      return this.custom &&
        this.customRange.length === 2 &&
        this.selectedBtn === this.ranges.length
        ? this.customTitle
        : "Custom";
    },
    maxCustomDate() {
      const maxDate = this.max.slice(0, 10);
      if (!this.maxRange) return maxDate;
      if (this.from && !this.to) {
        const maxTo = getRangeUpfront(this.maxRange, this.from, this.timeZone);
        return this.maxDateTime < maxTo
          ? maxDate
          : maxTo.toFormat("yyyy-MM-dd");
      }
      return maxDate;
    },
    minCustomDate() {
      const minDate = this.min ? this.min.slice(0, 10) : undefined;
      if (!this.maxRange || !this.from || (this.from && this.to)) {
        return minDate;
      }
      const minFrom = getRangeAgo(this.maxRange, this.from, this.timeZone);
      return this.minDateTime > minFrom
        ? minDate
        : minFrom.toFormat("yyyy-MM-dd");
    },
  },
  beforeMount() {
    const after =
      this.value?.after || getRangeAgo(this.default, "today", this.timeZone);
    const index = this.ranges.findIndex(
      (range) => after >= this.dateTimeRanges[range]
    );
    if (index > 0) {
      this.selectedBtn = index;
      this.prevBtn = index;
    }

    if (!this.value?.after) {
      this.emitResult(after);
    }
  },
  methods: {
    setRange(range, index) {
      this.prevBtn = index;
      this.emitResult(this.dateTimeRanges[range]);
    },
    toggleCustom() {
      if (!this.customSelect) {
        const { left, bottom } =
          this.$refs.customBtn.$el.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        this.customX = left < windowWidth - 310 ? left : windowWidth - 310;
        this.customY = bottom;
      }
      this.$nextTick(() => (this.customSelect = !this.customSelect));
    },
    cancel() {
      this.customSelect = false;
      this.customRange = [...this.prevRange];
      this.selectedBtn = this.prevBtn;
    },
    setCustomRange() {
      let after, before;
      if (this.from && this.to) {
        if (this.utcFrom < this.utcTo) {
          after = this.utcFrom;
          before = utcDate(this.to, true, this.timeZone);
        } else {
          after = this.utcTo;
          before = utcDate(this.from, true, this.timeZone);
        }
        this.prevRange = [...this.customRange];
        this.prevBtn = this.selectedBtn;
        this.emitResult(after, before);
      }
    },
    emitResult(after, before = undefined) {
      this.customSelect = false;
      this.$emit("input", {
        after: (!this.minDateTime || (after && after >= this.minDateTime)
          ? after
          : this.minDateTime
        )?.toISO(),
        before: (!this.maxDateTime || !before || before <= this.maxDateTime
          ? before
          : this.maxDateTime
        )?.toISO(),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.inverted.v-btn-toggle {
  background: transparent;
  :deep(.v-btn.v-btn) {
    background: transparent;
    border-color: inherit !important;
  }
  :deep(.v-btn.v-btn--active) {
    background: white;
  }
  :deep(.v-btn--active::before) {
    opacity: 0;
  }
}
.custom-dates {
  position: absolute;
  bottom: 2.5rem;
  left: 1.5rem;
  font-size: 0.9em;
}
</style>
