<template>
  <v-btn small color="primary" :title="title" v-on="$listeners">
    <v-icon left small>mdi-plus-circle</v-icon>
    <slot>Add</slot>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonView",
  props: {
    title: {
      type: String,
      default: "View Details",
    },
  },
};
</script>
