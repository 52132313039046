<template>
  <LayoutPage>
    <template #title>
      <v-btn icon @click="$router.go(-1)">
        <v-icon color="primary">fas fa-chevron-circle-left</v-icon>
      </v-btn>
      <v-toolbar-title class="headline">
        <span class="grey--text text--darken-2">Smart Units</span>
        <v-icon small left right>fas fa-chevron-right</v-icon>
        <template v-if="!renterId">
          <span class="grey--text text--darken-2">Inventory</span>
          <v-icon small left right>fas fa-chevron-right</v-icon>
        </template>
        <PossiblePMS as-icon :sync-obj="smartUnit" content-class="mr-2">
          {{ smartUnit.name }}
        </PossiblePMS>
      </v-toolbar-title>
    </template>
    <div class="felx-contianer">
      <dl class="property-list my-4" :class="{ 'two-columns': !singleColumn }">
        <dt>Incident Disposition</dt>
        <dd style="max-width: 220px">
          <v-select
            dense
            :items="[{ text: 'In Progress' }, { text: 'In Review' }]"
          />
        </dd>
        <dt>Assignee</dt>
        <dd style="max-width: 220px">
          <v-select
            dense
            :items="[
              { text: 'Helen (Facility Manager)' },
              { text: 'John (Facility Admin)' },
            ]"
          />
        </dd>
        <dt>Due Date</dt>
        <dd>
          <CardDate />
        </dd>
      </dl>
      <CardIncidentInfo :is-assign-to-manager="true" />
      <!-- <CardIncidentHistory :unit-id="unitId" :renter-id="renterId" /> -->
    </div>
    <v-row>
      <v-col cols="6">
        <v-textarea
          rows="3"
          label="Type in any additional information that you want to convey to the assignee"
        />
      </v-col>
    </v-row>
    <v-btn color="success">Assign incident</v-btn>
    <v-btn color="danger" @click="$router.go(-1)">Cancel</v-btn>
  </LayoutPage>
</template>

<script>
import { ref } from "vue";
import LayoutPage from "@layout/LayoutPage.vue";
import PossiblePMS from "@components/common/PossiblePMS.vue";
import useSmartUnit from "@components/smart_units/useSmartUnit";
import CardDate from "@components/authentication/call-center-dashboard/CardDate.vue";
import CardIncidentInfo from "./IncidentInfo.vue";

export default {
  name: "AssignToManager",
  components: {
    LayoutPage,
    CardIncidentInfo,
    PossiblePMS,
    CardDate,
  },
  setup() {
    const { smartUnit, smartUnitLoading, fetchSmartUnit } = useSmartUnit();

    const editingUnit = ref("");
    const editingResponders = ref("");

    return {
      smartUnit,
      smartUnitLoading,
      fetchSmartUnit,
      editingResponders,
      editingUnit,
    };
  },
  computed: {
    unitId() {
      return "1a8cbfe9-0ed0-4332-957b-372999c81ba2";
    },
    renterId() {
      return "f02a7b69-e3f8-491a-b375-2a25d53a087a";
    },
    singleColumn() {
      return false;
    },
  },
  watch: {
    editingResponders(editingResponders) {
      if (editingResponders) {
        this.editingUnit = "";
      }
    },
    editingUnit(editingUnit) {
      if (editingUnit) {
        this.editingResponders = "";
      }
    },
  },
  async beforeMount() {
    await this.fetchSmartUnit(this.$apollo, this.unitId);
  },
};
</script>

<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}

.felx-contianer {
  display: flex;
  gap: 8rem;
}
</style>
