import Vue from "vue";
import VueRouter from "vue-router";

import FacilityWrapper from "@layout/FacilityWrapper.vue";
import ContactUs from "@components/other_pages/ContactUs.vue";
import authenticationRoutes from "@components/authentication/routes";
import dashboardRoutes from "@components/dashboard/routes";
import smartUnitsRoutes from "@components/smart_units/routes";
import smartZonesRoutes from "@components/smart_zones/routes";
import rentersRoutes from "@components/renters/routes";
import manageFacilityRoutes from "@components/manage_facility/routes";
import reportsRoutes from "@components/reports/routes";
import incidentRoutes from "@components/incident/routes";
import notificationRoutes from "@components/notifications/routes";
import helpRoutes from "@components/help/routes";
import auditLogRoutes from "@components/audit_log/routes";

import PageCallCenterDashboard from "@components/authentication/call-center-dashboard/PageCallCenterDashboard.vue";
import OpenIncidence from "@components/authentication/call-center-dashboard/OpenIncidence.vue";
import InspectionReport from "@components/incident/InspectionReport.vue";
import ViewTask from "@components/authentication/call-center-dashboard/ViewTask.vue";
import AssignToManager from "@components/authentication/call-center-dashboard/AssignToManager.vue";
import IncidentInfo from "@components/authentication/call-center-dashboard/IncidentInfo.vue";
import PageOperationAndSecurityDashboard from "@components/authentication/operation-and-security-dashboard/PageOperationAndSecurityDashboard.vue";

import PageManagementDashboard from "@components/authentication/management_dashboard/PageManagementDashboard.vue";
import ConversionTable from "@components/authentication/management_dashboard/ConversionTable.vue";
import LifetimeRevenueTable from "@components/authentication/management_dashboard/LifetimeRevenueTable.vue";
import PenetrationTable from "@components/authentication/management_dashboard/PenetrationTable.vue";
import MTDTrendsTable from "@components/authentication/management_dashboard/MTDTrendsTableRevenue.vue";
import AverageVisitTable from "@components/authentication/management_dashboard/AverageVisitTable.vue";
import VacantUnitUncleanScoreTable from "@components/authentication/operation-and-security-dashboard/VacantUnitUncleanScoreTable.vue";

Vue.use(VueRouter);

const routes = [
  ...authenticationRoutes.navigation,
  {
    path: "/organizations/:organization_id",
    name: "Facility",
    component: FacilityWrapper,
    redirect: { name: "FacilityDashboard" },
    children: [
      ...dashboardRoutes.organization,
      ...smartUnitsRoutes.organization,
      ...rentersRoutes.organization,
      ...smartZonesRoutes.organization,
      ...manageFacilityRoutes.organization,
      ...reportsRoutes.organization,
      ...incidentRoutes.organization,
      ...notificationRoutes.organization,
      ...auditLogRoutes.organization,
    ],
  },
  ...reportsRoutes.navigation,
  ...notificationRoutes.navigation,
  ...helpRoutes.navigation,
  {
    path: "/contactus",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/demo/call-center",
    name: "CallCenter",
    component: PageCallCenterDashboard,
  },
  {
    path: "/demo/call-center/open-incidence/:status",
    name: "OpenIncidence",
    component: OpenIncidence,
  },
  {
    path: "/demo/call-center/inspection-report",
    name: "InspectionReport",
    component: InspectionReport,
  },
  {
    path: "/demo/call-center/view-task/:status",
    name: "ViewTask",
    component: ViewTask,
  },
  {
    path: "/demo/call-center/incident-info/:status",
    name: "IncidentInfo",
    component: IncidentInfo,
  },
  {
    path: "/demo/call-center/assign-to-manager",
    name: "AssignToManager",
    component: AssignToManager,
  },
  {
    path: "/demo/operation-and-security",
    name: "OperationAndSecurity",
    component: PageOperationAndSecurityDashboard,
  },
  {
    path: "/demo/operation-and-security/vacant-unit-unclean-score/:status",
    name: "VacantUnitUncleanScoreTable",
    component: VacantUnitUncleanScoreTable,
  },
  {
    path: "/demo/management",
    name: "PageManagementDashboard",
    component: PageManagementDashboard,
  },
  {
    path: "/demo/management/conversion/:highlightColumn",
    name: "ConversionTable",
    component: ConversionTable,
  },
  {
    path: "/demo/management/mtd-revenue/:highlightColumn",
    name: "MTDTrendsTable",
    component: MTDTrendsTable,
  },
  {
    path: "/demo/management/revenue/:highlightColumn",
    name: "RevenueTable",
    component: LifetimeRevenueTable,
  },
  {
    path: "/demo/management/penetration/:highlightColumn",
    name: "Penetrations",
    component: PenetrationTable,
  },
  {
    path: "/demo/management/average-visit/:highlightColumn",
    name: "AverageVisit",
    component: AverageVisitTable,
  },
];

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
