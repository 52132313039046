<template>
  <LayoutPage>
    <template #title>
      <v-toolbar-title class="headline">
        Grace Combs Monitoring & ControlHub Dashboard
      </v-toolbar-title>
    </template>
    <FacilitySelect />
    <v-row>
      <v-col>
        <ComparisonTile
          heading="Open Incidents"
          head-value="4"
          top-title="Current"
          top-value="2"
          bottom-title="Overdue"
          bottom-value="2"
          avatar-color="rgb(105,189,217)"
          top-color="green"
          bottom-color="red"
          avatar-name="open"
          @headClickEvent="handleHeadClickEvent"
          @topClickEvent="handleTopClickEvent"
          @bottomClickEvent="handleBottomClickEvent"
        />
      </v-col>
      <v-col>
        <ComparisonTile
          heading="In Progress Incidents"
          head-value="4"
          top-title="In Review"
          top-value="2"
          bottom-title="Overdue"
          bottom-value="2"
          avatar-color="rgba(249, 203, 0, 1)"
          top-color="green"
          bottom-color="red"
          avatar-name="inprogress"
          @headClickEvent="handleHeadClickEvent"
          @topClickEvent="handleTopClickEvent"
          @bottomClickEvent="handleBottomClickEvent"
        />
      </v-col>
      <v-col>
        <ComparisonTile
          heading="Open Task"
          head-value="6"
          top-title="In Progress"
          top-value="3"
          bottom-title="Overdue"
          bottom-value="6"
          avatar-color="#D50D41"
          top-color="green"
          bottom-color="red"
          avatar-name="task"
          @headClickEvent="handleHeadClickEvent"
          @topClickEvent="handleTopClickEvent"
          @bottomClickEvent="handleBottomClickEvent"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashboardLayout
          title="Incident Disposition Report"
          class="elevation-5"
        >
          <template #header-actions>
            <DateTimeFilter
              v-model="dateRange"
              :ranges="['Today', '2d']"
              custom
              inverted
              time-zone="America/New_York"
            />
            <IncidentFilter class="mr-2" />
            <div style="min-width: 240px" class="mr-2">
              <SearchField v-model="search" />
            </div>
          </template>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :items-per-page="10"
            :search="search"
            class="custom-table elevation-1"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <ButtonView title="View" @click="gotoZone(item)" />
                </td>
                <td style="min-width: 150px">{{ item.incident_id }}</td>
                <td style="max-width: 300px">
                  <div>
                    <strong>Organization</strong> : {{ item.organization }}
                  </div>
                  <div class="mt-2">
                    <strong>Facility</strong> : {{ item.facility }}
                  </div>
                  <div class="mt-2">
                    <strong>Smart Unit / Zone</strong> : {{ item.smart_unit }}
                  </div>
                </td>
                <td>{{ item.incident_type }}</td>
                <td>
                  <div>
                    {{ item.incident_disposition }}
                    <span v-if="item.overdue" class="overdue-text"
                      >Overdue!</span
                    >
                  </div>
                </td>
                <td>
                  <SeverityChip :severity="item.severity" />
                </td>
                <td>{{ item.renter_details }}</td>
                <td>{{ item.started_on }}</td>
                <td>{{ item.target_close_date }}</td>
                <td>{{ item.status }}</td>
              </tr>
            </template>
            <template #footer.prepend>
              <ButtonExportCSV
                class="order-last"
                :table-data="tableData"
                :headers="headers"
                filename="Incident Disposition Report"
              />
            </template>
          </v-data-table>
        </DashboardLayout>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import useUser from "../useUser";
import SearchField from "@atoms/SearchField.vue";
import ButtonView from "@atoms/ButtonView.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import FacilitySelect from "./FacilitySelect.vue";
import ComparisonTile from "./ComparisonTile.vue";
import IncidentFilter from "@components/reports/IncidentFilter.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import DashboardLayout from "../DashboardLayout.vue";
import SeverityChip from "./SeverityChip.vue";
import { useRouter } from "vue-router/composables";
import useDashboard from "../management_dashboard/useDashboard";

export default {
  name: "PageCallCenterDashboard",
  components: {
    LayoutPage,
    ComparisonTile,
    SearchField,
    ButtonView,
    DateTimeFilter,
    FacilitySelect,
    DashboardLayout,
    IncidentFilter,
    ButtonExportCSV,
    SeverityChip,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { incidentInfo } = useDashboard();
    const router = useRouter();
    const gotoZone = (status) => {
      incidentInfo.value = status;
      router.push(
        `/demo/call-center/incident-info/${status.incident_disposition}&${status.severity}&${status.smart_unit}`
      );
    };

    return { defaultOrganization, gotoZone };
  },
  data() {
    return {
      search: null,
      dateRange: { after: "2024-04-25T06:59:59.999Z" },
      headers: [
        { text: "Details", value: "details" },
        { text: "IncidentID", value: "incident_id" },
        { text: "Incident Location", value: "task_location" },
        // { text: "Organization", value: "organization" },
        // { text: "Facility", value: "facility" },
        // { text: "Smart Unit / Zone Location", value: "smart_unit" },
        { text: "Incident Type", value: "incident_type" },
        { text: "Incident Disposition", value: "incident_disposition" },
        { text: "Priority", value: "severity" },
        { text: "Renter Details", value: "renter_details" },
        { text: "Started On", value: "started_on" },
        { text: "Target Close Date", value: "target_close_date" },
        { text: "Status", value: "status" },
      ],
      tableData: [
        {
          incident_id: "VSU-DT-001",
          smart_unit: 100,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type: "Vacant Smart Unit Activity During Day",
          incident_disposition: "Open",
          overdue: false,
          severity: "Low",
          renter_details: "---",
          started_on: "13/6/2024, 7:00 am",
          status: "Ended",
          target_close_date: "19/6/2024",
        },
        {
          incident_id: "VSU-DT-002",
          smart_unit: 101,
          overdue: false,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type: "Vacant Smart Unit Activity During Day",
          incident_disposition: "Closed",
          severity: "Low",
          renter_details: "---",
          started_on: "13/6/2024, 6:53 am",
          status: "Ended, No Response from Renter",
          target_close_date: "19/6/2024",
        },
        {
          incident_id: "VSU-AH-001",
          smart_unit: 102,
          overdue: false,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Vacant Smart Unit Activity with extended motion After Hours",
          incident_disposition: "Closed",
          severity: "Moderate",
          renter_details: "---",
          started_on: "13/6/2024, 6:36 am",
          status: "Ongoing Day Time Extended Motion",
          target_close_date: "19/6/2024",
        },
        {
          incident_id: "VSU-AH-002",
          smart_unit: 103,
          overdue: false,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Vacant Smart Unit Activity with extended motion After Hours",
          incident_disposition: "Open",
          severity: "Moderate",
          renter_details: "---",
          started_on: "13/6/2024, 6:34 am",
          status: "Ongoing Day Time Extended Motion",
          target_close_date: "19/6/2024",
        },
        {
          incident_id: "ASU-DT-UN-001",
          smart_unit: 104,
          overdue: false,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Active Tenant Smart Unit Un-Authorized Activity During Day",
          incident_disposition: "In Progress",
          severity: "Substantial",
          renter_details: "Brian Lee((510) 290-8800)",
          started_on: "13/6/2024, 5:27 am",
          status: "Ended",
          target_close_date: "19/6/2024",
        },
        {
          incident_id: "ASU-DT-UN-002",
          smart_unit: 105,
          overdue: false,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Active Tenant Smart Unit Un-Authorized Activity During Day",
          incident_disposition: "In Review",
          severity: "Substantial",
          renter_details: "Carrie Brown((832) 906-1160)",
          started_on: "13/6/2024, 5:12 am",
          status: "Ended",
          target_close_date: "19/6/2024",
        },
        {
          incident_id: "ASU-AH-UN-001",
          smart_unit: 106,
          overdue: false,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Active Tenant Smart Unit Un-Authorized Activity After Hours",
          incident_disposition: "In Progress",
          severity: "Severe",
          renter_details: "Brian Lee((510) 290-8800)",
          started_on: "13/6/2024, 1:53 am",
          status: "Ended, No Response from Renter",
          target_close_date: "19/6/2024",
        },
        {
          incident_id: "ASU-AH-UN-002",
          smart_unit: 107,
          overdue: true,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Active Tenant Smart Unit Un-Authorized Activity After Hours",
          incident_disposition: "Open",
          severity: "Severe",
          renter_details: "Carrie Brown((832) 906-1160)",
          started_on: "12/6/2024 10.53 pm",
          status: "Ended",
          target_close_date: "14/6/2024",
        },
        {
          smart_unit: 108,
          incident_id: "MSU-AH-NA-001",
          overdue: true,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type: "Multi-Unit Motion Detection After Hours",
          incident_disposition: "In Review",
          severity: "Critical",
          renter_details: "Carrie Brown((832) 906-1160)",
          started_on: "12/6/2024 8:53 pm",
          status: "Ended, No Response from Renter",
          target_close_date: "13/6/2024",
        },
        {
          smart_unit: 109,
          incident_id: "MSU-AH-NA-002",
          overdue: true,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type: "Multi-Unit Motion Detection After Hours",
          incident_disposition: "In Progress",
          severity: "Critical",
          renter_details: "Brian Lee((510) 290-8800)",
          started_on: "12/6/2024 8:12 pm",
          status: "Ended",
          target_close_date: "13/6/2024",
        },
        {
          smart_unit: "Floor2 Hallway",
          incident_id: "MUZ-DT-NA-001",
          overdue: true,
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Smart Zone Motion / Multiple Non-Authorized Unit Motion Events During Access Hours, High Concern",
          incident_disposition: "In Progress",
          severity: "Critical",
          renter_details: "---",
          started_on: "12/6/2024 6:59 pm",
          status: "Ended",
          target_close_date: "14/6/2024",
        },
        {
          smart_unit: "Floor2 Hallway",
          incident_id: "MUZ-DT-NA-002",
          organization: "The Storage Assert Management",
          facility: "Long Beach",
          incident_type:
            "Smart Zone Motion / Multiple Non-Authorized Unit Motion Events During Access Hours, High Concern",
          incident_disposition: "Open",
          severity: "Critical",
          renter_details: "Helen @(972)838-0720",
          started_on: "12/6/2024 6:19 pm",
          status: "Ended",
          target_close_date: "13/6/2024",
          action: "open_incidence",
          overdue: true,
        },
      ],
    };
  },
  methods: {
    handleHeadClickEvent(event) {
      if (event === "opentask") {
        this.$router.push({
          name: "ViewTask",
          params: {
            status: event,
          },
        });
      } else {
        this.$router.push({
          name: "OpenIncidence",
          params: {
            status: event,
          },
        });
      }
    },
    handleTopClickEvent(event) {
      if (event.includes("opentask")) {
        this.$router.push({
          name: "ViewTask",
          params: {
            status: "inprogress",
          },
        });
      } else {
        this.$router.push({
          name: "OpenIncidence",
          params: {
            status: event,
          },
        });
      }
    },
    handleBottomClickEvent(event) {
      if (event.includes("opentask")) {
        this.$router.push({
          name: "ViewTask",
          params: {
            status: "overdue",
          },
        });
      } else {
        this.$router.push({
          name: "OpenIncidence",
          params: {
            status: event,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

.overdue-text {
  color: rgb(254, 10, 5);
  font-weight: bold;
  display: block;
  margin-top: 4px;
  font-size: 12px;
}

.custom-table >>> .v-data-table__wrapper table td {
  min-width: 150px;
}
</style>
