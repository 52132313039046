<template>
  <v-icon v-if="zoneIncidentIcon" :color="zoneIncidentColor" v-bind="$attrs">
    {{ zoneIncidentIcon }}
  </v-icon>
  <span
    v-else-if="isUnconfirmedAccess"
    class="fa-stack"
    :title="_title"
    v-bind="$attrs"
  >
    <i class="fas fa-scanner-keyboard fa-stack-2x"></i>
    <i class="fas fa-clock fa-stack-1x bottom-right error--text"></i>
  </span>

  <span
    v-else-if="isMultiUnit"
    class="fa-stack multi-unit error--text"
    :title="_title"
    v-bind="$attrs"
  >
    <i class="fas fa-walking fa-stack-1x"></i>
    <i class="fas fa-wifi-2 fa-stack-1x"></i>
    <i class="fas fa-wifi-2 fa-stack-1x left"></i>
  </span>

  <span
    v-else-if="incident.isVacant"
    class="fa-stack vacant"
    :title="_title"
    v-bind="$attrs"
  >
    <i class="fas fa-warehouse fa-stack-1x"></i>
    <i class="fas fa-wifi-2 fa-stack-1x"></i>
    <i
      v-if="incident.extendedDuration"
      class="fas fa-clock fa-stack-1x bottom-right"
      :class="afterHours"
    ></i>
  </span>

  <span v-else class="fa-stack" :title="_title" v-bind="$attrs">
    <i class="fas fa-walking fa-stack-2x"></i>
    <i class="fas fa-wifi-2 fa-stack-1x"></i>
    <i
      v-if="extraIconClass"
      class="fa-stack-1x bottom-right"
      :class="extraIconClass"
    ></i>
  </span>
</template>
<script>
import useZoneIncident from "@components/incident/useZoneIncident";
import { formatIncidentType, isMultiUnit } from "../helpers/incident";

export default {
  name: "IconIncidentType",
  props: {
    incident: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const {
      violatedThreshold,
      lowThreshold,
      highThreshold,
      isClimateIncident,
    } = useZoneIncident(props.incident);

    return {
      violatedThreshold,
      lowThreshold,
      highThreshold,
      isClimateIncident,
    };
  },
  computed: {
    zoneIncidentIcon() {
      switch (this.incident?.type) {
        case "humidity":
          return "fas fa-raindrops";

        case "temperature":
          return this.violatedThreshold &&
            this.violatedThreshold === this.lowThreshold
            ? "fas fa-temperature-frigid"
            : this.violatedThreshold &&
              this.violatedThreshold === this.highThreshold
            ? "fas fa-temperature-hot"
            : "fas fa-thermometer-half";

        case "entry":
          return "fas fa-door-open";

        case "service_request":
          return "mdi-gesture-tap";
      }
      return null;
    },
    zoneIncidentColor() {
      return this.isClimateIncident
        ? this.violatedThreshold && this.violatedThreshold === this.lowThreshold
          ? "blue lighten-3"
          : this.violatedThreshold &&
            this.violatedThreshold === this.highThreshold
          ? "error"
          : undefined
        : "primary";
    },
    isMultiUnit() {
      return isMultiUnit(this.incident);
    },
    isUnconfirmedAccess() {
      return this.incident.type === "unconfirmed_access";
    },
    isUnexplainedAfterHours() {
      return !!this.incident?.queuedUntilDawn;
    },
    afterHours() {
      if (this.incident.duringAccessHours === null) return "";
      return this.incident.duringAccessHours ? "success--text" : "error--text";
    },
    extraIconClass() {
      if (this.incident.hasDisabledLienResponder) {
        return "fas fa-lock error--text";
      }
      if (this.isUnexplainedAfterHours) {
        return "fas fa-clock error--text";
      }
      if (this.incident?.bypassedAccess) {
        return "fas fa-scanner-keyboard error--text";
      }
      if (this.incident.resolution === "presence_confirmed") {
        return "fas fa-scanner-keyboard success--text";
      }
      return null;
    },
    _title() {
      return this.title || formatIncidentType(this.incident);
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-stack {
  font-size: 0.8em;
  color: #555;
}
.fa-wifi-2 {
  transform: translate(0.8em, -0.8em) rotate(-135deg);
  &.left {
    transform: translate(-0.8em, -0.8em) rotate(135deg);
  }
}
.bottom-right {
  transform: translate(0.8em, 0.7em);
}
.multi-unit {
  .fa-walking {
    font-size: 1.5em;
  }
}
.vacant {
  color: gray;
}
</style>
