<template>
  <svg
    viewBox="-512 -512 2048.00 2048.00"
    fill="#ffffff"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ffffff"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M656.476 288.014c-4.422 0-8.078-3.578-8.078-8 0-4.42 3.5-8 7.906-8h0.172c4.406 0 8 3.578 8 8 0 4.422-3.594 8-8 8zM848.464 48.028c-4.422 0-8.078-3.578-8.078-8 0-4.422 3.5-8 7.906-8h0.172c4.406 0 8 3.578 8 8 0 4.422-3.592 8-8 8zM336.496 16.03c-4.422 0-8.078-3.578-8.078-8 0-4.422 3.5-8 7.906-8h0.172c4.406 0 8 3.578 8 8 0 4.422-3.594 8-8 8zM400.492 112.008c-4.422 0-8.078-3.578-8.078-8 0-4.422 3.5-8 7.906-8h0.172c4.406 0 8 3.578 8 8 0 4.422-3.594 8-8 8zM544.484 192.004c-4.422 0-8.078-3.578-8.078-8 0-4.42 3.5-8 7.906-8h0.172a8.006 8.006 0 0 1 7.998 8c0 4.422-3.594 8-7.998 8zM352.01 272.014c-30.874 0-55.998-25.124-55.998-55.998s25.124-55.996 55.998-55.996 55.998 25.124 55.998 55.996-25.126 55.998-55.998 55.998z m0-95.994c-22.062 0-39.998 17.944-39.998 39.998s17.936 39.998 39.998 39.998 39.998-17.944 39.998-39.998c-0.002-22.054-17.936-39.998-39.998-39.998z"
        fill=""
      ></path>
      <path
        d="M352.01 304.012c-30.874 0-55.998-25.124-55.998-55.998 0-4.42 3.578-8 8-8s8 3.578 8 8c0 22.054 17.936 39.998 39.998 39.998s39.998-17.944 39.998-39.998c0-4.42 3.578-8 8-8s8 3.578 8 8c-0.002 30.874-25.126 55.998-55.998 55.998z"
        fill=""
      ></path>
      <path
        d="M352.01 240.016c-4.42 0-8-3.578-8-8V200.02c0-4.422 3.578-8 8-8s8 3.578 8 8v31.998a7.998 7.998 0 0 1-8 7.998zM304.012 256.016c-4.422 0-8-3.578-8-8v-31.998c0-4.42 3.578-8 8-8s8 3.578 8 8v31.998c0 4.422-3.578 8-8 8zM400.006 256.016c-4.422 0-8-3.578-8-8v-31.998c0-4.42 3.578-8 8-8s8 3.578 8 8v31.998c0 4.422-3.578 8-8 8zM807.982 192.02c-26.466 0-47.996-21.53-47.996-47.998s21.53-47.998 47.996-47.998 47.998 21.53 47.998 47.998-21.53 47.998-47.998 47.998z m0-79.996c-17.638 0-31.998 14.35-31.998 31.998s14.36 31.998 31.998 31.998c17.64 0 31.998-14.35 31.998-31.998s-14.356-31.998-31.998-31.998z"
        fill=""
      ></path>
      <path
        d="M807.982 224.018c-26.466 0-47.996-21.53-47.996-47.998 0-4.42 3.578-8 7.998-8 4.422 0 8 3.578 8 8 0 17.648 14.36 31.998 31.998 31.998 17.64 0 31.998-14.35 31.998-31.998 0-4.42 3.578-8 8-8s8 3.578 8 8c0 26.468-21.53 47.998-47.998 47.998z"
        fill=""
      ></path>
      <path
        d="M767.984 182.02a7.994 7.994 0 0 1-7.998-8V144.022c0-4.42 3.578-8 7.998-8 4.422 0 8 3.578 8 8V174.02c0 4.422-3.578 8-8 8zM847.98 183.27c-4.422 0-8-3.578-8-8V144.022c0-4.42 3.578-8 8-8s8 3.578 8 8v31.248c0 4.422-3.578 8-8 8zM807.982 160.022a7.994 7.994 0 0 1-7.998-8v-15.998c0-4.422 3.578-8 7.998-8 4.422 0 8 3.578 8 8v15.998c0 4.422-3.578 8-8 8zM512 783.984c-49.872 0-67.778-16.468-69.652-18.344a7.996 7.996 0 0 1-2.344-5.656v-84.87L137.742 292.974c-2.734-3.46-2.14-8.492 1.312-11.234a8 8 0 0 1 11.25 1.312l303.98 384.312c1.11 1.422 1.718 3.156 1.718 4.968v83.698c5.688 3.656 22.28 11.954 55.998 11.954 33.624 0 50.232-8.25 55.998-11.968v-83.682c0-1.812 0.61-3.546 1.718-4.968l303.98-384.312a8 8 0 0 1 11.25-1.312 8.014 8.014 0 0 1 1.312 11.234l-302.264 382.14v84.87a7.996 7.996 0 0 1-2.344 5.656c-1.872 1.874-19.776 18.342-69.65 18.342z"
        fill=""
      ></path>
      <path
        d="M480.002 735.986c-4.42 0-8-3.578-8-8v-79.994c0-4.422 3.578-8 8-8s8 3.578 8 8v79.994a7.998 7.998 0 0 1-8 8z"
        fill=""
      ></path>
      <path
        d="M479.658 656.242a7.972 7.972 0 0 1-6.25-3.016L242.72 363.792a7.99 7.99 0 0 1 1.266-11.242c3.438-2.726 8.484-2.188 11.234 1.274L485.908 643.26a7.98 7.98 0 0 1-1.266 11.234 7.894 7.894 0 0 1-4.984 1.748zM512 336.01c-38.482 0-375.978-1.344-375.978-47.998 0-4.422 3.578-8 8-8a8 8 0 0 1 7.922 6.868c11.812 13.678 145.24 33.13 360.056 33.13 4.422 0 8 3.578 8 8 0 4.422-3.578 8-8 8z"
        fill=""
      ></path>
      <path
        d="M512 336.01c-4.42 0-8-3.578-8-8 0-4.422 3.578-8 8-8 216.754 0 350.652-19.812 360.354-33.498 0-4.422 3.39-7.25 7.812-7.25s7.812 4.328 7.812 8.75c0 46.654-337.496 47.998-375.978 47.998zM432.004 256.968a8.008 8.008 0 0 1-8-7.804 8.002 8.002 0 0 1 7.812-8.194c25.81-0.624 52.652-0.954 80.182-0.954 4.422 0 8 3.578 8 8 0 4.422-3.578 8-8 8-27.406 0-54.122 0.328-79.808 0.954l-0.186-0.002zM143.834 296.764c-4.422 0-7.812-4.328-7.812-8.75 0-8.328 0-25.646 135.242-38.294 4.64-0.352 8.312 2.82 8.718 7.226a8.01 8.01 0 0 1-7.218 8.71c-110.336 10.312-120.726 23.31-121.118 23.858 0.002 4.422-3.39 7.25-7.812 7.25z"
        fill=""
      ></path>
      <path
        d="M879.978 296.014a8 8 0 0 1-7.922-6.868c-11.81-13.678-145.24-33.13-360.056-33.13-4.42 0-8-3.578-8-8 0-4.42 3.578-8 8-8 38.482 0 375.978 1.344 375.978 47.998 0 4.422-3.578 8-8 8zM512 991.97c-66.168 0-119.994-53.824-119.994-119.992 0-4.422 3.578-8 8-8s8 3.578 8 8c0 57.34 46.652 103.994 103.994 103.994s103.994-46.654 103.994-103.994c0-4.422 3.578-8 8-8s8 3.578 8 8c0 66.168-53.826 119.992-119.994 119.992z"
        fill=""
      ></path>
      <path
        d="M512 1023.968c-66.168 0-119.994-53.824-119.994-119.992 0-4.422 3.578-8 8-8s8 3.578 8 8c0 57.34 46.652 103.992 103.994 103.992s103.994-46.652 103.994-103.992c0-4.422 3.578-8 8-8s8 3.578 8 8c0 66.168-53.826 119.992-119.994 119.992zM400.006 879.976c-4.422 0-8-3.578-8-7.998 0-32.56 12.782-62.996 35.998-85.712 3.156-3.078 8.25-3.032 11.312 0.124a7.994 7.994 0 0 1-0.124 11.31c-20.108 19.686-31.186 46.06-31.186 74.278a7.994 7.994 0 0 1-8 7.998zM623.994 879.976c-4.422 0-8-3.578-8-7.998 0-28.186-11.076-54.576-31.186-74.278a7.994 7.994 0 0 1-0.124-11.31c3.124-3.124 8.188-3.218 11.312-0.124 23.216 22.748 35.998 53.184 35.998 85.712a7.994 7.994 0 0 1-8 7.998z"
        fill=""
      ></path>
      <path
        d="M623.994 911.976c-4.422 0-8-3.578-8-8v-31.998c0-4.422 3.578-8 8-8s8 3.578 8 8v31.998c0 4.422-3.578 8-8 8zM400.006 911.976c-4.422 0-8-3.578-8-8v-31.998c0-4.422 3.578-8 8-8s8 3.578 8 8v31.998c0 4.422-3.578 8-8 8zM512 1023.968c-4.42 0-8-3.578-8-8v-31.998a7.994 7.994 0 0 1 8-7.998c4.422 0 8 3.578 8 7.998v31.998c0 4.422-3.578 8-8 8zM575.996 1003.876a7.994 7.994 0 0 1-7.998-8v-31.982c0-4.422 3.578-8 7.998-8 4.422 0 8 3.578 8 8v31.982c0 4.422-3.578 8-8 8zM448.004 1003.892c-4.42 0-8-3.578-8-8v-31.998c0-4.422 3.578-8 8-8s8 3.578 8 8v31.998a7.998 7.998 0 0 1-8 8zM512 927.974c-4.42 0-8-3.578-8-8V823.98c0-4.422 3.578-8 8-8 4.422 0 8 3.578 8 8v95.994c0 4.422-3.578 8-8 8zM544 312.012c-4.422 0-8-3.578-8-8 0-8.82-7.172-16-16-16s-16 7.18-16 16c0 4.42-3.578 8-8 8s-8-3.578-8-8c0-17.648 14.36-31.998 32-31.998s31.998 14.35 31.998 31.998c0 4.422-3.578 8-7.998 8zM256.016 112.024c-13.234 0-24-10.764-24-24 0-13.232 10.766-23.998 24-23.998s24 10.766 24 23.998c-0.002 13.236-10.766 24-24 24z m0-31.998c-4.406 0-8 3.586-8 8s3.594 8 8 8 8-3.586 8-8a8.014 8.014 0 0 0-8-8z"
        fill=""
      ></path>
      <path
        d="M687.99 48.028c-13.232 0-23.998-10.764-23.998-24 0-13.232 10.766-23.998 23.998-23.998s23.998 10.766 23.998 23.998c0 13.236-10.766 24-23.998 24z m0-31.998c-4.406 0-8 3.586-8 8s3.594 8 8 8 8-3.586 8-8-3.594-8-8-8z"
        fill=""
      ></path>
      <path
        d="M631.994 192.004c-17.64 0-31.998-14.35-31.998-31.998s14.358-31.998 31.998-31.998c17.638 0 31.998 14.35 31.998 31.998s-14.36 31.998-31.998 31.998z m0-47.998c-8.828 0-16 7.178-16 16s7.172 16 16 16c8.826 0 15.998-7.178 15.998-16s-7.172-16-15.998-16z"
        fill=""
      ></path>
      <path
        d="M184.02 224.002c-17.64 0-31.998-14.35-31.998-31.998S166.38 160.006 184.02 160.006c17.638 0 31.998 14.35 31.998 31.998s-14.358 31.998-31.998 31.998z m0-47.998c-8.828 0-16 7.178-16 16s7.172 16 16 16 15.998-7.178 15.998-16-7.17-16-15.998-16z"
        fill=""
      ></path>
      <path
        d="M512 96.026c-22.062 0-39.998-17.944-39.998-39.998 0-22.052 17.936-39.998 39.998-39.998 22.062 0 39.998 17.944 39.998 39.998S534.062 96.026 512 96.026z m0-63.996c-13.234 0-24 10.766-24 23.998 0 13.234 10.766 24 24 24s24-10.764 24-24c0-13.234-10.766-23.998-24-23.998z"
        fill=""
      ></path>
      <path
        d="M512 128.024c-22.062 0-39.998-17.944-39.998-39.998 0-4.42 3.578-8 8-8s8 3.578 8 8c0 13.234 10.766 24 24 24s24-10.764 24-24c0-4.42 3.578-8 8-8a7.994 7.994 0 0 1 7.998 8c-0.002 22.054-17.938 39.998-40 39.998z"
        fill=""
      ></path>
      <path
        d="M480.002 96.026c-4.42 0-8-3.578-8-8V56.028c0-4.42 3.578-8 8-8s8 3.578 8 8v31.998a7.998 7.998 0 0 1-8 8z"
        fill=""
      ></path>
      <path
        d="M544 96.026c-4.422 0-8-3.578-8-8V56.028c0-4.42 3.578-8 8-8a7.994 7.994 0 0 1 7.998 8v31.998c0 4.422-3.578 8-7.998 8z"
        fill=""
      ></path>
      <path
        d="M512 72.028c-4.42 0-8-3.578-8-8v-16c0-4.42 3.578-8 8-8 4.422 0 8 3.578 8 8v16c0 4.422-3.578 8-8 8z"
        fill=""
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  name: "IconsConversion",
  setup() {
    return {};
  },
};
</script>
