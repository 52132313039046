<template>
  <v-container v-if="!eventsLoading" fluid>
    <div v-if="climateConfigEnabled">
      <div v-if="hasEvents">
        <Chart :chart-data="chartData" />
      </div>
      <div v-else>No climate data.</div>
    </div>
    <div v-if="motionConfigEnabled">
      <div v-if="hasMotionEvents">
        <IncidentChart
          :chart-data="motionChartData"
          :min="0"
          :max="2"
          is-motion
          title="Motion Detected"
        />
      </div>
      <div v-else>No motion data.</div>
    </div>
    <div v-if="entryConfigEnabled">
      <div v-if="hasEntryEvents">
        <IncidentChart
          :chart-data="entryChartData"
          :min="0"
          :max="2"
          is-motion
          title="Open Entry Detected"
        />
      </div>
      <div v-else>No entry data.</div>
    </div>
    <div v-if="serviceRequestConfigEnabled">
      <div v-if="hasServiceRequestEvents">
        <IncidentChart
          :chart-data="serviceRequestChartData"
          :min="0"
          :max="2"
          is-motion
          title="Service Requested"
        />
      </div>
      <div v-else>No service request data.</div>
    </div>
  </v-container>
  <v-container v-else>Loading.....</v-container>
</template>

<script>
import Chart from "./chart.vue";
import IncidentChart from "./IncidentChart.vue";

import {
  deviceTelemetryEvents,
  deviceMotionEvents,
  deviceTriggerEvents,
} from "../../graphql/organizations/zone";
import { celsiusToFahrenheit } from "../utils/converters";
import useFacility from "@components/authentication/useFacility";

export default {
  name: "TelemetryDisplay",
  components: {
    Chart,
    IncidentChart,
  },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
    configProfile: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { facilityISOLocal } = useFacility();
    return { toISOLocal: facilityISOLocal };
  },
  data() {
    return {
      events: {
        tempEvents: [],
        humidityEvents: [],
      },
      motionEvents: [],
      triggerEvents: [],
    };
  },
  computed: {
    eventsLoading() {
      if (!this.events) return true;
      return this.$apollo.queries.events.loading;
    },
    hasEvents() {
      return (
        this.events &&
        (this.events.tempEvents.length > 0 ||
          this.events.humidityEvents.length > 0)
      );
    },
    hasMotionEvents() {
      return this.motionEvents && this.motionEvents.length > 0;
    },
    hasEntryEvents() {
      return (
        this.triggerEvents?.entryEvents &&
        this.triggerEvents.entryEvents.length > 0
      );
    },
    hasServiceRequestEvents() {
      return (
        this.triggerEvents?.serviceRequestEvents &&
        this.triggerEvents.serviceRequestEvents.length > 0
      );
    },
    climateConfigEnabled() {
      if (!this.configProfile?.climateConfig) {
        return false;
      } else {
        return !this.configProfile?.climateConfig?.disabled;
      }
    },
    motionConfigEnabled() {
      if (this.configProfile?.motionConfigs?.length) {
        return (
          !this.configProfile.motionConfigs.find((c) => c.mode === "motion")
            ?.disabled ||
          !this.configProfile.motionConfigs.find(
            (c) => c.mode === "people_counting"
          )?.disabled
        );
      }
      return null;
    },
    entryConfigEnabled() {
      if (!this.configProfile?.entryConfig) {
        return false;
      } else {
        return !this.configProfile?.entryConfig?.disabled;
      }
    },
    serviceRequestConfigEnabled() {
      if (!this.configProfile?.serviceRequestConfig) {
        return false;
      } else {
        return !this.configProfile?.serviceRequestConfig?.disabled;
      }
    },
    chartData() {
      if (!this.hasEvents) return;

      return {
        datasets: [
          {
            label: "Temperature °F",
            data: this.events.tempEvents,
            backgroundColor: "transparent",
            borderColor: "Red",
            borderWidth: 2,
            pointBackgroundColor: "Red",
            pointBorderColor: "Red",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            fill: false,
          },
          {
            label: "Humidity %",
            data: this.events.humidityEvents,
            backgroundColor: "transparent",
            borderColor: "Blue",
            borderWidth: 2,
            pointBackgroundColor: "Blue",
            pointBorderColor: "Blue",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            fill: false,
          },
        ],
      };
    },
    motionChartData() {
      const lineColor = "#1976D2"; // primary

      return {
        yLabels: ["", "Motion", ""],
        datasets: [
          {
            data: this.motionEvents,
            label: "Motion Detected",
            backgroundColor: lineColor,
            borderColor: lineColor,
            pointBackgroundColor: lineColor,
            pointBorderColor: lineColor,
            radius: 5,
            hoverRadius: 7,
            showLine: false,
          },
        ],
      };
    },
    entryChartData() {
      const lineColor = "#1976D2"; // primary

      return {
        yLabels: ["", "Open", ""],
        datasets: [
          {
            data: this.triggerEvents.entryEvents,
            label: "Open Entry Detected",
            backgroundColor: lineColor,
            borderColor: lineColor,
            pointBackgroundColor: lineColor,
            pointBorderColor: lineColor,
            radius: 5,
            hoverRadius: 7,
            showLine: false,
          },
        ],
      };
    },
    serviceRequestChartData() {
      const lineColor = "#1976D2"; // primary

      return {
        yLabels: ["", "Service Requested", ""],
        datasets: [
          {
            data: this.triggerEvents.serviceRequestEvents,
            label: "Service Requested",
            backgroundColor: lineColor,
            borderColor: lineColor,
            pointBackgroundColor: lineColor,
            pointBorderColor: lineColor,
            radius: 5,
            hoverRadius: 7,
            showLine: false,
          },
        ],
      };
    },
  },
  apollo: {
    events: {
      query: deviceTelemetryEvents,
      variables() {
        return {
          deviceId: this.deviceId,
          dateTimeFilter: this.dateRange,
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ events }) {
        return events.reduce(
          (acc, event) => {
            const payload = JSON.parse(event.data);
            if (
              payload.humid_pcnt != undefined &&
              payload.temp_celcius != undefined
            ) {
              acc.humidityEvents.push({
                y: payload.humid_pcnt,
                x: new Date(payload.timestamp).toISOString(),
              });
              acc.tempEvents.push({
                y: Math.floor(celsiusToFahrenheit(payload.temp_celcius)),
                x: new Date(payload.timestamp).toISOString(),
              });
            }
            return acc;
          },
          { tempEvents: [], humidityEvents: [] }
        );
      },
    },
    motionEvents: {
      query: deviceMotionEvents,
      variables() {
        return {
          deviceId: this.deviceId,
          dateTimeFilter: this.dateRange,
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ events }) {
        return events.reduce((acc, event) => {
          const payload = JSON.parse(event.data);
          if (
            payload.type != undefined &&
            ["motion_started", "motion_detected"].includes(payload.type)
          ) {
            acc.push({
              y: 1,
              x: this.toISOLocal(payload.timestamp),
            });
          }
          return acc;
        }, []);
      },
    },
    triggerEvents: {
      query: deviceTriggerEvents,
      variables() {
        return {
          deviceId: this.deviceId,
          dateTimeFilter: this.dateRange,
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ events }) {
        return events.reduce(
          (acc, event) => {
            const payload = JSON.parse(event.data);
            if (
              payload.type != undefined &&
              ["door_opened"].includes(payload.type)
            ) {
              acc.entryEvents.push({
                y: 1,
                x: this.toISOLocal(payload.timestamp),
              });
            }
            if (
              payload.type != undefined &&
              ["service_request_pressed"].includes(payload.type)
            ) {
              acc.serviceRequestEvents.push({
                y: 1,
                x: this.toISOLocal(payload.timestamp),
              });
            }
            return acc;
          },
          { entryEvents: [], serviceRequestEvents: [] }
        );
      },
    },
  },
};
</script>
