<template>
  <v-list>
    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="PageManagementDashboard"
      icon="fas fa-tachometer-alt"
    >
      New Dashboard
    </SideMenuListItem>
    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="FacilityDashboard"
      icon="fas fa-tachometer-alt"
    >
      Dashboard
    </SideMenuListItem>

    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="SmartUnits"
      icon="fas fa-table"
    >
      Smart Units
    </SideMenuListItem>

    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="Renters"
      icon="fas fa-user-friends"
    >
      Renters
    </SideMenuListItem>

    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="Inventory"
      icon="fas fa-dolly-flatbed"
    >
      Inventory
    </SideMenuListItem>

    <FeatureWrapper feature="smart_zones">
      <SideMenuListItem
        :disabled="!currentFacilityId"
        :facility-id="currentFacilityId"
        route="SmartZones"
        icon="fab fa-chromecast"
      >
        Smart Zones
      </SideMenuListItem>
    </FeatureWrapper>

    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="ManageFacility"
      icon="fas fa-warehouse"
    >
      Manage Facility
    </SideMenuListItem>

    <SideMenuListItem
      :facility-id="currentFacilityId"
      :route="currentFacilityId ? 'Reports' : 'ReportsOrganization'"
      icon="fas fa-file-chart-line"
    >
      Reports
    </SideMenuListItem>

    <SideMenuListItem
      :facility-id="currentFacilityId"
      :route="currentFacilityId ? 'Notifications' : 'UserNotifications'"
      icon="fas fa-bells"
    >
      Notifications
    </SideMenuListItem>
    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="AuditLog"
      icon="fas fa-history"
    >
      Audit Log
    </SideMenuListItem>

    <v-divider class="my-2" />

    <SideMenuListItem route="Help" icon="mdi-help-circle">
      Help Resources
    </SideMenuListItem>
    <SideMenuListItem route="ContactUs" icon="fa-envelope">
      Contact Us
    </SideMenuListItem>
    <v-divider class="my-2" />
    <SideMenuListItem>
      <div class="d-flex align-center">
        Change Theme
        <v-switch v-model="$vuetify.theme.dark" class="ml-5" />
      </div>
    </SideMenuListItem>
  </v-list>
</template>

<script>
import useFacility from "../authentication/useFacility";

import SideMenuListItem from "./SideMenuListItem.vue";
import FeatureWrapper from "./FeatureWrapper.vue";

export default {
  components: {
    FeatureWrapper,
    SideMenuListItem,
  },
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
};
</script>
