<template>
  <LayoutPage
    :title="['Smart Units', smartUnitName, 'Assigned Devices']"
    :loading="smartUnitLoading"
  >
    <v-row>
      <v-col lg="6">
        <CardAssignedDevices allow-actions />
      </v-col>

      <v-col lg="6">
        <CardDeviceInventory :zone-id="unitId" />
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { computed } from "vue";
import LayoutPage from "@layout/LayoutPage.vue";
import CardAssignedDevices from "./CardAssignedDevices.vue";
import CardDeviceInventory from "./CardDeviceInventory.vue";
import useSmartUnit from "./useSmartUnit";

export default {
  name: "PageSmartUnitDevices",
  components: {
    LayoutPage,
    CardAssignedDevices,
    CardDeviceInventory,
  },
  setup() {
    const { smartUnit, smartUnitLoading, fetchSmartUnit } = useSmartUnit();
    const smartUnitName = computed(() => smartUnit.value?.name || "...");
    return {
      smartUnitName,
      smartUnitLoading,
      fetchSmartUnit,
    };
  },
  computed: {
    unitId() {
      return this.$route.params.zone_id;
    },
  },
  watch: {
    //in case of push route to the same page component doesn't remount
    async unitId(newId) {
      await this.fetchSmartUnit(this.$apollo, newId);
    },
  },
  async beforeMount() {
    await this.fetchSmartUnit(this.$apollo, this.unitId);
  },
};
</script>
