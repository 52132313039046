// Provides audit log utility functions
import { titleCase } from "./strings";

import { displayUnitType } from "./unitType";
import { billingAccountStatusText } from "./billingAccount";

const ignoredKeys = [
  "updated_at",
  "inserted_at",
  "updated_by",
  "text_search_english",
];

export function formatOperationType(log) {
  const operation = log?.operation;
  switch (operation) {
    case 0:
      return "added";
    case 1:
      return "updated";
    case 2:
      return "deleted";
    default:
      break;
  }
}

export function formatLogDescription(log) {
  const entityData = log?.data;
  let operation = formatOperationType(log);
  switch (log?.entity) {
    case "billing_accounts":
      return formatBillingAccount(log, operation);

    case "billing_account_organization_assignments":
      return formatBillingAccountOrganizationAssignment(log, operation);

    case "contact_methods":
      return formatContactMethod(log, operation);

    case "unit_types":
      return formatUnitType(log, operation);

    case "device_assignments":
      return formatDeviceAssignment(log, operation);

    case "devices":
      return formatDevice(log, operation);

    case "organizations":
      return formatOrganization(log, operation);

    case "organization_details":
      return formatOrganizationDetail(log, operation);

    default:
      break;
  }
  return `${titleCase(operation)} ${titleCase(log?.entity)}`;
}

function customizeLogDescription(log) {
  if (log?.primary_zone_change) {
    return `Primary zone changed from ${log.primary_zone_change}`;
  }
  if (log?.unit_type_change) {
    return `Unit type has been changed from ${log.unit_type_change}`;
  }
  return formatChangeObjectToDescription(log?.changes, [
    ...ignoredKeys,
    "path",
  ]);
}

function formatOrganization(log, operation) {
  const { organizationType, organizationSubtype, organizationName } = log;
  const description = customizeLogDescription(log);

  if (organizationType === "zone") {
    const name = titleCase(organizationName);
    const subtype = titleCase(organizationSubtype);
    const capitalizedOperation = titleCase(operation);

    switch (operation) {
      case "added":
        return `${subtype} ${name} has been added`;
      case "updated":
        const changes = description ? `: ${description}` : "with no changes";
        return `${capitalizedOperation} ${subtype} ${name} ${changes}`;
      default:
        return description;
    }
  }

  return description;
}

function formatOrganizationDetail(log, operation) {
  const description = formatChangeObjectToDescription(
    operation === "added" ? jsonDiff(log?.data, {}) : log?.changes,
    [...ignoredKeys, "organization_id"]
  );

  return `${titleCase(operation)} ${
    log?.organizationType === "zone"
      ? `${log?.organizationSubtype} ${titleCase(
          log?.organizationName
        )} details with ${
          description === "" ? "no changes" : `: ${description}`
        }`
      : ""
  }`;
}

function formatBillingAccount(log, operation) {
  operation =
    operation === "added"
      ? `${titleCase(log?.name?.split("@")[0])} has been added`
      : operation === "updated"
      ? `${titleCase(
          log?.name?.split("@")[0]
        )} has been updated: ${billingAccountStatusText(log?.status)}`
      : `${titleCase(log?.name?.split("@")[0])} has been deleted`;

  return `${operation} ${
    log?.organizationType === "zone"
      ? `${titleCase(log?.organizationSubtype)} ${log?.organizationName}`
      : ""
  }`;
}

function formatBillingAccountOrganizationAssignment(log, operation) {
  operation =
    operation === "added"
      ? log?.organizationType === "facility"
        ? `${titleCase(log?.name?.split("@")[0])} has been added `
        : `${titleCase(log?.name?.split("@")[0])} has been removed from `
      : (operation === "updated" && log?.data?.unassigned_at) ||
        operation === "deleted"
      ? log?.organizationType === "facility"
        ? `${titleCase(log?.name?.split("@")[0])} has been removed`
        : `Removed ${titleCase(log?.name?.split("@")[0])} from `
      : `${log?.organizationType === "facility" ? "Added" : "Assigned"}`;

  return `${operation} ${
    log?.organizationType === "zone"
      ? `${titleCase(log?.organizationSubtype)} ${log?.organizationName}`
      : ""
  }`;
}

function formatContactMethod(log, operation) {
  const description = `${titleCase(log?.name)}'s ${
    log?.data?.is_default ? "primary " : ""
  }${log?.data?.type} has been ${
    operation === "added"
      ? "added"
      : operation === "updated"
      ? "updated from"
      : "removed"
  }`;

  const changes = `${
    operation === "added"
      ? log?.data?.value
      : `${log?.changes?.value?.old || log?.data?.value} to ${
          log?.changes?.value?.new || log?.data?.value
        }`
  }`;

  return `${description} ${changes}`;
}

function formatUnitType(log, operation) {
  const { oldData, newData } = mergeDataWithChanges(log);

  const description = `${
    operation === "added" ? displayUnitType(newData) : displayUnitType(oldData)
  } has been ${operation}`;

  const changes = `${
    operation === "updated" ? `to ${displayUnitType(newData)}` : ""
  }`;

  return `Unit type ${description} ${changes}`;
}

function formatDevice(log, operation) {
  let disposition = log?.changes?.disposition?.new ?? log?.data?.disposition;

  operation = `Device ${log?.data?.type?.toUpperCase()}-${
    log?.data?.short_id
  } has been ${
    operation === "added"
      ? "added"
      : `marked as ${titleCase(
          disposition == "in_service" ? "found" : disposition
        )}`
  }`;

  return operation;
}

function formatDeviceAssignment(log, operation) {
  operation =
    operation === "added"
      ? `Device ${log?.data?.short_id} has been ${
          log?.organizationType === "facility" ? "moved" : "added"
        } to`
      : (operation === "updated" && log?.data?.unassigned_at) ||
        operation === "deleted"
      ? `Device ${log?.data?.short_id} has been removed from`
      : `Device ${log?.data?.short_id} has been ${
          log?.organizationType === "facility" ? "moved" : "added"
        } to`;

  return `${operation} ${
    log?.organizationType === "zone"
      ? `${titleCase(log?.organizationSubtype)} ${log?.organizationName}`
      : "inventory"
  }`;
}

function formatChangeObjectToDescription(changes, ignoreKeys = []) {
  let description = "";
  for (const key in changes) {
    if (changes.hasOwnProperty(key) && !ignoreKeys.includes(key)) {
      const change = changes[key];
      if (
        (typeof change.old === "object" || typeof change.new === "object") &&
        (change.old !== null || change.new !== null) &&
        !Array.isArray(change.old) &&
        !Array.isArray(change.new)
      ) {
        description += formatChangeObjectToDescription(
          jsonDiff(change.new, change.old),
          ignoreKeys
        );
        continue;
      }

      if (checkEquality(change.old, change.new)) continue;

      description += `${titleCase(key)}- ${
        change.old
          ? `changed from ${formatValue(change.old)} to ${formatValue(
              change.new
            )}`
          : ` ${formatValue(change.new)} has been added`
      }\n`;
    }
  }
  return description;
}

function checkEquality(oldValue, newValue) {
  if (!oldValue && !newValue) return true;

  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    return oldValue.sort().join("") === newValue.sort().join("");
  }
  return oldValue === newValue;
}

function formatValue(value) {
  if (Array.isArray(value)) {
    return value
      .map((val) => {
        return titleCase(String(val));
      })
      .join(", ");
  }
  return value;
}

function jsonDiff(newObject, oldObject) {
  const result = {};

  newObject = newObject ?? {};
  oldObject = oldObject ?? {};
  if (typeof newObject !== "object" || typeof oldObject !== "object")
    return result;

  var keys = Object.keys(newObject).concat(Object.keys(oldObject));

  keys.forEach(function (key) {
    if (!(key in oldObject) || newObject[key] !== oldObject[key]) {
      result[key] = { old: oldObject[key], new: newObject[key] };
    }
  });

  return result;
}

function mergeDataWithChanges(log) {
  const newData = { ...log.data };
  const oldData = { ...log.data };

  for (const key in log.changes) {
    if (log.changes.hasOwnProperty(key)) {
      const change = log.changes[key];
      oldData[key] = change.old;
      newData[key] = change.new;
    }
  }

  return { oldData, newData };
}
