<script>
import { Line, mixins, Scatter } from "vue-chartjs";
// https://github.com/chartjs/chartjs-adapter-luxon
// FIXME: import "chartjs-adapter-luxon";
const { reactiveProp } = mixins;

import { formatDate, formatTime12hr } from "@tod-ui/helpers/datetime";
import { DateTime } from "luxon";

export default {
  name: "ZoneTrafficChart",
  extends: Scatter,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 500,
    },
    title: {
      type: String,
      required: false,
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (item) {
            let now = DateTime.local().endOf("day");
            now = now.minus({ hours: item.xLabel });
            return `${item.value} Traffic Events on ${formatDate(
              now
            )} at ${formatTime12hr(now)}`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            // TODO: Scale based on the date window?
            display: true,
            distribution: "linear",
            ticks: {
              min: 0,
              max: 167,
              stepSize: 1,
              autoSkip: false,
              reverse: true,
              callback: function (label, index, labels) {
                let now = DateTime.local().endOf("day");
                if (index == 167) {
                  return `${now.weekdayLong} (Today)`;
                } else if ((index + 1) % 24 == 0) {
                  now = now.plus({ hours: index });
                  return now.weekdayLong;
                }
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              min: Number(this.min),
              max: Number(this.max),
            },
          },
        ],
      },
    };

    this.renderChart(this.chartData, options);
  },
  methods: {},
};
</script>
