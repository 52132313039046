// Composes complex smart unit workflows & interactions
import { addOrganizationDevices, createZone } from "../graphql/organizations";

import { buildZoneProfile } from "../components/utils/organizations";

export async function addSmartUnit(apolloClient, smartUnit, deviceIds) {
  // Create the new zone/unit
  smartUnit.profile = buildZoneProfile(smartUnit);
  const {
    data: { createZone: zone },
  } = await apolloClient.mutate({
    mutation: createZone,
    variables: { ...smartUnit },
  });

  // Assign the selected devices to the zone/unit
  const {
    data: { addOrganizationDevices: added },
  } = await apolloClient.mutate({
    mutation: addOrganizationDevices,
    variables: { id: zone.id, devices: deviceIds },
  });

  return {
    ...zone,
    devices: added,
  };
}
