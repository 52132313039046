import gql from "graphql-tag";

export const MANAGEMENT_DASHBOARD = gql`
  query Dashboard($ids: [ID]!) {
    dashboard(ids: $ids) {
      id
      annualizedLifetimeRevenue
      averageSubscriptionDuration
      endedSubscriptionsThisMonth
      lifeTimeRevenue
      missedLifetimeRevenue
      name
      penetrationRate
      totalActiveSubscriptions
      totalActiveSubscriptionsThisMonth
      totalUnits
    }
  }
`;

export const MONTHLY_SUBSCRIPTIONS = gql`
  query MonthlySubscriptions($ids: [ID]!, $month: [Date]!) {
    monthlySubscriptions(facilityIds: $ids, month: $month) {
      subscriptionMonth
      subscriptionsCount
      facilityId
      facilityName
      revenueMonth
    }
  }
`;
