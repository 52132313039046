<template>
  <CardLayout title="Overdue Settings">
    <div
      v-for="(severity, index) in severities"
      :key="index"
      class="severity-section"
    >
      <SeverityChip :severity="severity.name" />
      <v-row align="center">
        <v-col cols="4">
          <v-row align="center">
            <v-col cols="2" />
            <v-col cols="2">
              <div>Initial Open</div>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="severity.open.value"
                type="number"
                class="severity-input"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="severity.open.unit"
                :items="timeUnits"
                class="severity-select"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row align="center">
            <v-col cols="1" />
            <v-col cols="3">
              <div>Incident Completion</div>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="severity.inProgress.value"
                type="number"
                class="severity-input"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="severity.inProgress.unit"
                :items="timeUnits"
                class="severity-select"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row align="center">
            <v-col cols="1" />
            <v-col cols="3">
              <div>Mgmt. Review</div>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="severity.inReview.value"
                type="number"
                class="severity-input"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="severity.inReview.unit"
                :items="timeUnits"
                class="severity-select"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-btn depressed tile class="success" type="submit"> Save Changes </v-btn>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import SeverityChip from "./SeverityChip.vue";

export default {
  components: {
    CardLayout,
    SeverityChip,
  },
  data() {
    return {
      timeUnits: ["Hours", "Days"],
      severities: [
        {
          name: "Critical",
          open: { value: 1, unit: "Hours" },
          inProgress: { value: 1, unit: "Hours" },
          inReview: { value: 1, unit: "Hours" },
        },
        {
          name: "Severe",
          open: { value: 1, unit: "Hours" },
          inProgress: { value: 1, unit: "Hours" },
          inReview: { value: 1, unit: "Hours" },
        },
        {
          name: "Substantial",
          open: { value: 1, unit: "Days" },
          inProgress: { value: 1, unit: "Days" },
          inReview: { value: 1, unit: "Days" },
        },
        {
          name: "Moderate",
          open: { value: 1, unit: "Days" },
          inProgress: { value: 1, unit: "Days" },
          inReview: { value: 1, unit: "Days" },
        },
        {
          name: "Low",
          open: { value: 1, unit: "Days" },
          inProgress: { value: 1, unit: "Days" },
          inReview: { value: 1, unit: "Days" },
        },
      ],
    };
  },
};
</script>
