<template>
  <v-card v-if="!loading" outlined flat tile elevation="5">
    <!-- <div :style="{ backgroundColor: color, height: '18px' }"></div> -->
    <v-card-title class="title" style="font-weight: 500">
      {{ title }}
    </v-card-title>
    <div class="d-flex justify-end">
      <slot name="header-actions"></slot>
    </div>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DashboardLayout",
  props: {
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};

// 20 Unique Colors from the Material Design color pallette
const LINE_COLORS = [
  "#F44336",
  "#673AB7",
  "#03A9F4",
  "#4CAF50",
  "#FFEB3B",
  "#FF5722",
  "#E91E63",
  "#3F51B5",
  "#00BCD4",
  "#8BC34A",
  "#FFC107",
  "#795548",
  "#9C27B0",
  "#2196F3",
  "#009688",
  "#CDDC39",
  "#FF9800",
  "#607D8B",
  "#9E9E9E",
  "#000000",
];
</script>
