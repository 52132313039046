<template>
  <LayoutPage title="">
    <div class="flex-container">
      <div style="max-width: 200px">
        <v-select
          v-model="selectedRegion"
          dense
          :items="regions"
          label="Select Regions"
          outlined
        />
      </div>
      <div style="max-width: 200px">
        <v-autocomplete
          v-model="selectedFacilities"
          dense
          :items="facilities"
          label="Select Facilities"
          multiple
          outlined
          clearable
          item-text="name"
          item-value="id"
        />
      </div>
      <div style="max-width: 200px">
        <v-select
          v-model="selectedSchedule"
          :items="['During Access Hours', 'During After Hours']"
          dense
          outlined
          label="Facility Schedule"
        />
      </div>
      <div style="max-width: 200px">
        <v-select
          v-model="selectedDuration"
          :items="['Visit Within 7 Days', 'Visit Within 30 Days']"
          dense
          outlined
          label="Duration"
        />
      </div>
      <div style="max-width: 200px">
        <v-select
          v-model="selectedIsVisited"
          :items="['Yes', 'No']"
          dense
          outlined
          label="Is Visited"
        />
      </div>
      <div class="d-flex" style="min-width: 240px; margin-bottom: 25px">
        <DateTimeFilter
          v-model="dateRange"
          :ranges="['Today', '1w', '1m']"
          custom
          inverted
          time-zone="America/New_York"
        />
        <IncidentFilter class="mr-4" />
        <SearchField v-model="search" />
      </div>
    </div>
    <v-data-table
      :headers="filteredHeaders"
      :items="filteredTableData"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
    />
  </LayoutPage>
</template>

<script>
import SearchField from "@atoms/SearchField.vue";
import LayoutPage from "@components/layout/LayoutPage.vue";
import IncidentFilter from "@components/reports/IncidentFilter.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { computed, ref, watchEffect } from "vue";
import { useRoute } from "vue-router/composables";

export default {
  components: {
    SearchField,
    LayoutPage,
    IncidentFilter,
    DateTimeFilter,
  },
  setup() {
    const route = useRoute();
    const selectedDisposition = ref("Open");
    const status = computed(() => {
      return route.params.status;
    });
    const selectedDuration = ref("Visit Within 7 Days");
    const selectedIsVisited = ref("Yes");

    watchEffect(() => {
      if (status.value) {
        const [duration, visited] = status.value.split(",");
        if (duration === "30") {
          selectedDuration.value = "Visit Within 30 Days";
        } else {
          selectedDuration.value = "Visit Within 7 Days";
        }
        if (visited === "yes") {
          selectedIsVisited.value = "Yes";
        } else {
          selectedIsVisited.value = "No";
        }
      }
    });

    const tableData = ref([
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 100,
        move_out: "10/6/2024",
        incident_after_move_out: "13/6/2024, 7:00 am",
        cleaned_on: "3 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 101,
        move_out: "8/6/2024",
        incident_after_move_out: "13/6/2024, 6:53 am",
        cleaned_on: "4 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 102,
        move_out: "7/6/2024",
        incident_after_move_out: "13/6/2024, 6:36 am",
        cleaned_on: "5 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 103,
        move_out: "5/6/2024",
        incident_after_move_out: "13/6/2024, 6:34 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 104,
        move_out: "5/6/2024",
        incident_after_move_out: "13/6/2024, 5:27 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 105,
        move_out: "5/6/2024",
        incident_after_move_out: "13/6/2024, 5:12 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 106,
        move_out: "5/6/2024",
        incident_after_move_out: "13/6/2024, 1:53 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 107,
        move_out: "4/6/2024",
        incident_after_move_out: "12/6/2024, 10:53 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",
        smart_unit: 108,
        move_out: "3/6/2024",
        incident_after_move_out: "12/6/2024, 8:53 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Assert Management",
        facility: "Long Beach",

        smart_unit: 109,
        move_out: "2/6/2024",
        incident_after_move_out: "12/6/2024, 8:12 PM",
        cleaned_on: "9 Days after move out",
      },
    ]);

    const filteredTableData = computed(() => {
      if (selectedDuration.value === "Visit Within 7 Days") {
        return tableData.value.filter((entry) => {
          const days = parseInt(entry.cleaned_on.split(" ")[0]);
          return days <= 7;
        });
      }
      return tableData.value;
    });

    const filteredHeaders = computed(() => {
      const baseHeaders = [
        { text: "Organization", value: "organization" },
        { text: "Facility", value: "facility" },
        { text: "Smart Unit", value: "smart_unit" },
        { text: "MoveOut Date", value: "move_out" },
        { text: "Incident After Move Out", value: "incident_after_move_out" },
      ];

      if (selectedDuration.value !== "Visit Within 30 Days") {
        baseHeaders.push({ text: "Cleaned On", value: "cleaned_on" });
      }

      return baseHeaders;
    });

    return {
      selectedDisposition,
      tableData,
      selectedDuration,
      selectedIsVisited,
      filteredTableData,
      filteredHeaders,
    };
  },
  data() {
    return {
      dateRange: { after: "2024-04-25T06:59:59.999Z" },
      selectedSchedule: "During Access Hours",
      selectedRegion: "Grace Combs",
      regions: ["Grace Combs", "Region 2", "Region 3"],
      selectedAssignee: "Helen(Facility Manager)",
      selectedFacilities: [{ id: 1, name: "Facility 1" }],
      facilities: [
        { id: 1, name: "Facility 1" },
        { id: 2, name: "Facility 2" },
        { id: 3, name: "Facility 3" },
      ],
      search: null,
      headers: [
        { text: "Organization", value: "organization" },
        { text: "Facility", value: "facility" },
        { text: "Smart Unit", value: "smart_unit" },
        { text: "MoveOut Date", value: "move_out" },
        { text: "Incident After Move Out", value: "incident_after_move_out" },
        { text: "Cleaned On", value: "cleaned_on" },
      ],
    };
  },
  methods: {
    gotoZone(status) {
      this.$router.push({
        name: "IncidentInfo",
        params: {
          status: `${status.incident_disposition}&${status.severity}`,
        },
      });
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}
</style>
