import { ref, computed, unref } from "vue";
import { formatIncidentType } from "@components/common/helpers/incident";
import { celsiusToFahrenheit } from "@components/utils/converters";
import useFacility from "@components/authentication/useFacility";

export default function useZoneIncident(incident) {
  const incidentRef = ref(incident);
  const { facilityISOLocal } = useFacility();

  const incidentType = computed(() => incidentRef.value?.type);
  const isClimateIncident = computed(() =>
    ["temperature", "humidity"].includes(incidentType.value)
  );
  const climateConfig = computed(() => {
    if (!incidentRef.value) return undefined;

    if (incidentRef.value?.zoneProfile) {
      return incidentRef.value.zoneProfile?.climateConfig;
    }

    return incidentRef.value?.organization?.profile?.climateConfig;
  });

  const incidentEvents = computed(() => {
    const emptyValues = {
      graphData: [],
      motionEvents: [],
      telemetryEvents: [],
    };
    if (!incidentRef.value?.events) {
      return emptyValues;
    }

    return incidentRef.value.events.reduce((acc, event) => {
      const data = JSON.parse(event.data);

      const yValue = getYGraphValue(incidentType, data);
      if (yValue) {
        acc.graphData.push({
          y: yValue,
          x: facilityISOLocal(data.timestamp),
        });
      }
      if (event.name === "telemetry") {
        acc.telemetryEvents.push(data);
      } else if (isMotionEvent(incidentType, event.name, data.type)) {
        acc.motionEvents.push(data);
      }

      return acc;
    }, emptyValues);
  });
  const thresholds = computed(() => getThresholds(incidentType, climateConfig));
  const eventValues = computed(() =>
    getEventValues(incidentType, incidentEvents.value?.telemetryEvents)
  );

  const highValue = computed(() =>
    incidentRef.value ? Math.ceil(Math.max(...eventValues.value)) : undefined
  );
  const lowValue = computed(() =>
    incidentRef.value ? Math.floor(Math.min(...eventValues.value)) : undefined
  );

  const violatedThreshold = computed(() => {
    if (!incidentRef.value || !isClimateIncident.value) return undefined;

    return lowValue.value < thresholds.value[0]
      ? thresholds.value[0]
      : highValue.value > thresholds.value[1]
      ? thresholds.value[1]
      : undefined;
  });

  const dataLabel = computed(() => {
    switch (incidentType.value) {
      case "humidity":
        return "%";
      case "temperature":
        return "°F";
    }
    return "";
  });

  const incidentTriggerDescription = computed(() => {
    if (!incidentRef.value) return undefined;

    const violatedPreposition =
      violatedThreshold.value === thresholds.value[0]
        ? "below"
        : violatedThreshold.value === thresholds.value[1]
        ? "above"
        : "";
    switch (incidentType.value) {
      case "temperature":
        return `Temperature ${violatedPreposition} ${violatedThreshold?.value}${dataLabel.value}`;
      case "humidity":
        return `Humidity ${violatedPreposition} ${violatedThreshold?.value}${dataLabel.value}`;
      case "entry":
        return incidentRef.value?.organization?.name
          ? `${incidentRef.value.organization.name} Open`
          : formatIncidentType(incidentRef.value);
      default:
        return formatIncidentType(incidentRef.value);
    }
  });

  return {
    isClimateIncident,
    incidentEvents,
    eventValues,
    incidentType,
    lowThreshold: computed(() => thresholds.value[0]),
    lowValue,
    highThreshold: computed(() => thresholds.value[1]),
    highValue,
    violatedThreshold,
    dataLabel,
    incidentTriggerDescription,
  };
}

function getYGraphValue(incidentTypeRef, data) {
  const incidentType = unref(incidentTypeRef);
  switch (incidentType) {
    case "humidity":
      return data.humid_pcnt || null;
    case "temperature":
      return data.temp_celcius
        ? Math.round(celsiusToFahrenheit(data.temp_celcius))
        : null;
    case "motion":
      return ["motion_started", "motion_detected"].includes(data.type)
        ? 1
        : null;
    case "entry":
      return ["door_opened"].includes(data.type) ? 1 : null;
    case "service_request":
      return ["service_request_pressed"].includes(data.type) ? 1 : null;
  }
  return null;
}

function isMotionEvent(incidentTypeRef, eventName, dataType) {
  const incidentType = unref(incidentTypeRef);
  return eventName === "motion" &&
    incidentType === "motion" &&
    ["motion_started", "motion_detected"].includes(dataType)
    ? true
    : eventName === "trigger"
    ? incidentType === "entry" && ["door_opened"].includes(dataType)
      ? true
      : incidentType === "service_request" &&
        ["service_request_pressed"].includes(dataType)
      ? true
      : false
    : false;
}

function getThresholds(incidentTypeRef, climateConfigRef) {
  const incidentType = unref(incidentTypeRef);
  const climateConfig = unref(climateConfigRef);
  if (!incidentType || !climateConfig) return [undefined, undefined];
  switch (incidentType) {
    case "humidity":
      return [
        Number(climateConfig?.lowHumidityThreshold),
        Number(climateConfig?.highHumidityThreshold),
      ];
    case "temperature":
      return [
        Math.floor(celsiusToFahrenheit(climateConfig?.lowTemperatureThreshold)),
        Math.ceil(celsiusToFahrenheit(climateConfig?.highTemperatureThreshold)),
      ];
  }
  return [undefined, undefined];
}

function getEventValues(incidentTypeRef, telemetryEvents) {
  const incidentType = unref(incidentTypeRef);
  if (!incidentType || !telemetryEvents) return [];

  switch (incidentType) {
    case "humidity":
      return telemetryEvents.reduce((acc, event) => {
        if (event?.humid_pcnt !== undefined && event.humid_pcnt !== null) {
          acc.push(Number(event.humid_pcnt));
        }
        return acc;
      }, []);
    case "temperature":
      return telemetryEvents.reduce((acc, event) => {
        if (event?.temp_celcius !== undefined && event.temp_celcius !== null) {
          acc.push(celsiusToFahrenheit(event.temp_celcius));
        }
        return acc;
      }, []);
  }
  return [];
}
