<template>
  <CardLayout title="Incident Severity Setting">
    <v-row align="center">
      <v-col cols="2">
        <div class="subtitle-2">Extensive</div>
      </v-col>
      <v-col cols="2">
        <v-select
          :items="['Temperature', 'Humidity', 'Motion']"
          label="Incident Type"
        />
      </v-col>
      <v-col cols="2">
        <v-select :items="['Daily', 'Weekly', 'Monthly']" label="Frequency" />
      </v-col>
      <v-col cols="2">
        <v-text-field label="Value" type="number" />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2">
        <div class="subtitle-2">Frequent</div>
      </v-col>
      <v-col cols="2">
        <v-select
          :items="['Temperature', 'Humidity', 'Motion']"
          label="Incident Type"
        />
      </v-col>
      <v-col cols="2">
        <v-select :items="['Daily', 'Weekly', 'Monthly']" label="Frequency" />
      </v-col>
      <v-col cols="2">
        <v-text-field label="Value" type="number" />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2">
        <div class="subtitle-2">Initial</div>
      </v-col>
      <v-col cols="2">
        <v-select
          :items="['Temperature', 'Humidity', 'Motion']"
          label="Incident Type"
        />
      </v-col>
      <v-col cols="2">
        <v-select :items="['Daily', 'Weekly', 'Monthly']" label="Frequency" />
      </v-col>
      <v-col cols="2">
        <v-text-field label="Value" type="number" />
      </v-col>
    </v-row>
    <v-spacer />
    <v-btn depressed tile class="success" type="submit"> Save Changes </v-btn>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";

export default {
  components: {
    CardLayout,
  },

  setup() {
    return {};
  },
  data() {
    return {
      openIncidentDays: 1,
      inProgressIncidentDays: 1,
    };
  },
};
</script>

<style lang="scss" scoped></style>
