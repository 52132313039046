<template>
  <v-menu v-if="hasPMS" bottom right offset-y max-width="200px">
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom max-width="380" :disabled="false">
        <template #activator="{ on: tooltip }">
          <div v-on="tooltip">
            <v-btn
              icon
              v-bind="attrs"
              class="pa-7 mr-n2"
              :disabled="btnDisabled"
              v-on="menu"
            >
              <span class="fa-stack">
                <i class="fas fa-sync-alt fa-stack-2x"></i>
                <i
                  v-if="!syncCompleted"
                  class="fas fa-clock fa-stack-1x warning--text"
                ></i>
              </span>
            </v-btn>
          </div>
        </template>
        <span v-if="btnDisabled">
          Synchronization with
          <strong>{{ propertyManagementSystem }}</strong> has been started. It
          can take up to a few min for changes to reflect.
        </span>
        <span v-else-if="!syncCompleted">
          Synchronizing with <strong>{{ propertyManagementSystem }}</strong
          >;
          <span v-if="unitsRemain !== null">
            {{ unitsCompleted }} units connected as of today,
            {{ unitsRemain }} remain.
          </span>
          <span v-else>units remain: TBD.</span>
        </span>
        <span v-else>
          Manually trigger synchronization with
          <strong>{{ propertyManagementSystem }}</strong>
        </span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-text>
        <div v-if="!syncCompleted">
          Sync is in process.<br />
          <span v-if="unitsRemain !== null">
            {{ unitsCompleted }} units connected as of today,
            {{ unitsRemain }} remain.
          </span>
          <span v-else>units remain: TBD.</span>
          <br />
          <br />
        </div>
        <strong>{{ availablePMSQuota }}</strong> out of
        <strong>{{ totalPMSQuota }}</strong>
        {{ propertyManagementSystem }} sync requests remain for the day.
        <div v-if="resetQuotaTime">
          More requests will be available in
          {{ resetQuotaTime }}
        </div>
      </v-card-text>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-card-actions>
            <v-btn
              text
              :disabled="availablePMSQuota === 0"
              @click="triggerSync"
              v-on="on"
            >
              {{ syncNowBtnName }}
            </v-btn>
          </v-card-actions>
        </template>
        <span>{{ syncNowTooltipText }}</span>
      </v-tooltip>
    </v-card>
  </v-menu>
</template>

<script>
import { computed, ref, watchEffect, watch } from "vue";
import useAlerts from "@tod-ui/composables/useAlerts";
import usePMS from "../common/usePMS";
import useSmartUnit from "@components/smart_units/useSmartUnit";
import useRenter from "@components/renters/useRenter";
import { useRoute } from "vue-router/composables";

const STARTED_TIMESTAMP = "sync_started";
const DISABLE_FOR_MIN = 1;

export default {
  name: "PmsSyncNow",
  setup() {
    const {
      propertyManagementSystem,
      hasPMS,
      pmsSiteId,
      pmsServiceHasSync,
      pmsServiceHasUnitRenterSync,
      syncCompleted,
      $unitsCompleted,
      $unitsRemain,
      fetchSyncSummary,
      fetchPMSQuotas,
      triggerPMSSync,
      resetQuotaTime,
      availablePMSQuota,
      totalPMSQuota,
    } = usePMS(true);
    const { smartUnit } = useSmartUnit();
    const { renter } = useRenter();

    const timestampStarted = parseInt(
      window.localStorage.getItem(STARTED_TIMESTAMP)
    );
    const timeLeft = !isNaN(timestampStarted)
      ? DISABLE_FOR_MIN * 60 * 1000 - (Date.now() - timestampStarted)
      : false;

    const btnDisabled = ref(true);
    const syncNowItemId = ref(null);
    const syncNowBtnName = ref("Sync Now");
    const syncNowTooltipText = ref("Sync All Units");
    const route = useRoute();

    // Computed property to determine the current page
    const currentPage = computed(() => {
      switch (route?.name) {
        case "AssignedSmartUnitDrilldown":
          return "unit";
        case "ViewSmartUnit":
          return "unit";
        case "ViewRenter":
          return "renter";
        default:
          return null;
      }
    });

    watchEffect(() => {
      updateSyncInfo();
    });

    function updateSyncInfo() {
      if (pmsServiceHasUnitRenterSync.value) {
        switch (currentPage.value) {
          case "unit":
            setUnitSyncInfo();
            break;
          case "renter":
            setRenterSyncInfo();
            break;
        }
      } else {
        resetToDefaultSyncInfo();
      }
    }

    function setUnitSyncInfo() {
      const unit = smartUnit.value;
      if (unit) {
        syncNowItemId.value = unit.externalId;
        syncNowBtnName.value = "Sync Unit";
        syncNowTooltipText.value = `Sync only unit ${unit.name}`;
      }
    }

    function setRenterSyncInfo() {
      const tenant = renter.value;
      if (tenant) {
        syncNowItemId.value = tenant.externalId;
        syncNowBtnName.value = "Sync Tenant";
        syncNowTooltipText.value = `Sync only tenant ${tenant.firstName} ${tenant.lastName}`;
      }
    }

    function resetToDefaultSyncInfo() {
      syncNowBtnName.value = "Sync Now";
      syncNowTooltipText.value = "Sync All Units";
      syncNowItemId.value = null;
    }

    watch(
      pmsServiceHasSync,
      (val) => {
        if (val && pmsSiteId.value) {
          if (timeLeft > 0) {
            btnDisabled.value = true;
            scheduleEnableBtn(timeLeft);
          } else {
            btnDisabled.value = false;
          }
        } else {
          btnDisabled.value = true;
        }
      },
      { immediate: true }
    );

    function scheduleEnableBtn(time = DISABLE_FOR_MIN * 60 * 1000) {
      setTimeout(() => {
        window.localStorage.removeItem(STARTED_TIMESTAMP);
        btnDisabled.value = false;
      }, time);
    }

    const { addAlert } = useAlerts();

    return {
      propertyManagementSystem,
      hasPMS,
      pmsSiteId,
      syncCompleted,
      unitsCompleted: $unitsCompleted,
      unitsRemain: $unitsRemain,
      availablePMSQuota,
      totalPMSQuota,
      resetQuotaTime,
      btnDisabled,
      scheduleEnableBtn,
      fetchSyncSummary,
      fetchPMSQuotas,
      triggerPMSSync,
      addAlert,
      currentPage,
      syncNowBtnName,
      syncNowItemId,
      syncNowTooltipText,
    };
  },

  watch: {
    pmsSiteId: {
      handler(siteId) {
        if (siteId) {
          this.fetchSyncSummary(this.$apolloProvider);
          this.fetchPMSQuotas(this.$apolloProvider);
        }
      },
      immediate: true,
    },
  },

  methods: {
    async triggerSync() {
      this.btnDisabled = true;
      const result = await this.triggerPMSSync(
        this.$apolloProvider,
        this.currentPage,
        [this.syncNowItemId]
      );
      if (result) {
        this.scheduleEnableBtn();
        this.addAlert({
          type: "success",
          message: `Synchronization with ${this.propertyManagementSystem} successfully started. It can take up to a few minutes to reflect all the changes.`,
          timeout: 20,
        });
      } else {
        this.btnDisabled = false;
      }

      if (this.btnDisabled) {
        window.localStorage.setItem(STARTED_TIMESTAMP, Date.now());
      } else {
        window.localStorage.removeItem(STARTED_TIMESTAMP);
      }
    },
  },
};
</script>

<style scoped>
.fa-stack {
  font-size: 8px;
}
.fa-stack-1x {
  transform: translate(9px, 9px);
  font-size: 12px;
}
</style>
