<template>
  <LayoutPage title="Regional Reports">
    <v-tabs v-model="tab">
      <v-tab key="summaries">
        {{ organizationSummaryTitle }}
      </v-tab>
      <v-tab key="monthly_subscriptions">
        {{ organizationMontlySubscriptionTitle }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="summaries">
        <OrganizationFacilitySummaries />
      </v-tab-item>
      <v-tab-item key="monthly_subscriptions">
        <OrganizationFacilitySubscriptions />
      </v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>

<script>
import { computed, watch } from "vue";
import useUser from "../authentication/useUser";
import useRouteQuery from "@components/common/useRouteQuery";

import OrganizationFacilitySummaries from "./OrganizationFacilitySummaries.vue";
import OrganizationFacilitySubscriptions from "./OrganizationFacilitySubscriptions.vue";
import LayoutPage from "@layout/LayoutPage.vue";

export default {
  name: "PageReportsOrganization",
  components: {
    LayoutPage,
    OrganizationFacilitySummaries,
    OrganizationFacilitySubscriptions,
  },
  setup() {
    const { defaultOrganization } = useUser();

    const { tab, accordion } = useRouteQuery();

    //reset accordion value on tab switch
    watch(tab, (_value) => {
      accordion.value = undefined;
    });

    const organizationSummaryTitle = computed(
      () => `${defaultOrganization.value.name} Summary`
    );

    const organizationMontlySubscriptionTitle = computed(
      () => `${defaultOrganization.value.name} Subscriptions`
    );

    return {
      tab,
      organizationSummaryTitle,
      organizationMontlySubscriptionTitle,
    };
  },
};
</script>
