<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row dense justify="end">
        <v-col cols="12" xs="12" md="5">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View traffic for..."
            :items="smartZones"
            item-text="name"
            item-value="id"
            single-line
          />
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <ZoneTrafficReportChart
          :chart-data="zoneTraffic"
          :max="chartMax"
          :min="chartMin"
        />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import { getDaysAgo } from "@tod-ui/helpers/datetime";

import ZoneTrafficReportChart from "./ZoneTrafficReportChart.vue";

import { zonePeopleCountingEvents } from "../../graphql/organizations/smartZones";
import { organizationSmartZones } from "../../graphql/organizations";
import useFacility from "../authentication/useFacility";

export default {
  name: "ZoneTrafficReport",
  components: {
    ZoneTrafficReportChart,
  },
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    zoneTraffic: [],
    chartMin: 0,
    chartMax: 6,
    bucketsPerDay: 24,
    zoneOptions: [],
    smartZones: [],
    selectedZone: null,
  }),
  computed: {
    loading() {
      const { smartZones, zoneTraffic } = this.$apollo.queries;
      return smartZones.loading || zoneTraffic.loading;
    },
    dateRange() {
      return {
        after: getDaysAgo(7),
      };
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          sort: "name",
        };
      },
      update({ smartZones }) {
        const selectableZones = [
          {
            id: "all",
            name: "All Zones",
          },
          {
            divider: true,
          },
        ];

        smartZones.reduce((acc, zone) => {
          // must have devices and people-counting enabled
          if (zone.devices.length) {
            const config = zone.profile.motionConfigs?.find(
              ({ mode, disabled }) => mode === "people_counting" && !disabled
            );

            if (config) acc.push({ id: zone.id, name: zone.name });
          }

          return acc;
        }, selectableZones);

        return selectableZones;
      },
    },
    zoneTraffic: {
      query: zonePeopleCountingEvents,
      variables() {
        const vars = {
          aggregate: "hour",
          dateTimeFilter: this.dateRange,
          sort: "timestamp",
        };

        if (!this.selectedZone || this.selectedZone === "all") {
          vars.parentId = this.currentFacilityId;
        } else {
          vars.id = this.selectedZone;
        }

        return vars;
      },
      update({ zoneTraffic }) {
        // Track the max event count for the chart scale/boundary
        let maxCount = 0;

        let data_array = this.initializeDataArray(0);
        for (let zt of zoneTraffic) {
          let ts = this.formatDateTime(zt.timestamp);
          const dataElem = data_array.find(({ x }) => x == ts);
          if (dataElem) {
            dataElem.y += 1;
            if (dataElem.y > maxCount) {
              maxCount = dataElem.y;
            }
          }
        }
        this.chartMax = maxCount + Math.ceil(maxCount / 20);
        if (this.chartMax == 0) {
          this.chartMax = 1;
        }
        return {
          datasets: [
            {
              label: `Zone Traffic from the last Week`,
              borderColor: "#F44336",
              pointBackgroundColor: "#F44336",
              pointBorderColor: "#F44336",
              showLine: true,
              data: data_array,
            },
          ],
        };
      },
    },
  },
  methods: {
    formatDateTime(datetime) {
      if (!datetime) return datetime;
      const now = DateTime.local().setZone(this.facilityTimeZone);
      const dt = DateTime.fromISO(datetime, {
        zone: this.facilityTimeZone,
      });
      const diff = now.diff(dt, "hours");
      return Math.floor(diff.hours);
    },
    initializeDataArray(initialValue) {
      let data_array = [];
      for (let i = 0; i < 7 * 24; i++) {
        data_array.push({
          x: i,
          y: initialValue,
        });
      }
      return data_array;
    },
  },
};
</script>
