<template>
  <ValidationObserver ref="validator" v-slot="{ handleSubmit }">
    <form ref="formInput" @submit.prevent="handleSubmit(save)">
      <v-row dense>
        <v-col lg="6" sm="12">
          <PossiblePMS v-slot="{ disabled }" :sync-obj="renter">
            <ValidatedTextField
              v-model="renter.firstName"
              name="First Name"
              rules="required|max:250"
              :disabled="disabled || disabledInputs"
            />
          </PossiblePMS>
        </v-col>
        <v-col lg="6" sm="12">
          <PossiblePMS v-slot="{ disabled }" :sync-obj="renter">
            <ValidatedTextField
              v-model="renter.lastName"
              name="Last Name"
              rules="required|max:250"
              :disabled="disabled || disabledInputs"
            />
          </PossiblePMS>
        </v-col>

        <v-col :lg="demoRenter ? 12 : 6" sm="12">
          <PossiblePMS
            v-slot="{ disabled }"
            :sync-obj="renter"
            hint-for="primaryPhoneMethod"
          >
            <InputPhoneNumber
              v-model="primaryPhoneMethod"
              name="Primary Mobile Phone"
              required
              show-failing
              :disabled="disabled || disabledInputs"
              :contact-log-time-zone="facilityTimeZone"
            />
          </PossiblePMS>
        </v-col>
        <v-col v-if="!demoRenter" lg="6" sm="12">
          <InputPhoneNumber
            v-model="secondaryPhoneMethod"
            name="Secondary Mobile Phone"
            show-failing
            :disabled="disabledInputs"
            :contact-log-time-zone="facilityTimeZone"
          />
        </v-col>

        <v-col v-if="!demoRenter" lg="12">
          <PossiblePMS v-slot="{ disabled }" :sync-obj="renter">
            <ValidatedTextField
              v-model="emailContactMethod.value"
              name="Email"
              rules="email|min:5|max:250"
              type="email"
              :disabled="disabled || disabledInputs"
            />
          </PossiblePMS>
        </v-col>
      </v-row>

      <v-row v-if="additionalContactMethods.length > 0" dense>
        <v-col v-for="cm in additionalContactMethods" :key="cm.id" lg="6">
          <InputPhoneNumber
            v-if="cm.type == 'mobile'"
            :value="cm"
            disabled
            show-failing
            name="Additional Alert Recipient"
            :contact-log-time-zone="facilityTimeZone"
          />
          <InputPhoneNumber
            v-else-if="cm.type == 'phone'"
            :value="cm"
            disabled
            show-failing
            name="Additional Landline Phone"
            :contact-log-time-zone="facilityTimeZone"
          />
          <v-text-field
            v-else
            :value="cm.value"
            disabled
            label="Additional Email"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="
          languageOptions.length > 0 &&
          (languageOptions.length !== 1 || languageOptions[0].value !== 'en')
        "
        dense
      >
        <v-col md="6" lg="6">
          <v-select
            v-model="renter.preferredLanguage"
            :items="languageOptions"
            label="Language Preference"
          />
        </v-col>
      </v-row>

      <v-card-actions v-if="!newRenter">
        <ButtonRenterStatus v-if="!demoRenter" v-on="$listeners" />

        <PossiblePMS
          v-if="!demoRenter"
          v-slot="{ disabled }"
          :sync-obj="renter"
          content-class="ml-2"
        >
          <v-btn
            small
            color="warning"
            :disabled="disabled"
            @click="$emit('remove-renter')"
          >
            Remove Renter
          </v-btn>
        </PossiblePMS>

        <v-spacer />
        <v-btn small color="success" type="submit"> Save Renter </v-btn>
      </v-card-actions>
      <v-btn
        v-if="!newRenter && !demoRenter"
        outlined
        small
        color="black"
        class="ml-2 mt-2"
        target="_blank"
        href="https://drive.google.com/file/d/1qXoWGoFAb6doswN0mA06KXd9xxzvIA9V/view"
      >
        Download a Blank Tenant Addendum
      </v-btn>
    </form>
  </ValidationObserver>
</template>

<script>
import ValidatedTextField from "../utils/ValidatedTextField.vue";
import PossiblePMS from "../common/PossiblePMS.vue";
import ButtonRenterStatus from "./ButtonRenterStatus.vue";
import InputPhoneNumber from "@tod-ui/components/InputPhoneNumber.vue";

import { max, min, email, required } from "vee-validate/dist/rules";
import { extend, setInteractionMode, ValidationObserver } from "vee-validate";
import useRenter from "./useRenter";
import useAlerts from "@tod-ui/composables/useAlerts";
import { titleCase, languageOptions } from "@tod-ui/helpers/strings";
import useFacility from "@components/authentication/useFacility";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "{_field_} is required",
});

extend("email", {
  ...email,
  message: '"{_value_}" is not a valid email',
});

export default {
  name: "FormRenterInfo",
  components: {
    PossiblePMS,
    ValidatedTextField,
    ValidationObserver,
    ButtonRenterStatus,
    InputPhoneNumber,
  },
  props: {
    newRenter: {
      type: Boolean,
      default: false,
    },
    demoRenter: {
      type: Boolean,
      default: false,
    },
    disabledInputs: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      renter,
      primaryPhoneMethod,
      secondaryPhoneMethod,
      emailContactMethod,
      secondaryPhoneAdded,
      additionalContactMethods,
      saveRenter,
    } = useRenter();

    const { addAlert, clearAllAlerts } = useAlerts();
    const { facilityTimeZone, currentFacility } = useFacility();
    return {
      renter,
      primaryPhoneMethod,
      secondaryPhoneMethod,
      emailContactMethod,
      secondaryPhoneAdded,
      additionalContactMethods,
      saveRenter,
      addAlert,
      clearAllAlerts,
      facilityTimeZone,
      currentFacility,
    };
  },
  computed: {
    languageOptions() {
      const langOptions = languageOptions();

      const facilityOptions =
        this.currentFacility?.profile?.languageOptions || [];

      return langOptions
        .filter((lp) => facilityOptions.includes(lp.code))
        .map((lang) => ({
          value: lang.code,
          text: titleCase(lang.name),
        }));
    },
  },
  methods: {
    async validate() {
      return await this.$refs.validator.validate();
    },
    reset() {
      return this.$refs.validator.reset();
    },
    focusPrimaryPhone() {
      const input = this.$refs.formInput.querySelector("[type='tel']");
      this.$nextTick(() => input.focus());
    },
    async createRenter() {
      if (await this.$refs.validator.validate()) {
        return await this.$refs.createAlert.tryCreateRenter();
      }
      return false;
    },
    async save() {
      this.clearAllAlerts();
      // snapshot of the reactive value
      const secondaryPhoneAdded = this.secondaryPhoneAdded;

      try {
        const message = await this.saveRenter(this.$apollo);
        this.addAlert({ type: "success", message, timeout: 15 });
        if (secondaryPhoneAdded) {
          this.$emit("added-secondary-phone", {
            ...this.secondaryPhoneMethod,
          });
        }
      } catch (errorMessage) {
        this.addAlert({ message: errorMessage, timeout: 20 });
      }
    },
  },
};
</script>
