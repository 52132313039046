<template>
  <LayoutPage title="Penetrations & Annualized Lifetime Revenue">
    <div class="container">
      <v-select
        v-model="selectedRegion"
        :items="['Grace Combs']"
        label="Select Region"
      />
      <v-select
        v-model="selectedFacility"
        :items="['Facility A']"
        label="Select Facility"
      />
      <v-select v-model="selectedYear" label="Year" :items="['2024', '2023']" />
      <v-select
        v-model="selectedMonth"
        label="Month"
        :items="['January', 'February', 'March', 'April', 'May', 'June']"
      />
    </div>
    <div class="table-container">
      <v-data-table
        :headers="penetrationTable.headers"
        :items="penetrationTable.body"
        :items-per-page="5"
        class="elevation-1"
      >
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.facilityName">
              <template v-for="(value, key) in item">
                <CustomTableCell
                  :key="key"
                  :value="value"
                  :is-highlighted="isHighlighted(key)"
                  :unit="getUnit(key)"
                />
              </template>
            </tr>
            <tr class="total">
              <td :class="{ highlighted: isHighlighted('total') }">Total</td>
              <td :class="{ highlighted: isHighlighted('total') }">410</td>
              <td :class="{ highlighted: isHighlighted('total') }">740</td>
              <td
                :class="{ highlighted: isHighlighted('facilityPenetrations') }"
              >
                56%
              </td>
              <td :class="{ highlighted: isHighlighted('annualizedRevenue') }">
                $59,040
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CustomTableCell from "./CustomTableCell.vue";
import useUser from "../useUser";
import { facilityData } from "./data";
import useDashboard from "./useDashboard";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";

export default {
  name: "ConversionTable",
  components: {
    LayoutPage,
    CustomTableCell,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const {
      fetchOrganizationDescendants,
      selectedFacilityIds,
      filteredFacilities,
      dashboardData,
      penetrationTable,
    } = useDashboard();

    const route = useRoute();

    const highlightColumn = computed(() => {
      return route.params.highlightColumn;
    });

    const isHighlighted = (column) => {
      if (highlightColumn.value === "penetration") {
        return "facilityPenetrations" === column;
      } else if (highlightColumn.value === "annualizedRevenue") {
        return "annualizedRevenue" === column;
      }
      return column === highlightColumn.value;
    };

    const getColor = (column, value) => {
      return "rgba(10,1,131,0.8)";
    };

    const getUnit = (column) => {
      if (column === "facilityPenetrations") {
        return "%";
      } else if (column === "annualizedRevenue") {
        return "$";
      }
      return "";
    };

    return {
      fetchOrganizationDescendants,
      selectedFacilityIds,
      filteredFacilities,
      defaultOrganization,
      dashboardData,
      isHighlighted,
      getColor,
      highlightColumn,
      penetrationTable,
      getUnit,
    };
  },

  data() {
    return {
      selectedYear: "2024",
      selectedMonth: "June",
      trends: [
        { name: "By Trends", value: "byTrends" },
        { name: "By Months", value: "byMonths" },
      ],
      period: [
        { name: "Last 12 Months", value: "last12Months" },
        { name: "Custom Period", value: "customPeriod" },
      ],
      selectedRegion: "Grace Combs",
      selectedFacility: "Facility A",
    };
  },
  watch: {
    filteredFacilities(newFacilities) {
      this.selectedFacilityIds = newFacilities.map((facility) => facility.id);
    },
    selectedFacilityIds(facilityIds) {
      this.dashboardData = facilityData.filter((facility) =>
        facilityIds.includes(facility.id)
      );
    },
  },
  beforeMount() {
    this.fetchOrganizationDescendants(
      this.$apollo,
      this.defaultOrganization.id,
      ["region", "facility"]
    );
    this.selectedFacilityIds = this.filteredFacilities.map(
      (facility) => facility.id
    );
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-container td {
  min-width: 150px;
}

.container {
  display: flex;
  gap: 15px;
}

.total td {
  font-weight: bold;
}

.negative-value {
  color: red;
}

.positive-value {
  color: green;
}

.highlighted {
  background-color: rgba(14, 59, 110, 0.2); /* Highlight color */
}
</style>
