<template>
  <v-dialog v-model="dialog" width="800px" max-width="800px">
    <template #activator="{ on }">
      <v-btn
        :small="small"
        depressed
        color="transparent"
        title="View Video"
        v-on="on"
      >
        <v-icon color="success" :small="small" left>
          fas fa-play-circle
        </v-icon>
        View Video
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Video Viewer
        <v-spacer />
        <v-btn icon @click="cancel">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4" style="height: 600px">
        <iframe
          :src="videoConfig.videoUrl"
          width="100%"
          height="100%"
          frameborder="0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VideoViewer",
  props: {
    zone: {
      type: Object,
      required: false,
    },
    videoConfig: {
      type: Object,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
