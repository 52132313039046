import gql from "graphql-tag";
import { incidentFields, organizationFields } from "../../graphql/fragments";

export const FACILITY_SUBSCRIPTIONS = gql`
  query FacilitySubscriptions(
    $organizationId: ID!
    $dateTimeFilter: DateTimeFilter
  ) {
    facilitySubscriptions(
      organizationId: $organizationId
      dateTimeFilter: $dateTimeFilter
    ) {
      activeSmartUnits
      activeSubscriptions
      added
      ended
    }
  }
`;

export const INCIDENT_HISTORY = gql`
  query IncidentHistory(
    $parentId: ID!
    $dateTimeFilter: DateTimeFilter!
    $organizationSubtypes: [String]
    $resolutions: [String]
    $statuses: [String]
    $types: [String]
    $duringAccessHours: Boolean
    $bypassedAccess: Boolean
    $accessStatuses: [String]
  ) {
    incidents(
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      organizationSubtypes: $organizationSubtypes
      resolutions: $resolutions
      statuses: $statuses
      types: $types
      duringAccessHours: $duringAccessHours
      bypassedAccess: $bypassedAccess
      accessStatuses: $accessStatuses
      sort: "startedAt desc"
    ) {
      ...IncidentFields
      organization {
        ...OrganizationFields
      }
      responders {
        id
        escalationLevel
        name
        type
        status
        contactMethod {
          id
          type
          value
        }
      }
    }
  }
  ${incidentFields}
  ${organizationFields}
`;

export const FACILITY_REPORT_INCIDENTS = gql`
  query FacilityReportIncidents($parentId: ID!, $after: DateTime!) {
    incidents(
      parentId: $parentId
      dateTimeFilter: { after: $after }
      organizationSubtype: "unit"
      sort: "startedAt asc"
    ) {
      ...IncidentFields
    }
  }
  ${incidentFields}
`;
