// Provides datetime utility functions
import { DateTime, Duration, Interval, IANAZone } from "luxon";

export const MS_PER_DAY = 86400000;
const NBSP = "\xa0";

export function convertToLuxonDT(datetime, timeZone) {
  return datetime === "now" || datetime === "today"
    ? timeZone
      ? utcNow().setZone(timeZone)
      : utcNow()
    : timeZone
    ? DateTime.fromISO(datetime, { zone: timeZone })
    : DateTime.fromISO(datetime);
}

export function formatDate(datetime, timeZone = null) {
  if (!datetime) return datetime;

  const dt = convertToLuxonDT(datetime, timeZone);
  return dt.toLocaleString();
}

export function formatDateTime(datetime, timeZone = null, withSeconds = false) {
  if (!datetime) return datetime;

  const dt = convertToLuxonDT(datetime, timeZone);

  return dt.toLocaleString(
    withSeconds ? DateTime.DATETIME_SHORT_WITH_SECONDS : DateTime.DATETIME_SHORT
  );
}

export function formatDateTime4Humans(
  datetime,
  timeZone = null,
  shortFor1Week = false,
  includeWeekDay = true
) {
  const dt = timeZone
    ? DateTime.fromISO(datetime, { zone: timeZone })
    : DateTime.fromISO(datetime);

  const now = timeZone ? utcNow().setZone(timeZone) : utcNow();
  const isToday =
    now.hasSame(dt, "day") &&
    now.hasSame(dt, "month") &&
    now.hasSame(dt, "year");

  const date = shortFor1Week
    ? isToday
      ? "today"
      : dt.toFormat("EEE")
    : includeWeekDay
    ? dt.toFormat("EEE, MMM d y,")
    : dt.toFormat("MMM d y,");

  const meridiem = dt.hour < 12 ? "a.m." : "p.m.";

  return `${date} ${dt.toFormat("h:mm")} ${meridiem}`;
}

export function formatTime12hr(time) {
  if (!time) return time;

  // Convert ISO Time into components
  let { hour, minute } = DateTime.fromISO(time);
  let meridiem = hour < 12 ? "a.m." : "p.m.";

  // The hour select uses '12' and not '0' for midnight
  if (hour === 0) hour = 12;

  const value = {
    hour: (hour <= 12 ? hour : hour % 12).toString(),
    minute: minute.toLocaleString("en", { minimumIntegerDigits: 2 }),
    meridiem,
  };

  return minute > 0
    ? `${value.hour}:${value.minute}${NBSP}${value.meridiem}`
    : `${value.hour}${NBSP}${value.meridiem}`;
}

export function formatTimeDayOfWeek(dt) {
  let day = dt.weekdayLong;
  return `${day}`;
}

export function toISOLocal(datetime, timeZone) {
  if (!datetime) return datetime;

  let dt = DateTime.fromISO(datetime, { zone: timeZone });
  return dt.toISO();
}

export function diff(startAt, endAt = utcNow()) {
  if (!startAt) return undefined;
  const _startAt = DateTime.fromISO(startAt);
  const _endAt = DateTime.fromISO(endAt);

  return _endAt.diff(_startAt);
}

export function durationInWords(startAt, endAt = utcNow()) {
  if (!startAt) return "N/A";
  const _startAt = DateTime.fromISO(startAt);
  const _endAt = DateTime.fromISO(endAt);

  const duration = Interval.fromDateTimes(_startAt, _endAt).toDuration([
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
  ]);

  if (duration.years > 0) {
    return `More than a year`;
  } else if (duration.months > 0) {
    const months = Math.round(duration.months);
    if (months === 1) return `${months} month`;
    return `${months} months`;
  } else if (duration.weeks > 0) {
    const weeks = Math.round(duration.weeks);
    if (weeks === 1) return `${weeks} week`;
    return `${weeks} weeks`;
  } else if (duration.days > 0) {
    const days = Math.round(duration.days);
    if (days === 1) return `${days} day`;
    return `${days} days`;
  } else if (duration.hours > 0) {
    const hours = Math.round(duration.hours);
    if (hours === 1) return `${hours} hour`;
    return `${hours} hours`;
  } else {
    const minutes = Math.round(duration.minutes);
    if (minutes > 1) return `${minutes} minutes`;
    return `< 1 minute`;
  }
}

export function getDaysAgo(days) {
  return utcNow().minus({ days: days });
}

export function getRangeAgo(range, date = utcNow(), timeZone = null) {
  const value =
    typeof range === "string" && range.toLowerCase() === "today" ? "1d" : range;
  const includeToday = date === "today" || value === "1d";
  const dt =
    typeof date === "string" ? utcDate(date, includeToday, timeZone) : date;
  const { unit, count } = unitCountFromRange(value);
  return dt.minus({ [unit]: count });
}

export function getRangeUpfront(range, date = utcNow(), timeZone = null) {
  const dt = typeof date === "string" ? utcDate(date, false, timeZone) : date;
  const { unit, count } = unitCountFromRange(range);
  return dt.plus({ [unit]: count });
}

export function dateRangeFilterDaysAgo(daysAgo) {
  return {
    //before: new Date().toISOString(),
    after: getRangeAgo(daysAgo),
  };
}

export function daysAgo(datetime) {
  const dt = DateTime.fromISO(datetime);

  const now = utcNow();
  const diff = now.diff(dt, ["days"]);
  return Math.floor(diff.days);
}

export function sortByDateTime(left, right, order = "asc") {
  const a = DateTime.fromISO(left);
  const b = DateTime.fromISO(right);

  if (order === "desc") {
    return b - a;
  }
  return a - b;
}

export function utcNow() {
  return DateTime.utc();
}

export function utcDate(date, endOfTheDay = false, timeZone = null) {
  const dt = convertToLuxonDT(date, timeZone);
  const dtExact = endOfTheDay ? dt.endOf("day") : dt.startOf("day");
  return dtExact.toUTC();
}

function unitCountFromRange(range) {
  let unit = "days";
  if (typeof range === "string") {
    switch (range.slice(-1)) {
      case "y":
        unit = "years";
        break;
      case "m":
        unit = "months";
        break;
      case "w":
        unit = "weeks";
        break;
      case "d":
      default:
        unit = "days";
    }
  }

  const count = parseInt(range);
  return { unit, count };
}

export function lastDayOfMonth(monthOrMonthsBefore, timeZone) {
  // start with now in specified time zone
  const now = DateTime.utc().setZone(timeZone);

  const month = monthOrMonthsBefore
    ? monthOrMonthsBefore < 0
      ? now.month + monthOrMonthsBefore
      : monthOrMonthsBefore
    : now.month;

  // shift to the 1st day of the specified month
  const dt = DateTime.fromObject(
    {
      year: now.year,
      month,
      day: 1,
    },
    { zone: timeZone }
  );
  // return the start of the day of the last day of the month in UTC
  return dt.toUTC().endOf("month").startOf("day");
}

export function yesterday(timeZone) {
  const zone = IANAZone.create(timeZone);
  return utcNow().plus({ days: -1 }).setZone(zone);
}

export function yesterdayStartedAt(timeZone) {
  const day = yesterday(timeZone);
  return day.startOf("day").toUTC();
}

export function yesterdayEndedAt(timeZone) {
  const day = yesterday(timeZone);
  return day.endOf("day").toUTC();
}

export function yesterdayDOW(timeZone) {
  const day = yesterday(timeZone);
  return day.weekdayLong;
}

export function daysAfterNow(days, timeZone) {
  const zone = IANAZone.create(timeZone);
  return utcNow().plus({ days }).setZone(zone).toISO();
}

export function isTimeAfterNow(datetime, timeZone = "Etc/UTC") {
  const dt = DateTime.fromISO(datetime, { zone: timeZone });
  const now = DateTime.utc().setZone(timeZone);
  return dt > now;
}

export function nowTimestamp() {
  return DateTime.now().toFormat("yyyy-LL-dd_HH-mm");
}

export function timeZoneOffset(tz) {
  return IANAZone.create(tz).offset(Date.now());
}
