<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import { formatDateTime } from "@tod-ui/helpers/datetime";

export default {
  name: "Chart",
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  mounted() {
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const readableDate = formatDateTime(tooltipItem.xLabel);
            if (tooltipItem.datasetIndex === 0) {
              return `Temp: ${tooltipItem.yLabel}°F`;
            } else {
              return `Humidity: ${tooltipItem.yLabel}%`;
            }
          },
          title: function (tooltipItem, data) {
            return formatDateTime(tooltipItem[0].label);
          },
        },
      },
      title: {
        display: this.title ? true : false,
        text: this.title,
      },
      scales: {
        xAxes: [
          {
            display: true,
            distribution: "linear",
            type: "time",
            time: {
              unit: "hour",
              displayFormats: {
                hour: "ddd hh:mm a",
              },
            },
          },
        ],
      },
    };

    this.renderChart(this.chartData, options);
  },
};
</script>
