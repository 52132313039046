<template>
  <LayoutPage title="Notifications">
    <AddTaskDialog v-model="isOpen" />
    <v-tabs v-model="tab">
      <v-tab
        v-for="user in notificationUsers"
        :key="user.id"
        :title="`Username: ${user.username}; Total unread: ${
          totalUnreadUserNotifications[user.id]
        }`"
      >
        {{ user.firstName }} {{ user.lastName }}
        <span v-if="currentUserId == user.id" class="pl-2">(me)</span>
        <span v-if="!loading"
          >:
          {{
            currentUserId == user.id
              ? currentUserUnreadTotal
              : totalUnreadUserNotifications[user.id]
          }}</span
        >
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="user in notificationUsers" :key="user.id">
        <v-data-table
          :headers="[
            {
              text: 'Notification',
              value: 'summary',
              width: '50%',
            },
            {
              text: 'Created',
              value: 'insertedAt',
              class: 'text-no-wrap',
            },
            {
              text: 'Actions',
              value: 'actions',
              sortable: false,
              filterable: false,
            },
          ]"
          :items="
            user.id === currentUserId
              ? notifications
              : userNotifications[user.id]
          "
          :search="search"
          :sort-by="['insertedAt']"
          :sort-desc="[true]"
          :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
          :loading="loading"
        >
          <template #body="{ items }">
            <tbody v-if="loading">
              <tr>
                <td colspan="3" class="text-center grey--text text--darken-2">
                  Loading user notifications
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="items.length === 0">
              <tr>
                <td colspan="3" class="text-center grey--text text--darken-2">
                  {{
                    user.id === currentUserId
                      ? "You are"
                      : `${user.firstName} is`
                  }}
                  all caught up! No notifications found.
                </td>
              </tr>
            </tbody>
            <tbody is="transition-group" v-else name="list">
              <tr
                v-for="item in items"
                :key="item.id"
                class="item-row"
                :class="`notification-${item.id}`"
                @click="toggleNotification(item)"
              >
                <td>
                  <div class="notification-title">
                    <v-icon left small :color="severityColor(item.severity)">
                      mdi-circle
                    </v-icon>
                    <span :class="item.status">
                      {{ item.title }}
                    </span>
                  </div>
                  <div
                    v-if="selectedNotification === item.id"
                    class="notification-text"
                  >
                    {{ item.body }}
                  </div>
                </td>

                <td class="action-cell">
                  <DialogNotificationHistory
                    :notification-id="item.id"
                    class="mr-2"
                  />
                  <span :class="item.status">
                    {{
                      facilityFormatDateTime4Humans(
                        item.insertedAt,
                        false,
                        false
                      )
                    }}
                  </span>
                </td>

                <td class="action-cell d-flex">
                  <NotificationActionButtons
                    :notification="item"
                    :is-for-current-user="user.id === currentUserId"
                    @redirect="redirect"
                    @dismiss="dismiss"
                  />
                  <ButtonAdd
                    class="ml-8"
                    title="Add Task"
                    @click="openDialog"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import NotificationActionButtons from "./NotificationActionButtons.vue";
import ButtonAdd from "@atoms/ButtonAdd.vue";
import DialogNotificationHistory from "./DialogNotificationHistory.vue";

import { severityColor, templateLabel, viewNotification } from "./helper";
import { titleCase } from "@tod-ui/helpers/strings";
import useFacility from "@components/authentication/useFacility";
import useUser from "@components/authentication/useUser";
import useNotifications from "./useNotifications";
import { ref, computed } from "vue";
import { useRoute } from "vue-router/composables";
import AddTaskDialog from "@components/authentication/call-center-dashboard/AddTaskDialog.vue";

export default {
  name: "PageNotifications",
  components: {
    LayoutPage,
    NotificationActionButtons,
    DialogNotificationHistory,
    ButtonAdd,
    AddTaskDialog,
  },
  setup() {
    const { facilityFormatDateTime4Humans, facilityUsers, fetchFacilityUsers } =
      useFacility();
    const {
      notifications,
      unreadNotifications,
      userNotifications,
      totalUnreadUserNotifications,
      loading,
      fetchNotifications,
      markNotificationRead,
      redirectAndMarkAsRead,
      dismissNotification,
    } = useNotifications();

    const currentUserUnreadTotal = computed(
      () => unreadNotifications.value.length
    );
    const { currentUser } = useUser();
    const currentUserId = computed(() => currentUser.value?.id);
    const notificationUsers = computed(() => [
      currentUser.value,
      ...facilityUsers.value.filter((u) => u.id !== currentUserId.value),
    ]);

    const $route = useRoute();

    const tab = ref();
    const search = ref("");
    const selectedNotification = ref($route?.params?.id);

    return {
      facilityFormatDateTime4Humans,
      notifications,
      currentUserUnreadTotal,
      userNotifications,
      totalUnreadUserNotifications,
      loading,
      tab,
      search,
      notificationUsers,
      currentUserId,
      selectedNotification,
      fetchNotifications,
      markNotificationRead,
      fetchFacilityUsers,
      redirectAndMarkAsRead,
      dismissNotification,
    };
  },
  data() {
    return {
      isOpen: false, // Ensure isOpen is initialized
      // Other data properties
    };
  },
  watch: {
    notificationUsers: {
      handler(users) {
        users.forEach((u) => {
          if (u.id !== this.currentUserId)
            this.fetchNotifications(this.$apollo, u.id);
        });
      },
      immediate: true,
    },
    selectedNotification(notificationId) {
      if (notificationId) {
        this.$vuetify.goTo(`.notification-${notificationId}`, {
          offset: 60,
        });
      }
    },
  },
  mounted() {
    this.fetchFacilityUsers(this.$apollo);
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    async toggleNotification(notification) {
      if (notification.id === this.selectedNotification) {
        this.selectedNotification = null;
        this.$router.replace({ name: "Notifications" });
      } else {
        this.selectedNotification = notification.id;
        this.markNotificationRead(this.$apollo, notification);
        this.$router.replace(viewNotification(notification));
      }
    },
    async redirect(notification) {
      await this.redirectAndMarkAsRead(this.$apollo, notification);
    },
    async dismiss(notification) {
      await this.dismissNotification(this.$apollo, notification);
    },
    severityColor,
    templateLabel,
    titleCase,
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

.list-move {
  transition: transform 0.5s;
}

.item-row {
  display: table-row;
}

.notification-link {
  text-decoration: none;
  color: currentColor;
}

.published {
  font-weight: bold;
}

.action-cell {
  vertical-align: top;
  padding-top: 10px !important;
}

.notification-title {
  line-height: 48px;
  cursor: pointer;
}

.notification-text {
  min-height: 48px;
  padding-left: 2em;
  color: gray;
}
</style>
