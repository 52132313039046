<template>
  <LayoutPage title="Lifetime / Missed Revenue Report">
    <div class="container">
      <v-select
        v-model="selectedRegion"
        :items="['Grace Combs']"
        label="Select Region"
      />
      <v-select
        v-model="selectedFacility"
        :items="['Facility A']"
        label="Select Facility"
      />
      <v-select v-model="selectedYear" label="Year" :items="['2024', '2023']" />
      <v-select
        v-model="selectedMonth"
        label="Month"
        :items="['January', 'February', 'March', 'April', 'May', 'June']"
      />
    </div>
    <div class="table-container">
      <v-data-table
        :headers="lifetimeRevenueAddsTable.headers"
        :items="lifetimeRevenueAddsTable.body"
        :items-per-page="5"
        class="elevation-1"
      >
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.facilityName">
              <template v-for="(value, key) in item">
                <CustomTableCell
                  :key="key"
                  :value="value"
                  :is-highlighted="isHighlighted(key)"
                  :unit="getUnit(key)"
                />
              </template>
            </tr>
            <tr class="total">
              <td>Total</td>
              <td :class="{ highlighted: isHighlighted('subscriptions') }">
                45
              </td>
              <td :class="{ highlighted: isHighlighted('qwe') }">82</td>
              <td :class="{ highlighted: isHighlighted('qwe') }">127</td>
              <td>12</td>
              <td
                :class="{ highlighted: isHighlighted('lifetimeRevenueAdds') }"
              >
                $6,480
              </td>
              <td
                :class="{
                  highlighted: isHighlighted('missedPotentialRevenue'),
                }"
              >
                $11,808
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CustomTableCell from "./CustomTableCell.vue";
import useUser from "../useUser";
import { facilityData } from "./data";
import useDashboard from "./useDashboard";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";

export default {
  name: "ConversionTable",
  components: {
    LayoutPage,
    CustomTableCell,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const {
      fetchOrganizationDescendants,
      selectedFacilityIds,
      filteredFacilities,
      dashboardData,
      lifetimeRevenueAddsTable,
    } = useDashboard();

    const route = useRoute();

    const highlightColumn = computed(() => {
      return route.params.highlightColumn;
    });

    const isHighlighted = (column) => {
      if (highlightColumn.value === "lifetimeadds") {
        return ["lifetimeRevenueAdds"].includes(column);
      } else if (highlightColumn.value === "missed") {
        return ["missedPotentialRevenue"].includes(column);
      }
      return column === highlightColumn.value;
    };

    const getColor = (column, value) => {
      return "rgba(10,1,131,0.8)";
    };

    const getUnit = (column) => {
      const columnsWithUnit = ["lifetimeRevenueAdds", "missedPotentialRevenue"];
      return columnsWithUnit.includes(column) ? "$" : "";
    };

    return {
      fetchOrganizationDescendants,
      selectedFacilityIds,
      filteredFacilities,
      defaultOrganization,
      dashboardData,
      isHighlighted,
      getColor,
      highlightColumn,
      lifetimeRevenueAddsTable,
      getUnit,
    };
  },

  data() {
    return {
      selectedYear: "2024",
      selectedMonth: "June",
      trends: [
        { name: "By Trends", value: "byTrends" },
        { name: "By Months", value: "byMonths" },
      ],
      period: [
        { name: "Last 12 Months", value: "last12Months" },
        { name: "Custom Period", value: "customPeriod" },
      ],
      selectedRegion: "Grace Combs",
      selectedFacility: "Facility A",
    };
  },
  watch: {
    filteredFacilities(newFacilities) {
      this.selectedFacilityIds = newFacilities.map((facility) => facility.id);
    },
    selectedFacilityIds(facilityIds) {
      this.dashboardData = facilityData.filter((facility) =>
        facilityIds.includes(facility.id)
      );
    },
  },
  beforeMount() {
    this.fetchOrganizationDescendants(
      this.$apollo,
      this.defaultOrganization.id,
      ["region", "facility"]
    );
    this.selectedFacilityIds = this.filteredFacilities.map(
      (facility) => facility.id
    );
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-container td {
  min-width: 150px;
}

.container {
  display: flex;
  gap: 15px;
}

.total td {
  font-weight: bold;
}

.negative-value {
  color: red;
}

.positive-value {
  color: green;
}
.highlighted {
  background-color: rgba(14, 59, 110, 0.2); /* Highlight color */
}
</style>
