export const debounce = function debounce(func, waitMS = 500) {
  let timeoutId = null;

  function debounced(...args) {
    clearTimeout(timeoutId);
    return new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => {
        resolve(func.apply(this, args));
      }, waitMS);
    });
  }
  function cancel() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }
  debounced.cancel = cancel;

  return debounced;
};

// Use the same logic as in vuetify v-data-table
// https://github.com/vuetifyjs/vuetify/blob/f50a8083884917b1be271098483fc86162ff7270/packages/vuetify/src/util/helpers.ts#L123
export function getObjectValueByPath(obj, path, fallback = "") {
  if (obj == null || !path || typeof path !== "string") return fallback;
  if (obj[path] !== undefined) return obj[path];
  return getNestedValue(obj, normalizePath(path), fallback);
}
function normalizePath(path) {
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  return path.split(".");
}
function getNestedValue(obj, path, fallback) {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function setObjectValueByPath(obj, path, value) {
  path = normalizePath(path);
  const last = path.length - 1;
  const clone = JSON.parse(JSON.stringify(obj));

  let objRef = clone;
  for (let i = 0; i < last; i++) {
    const key = path[i];
    if (!objRef[key]) objRef[key] = {};
    objRef = objRef[key];
  }

  objRef[path[last]] = value;
  return clone;
}
