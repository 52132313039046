<template>
  <CardLayout title="Alert Recipients" :loading="loading">
    <v-data-table
      :headers="[
        { text: 'Contact Person', value: 'name' },
        { text: 'Escalation Level', value: 'escalationLevel' },
        { text: 'Incident Types', value: 'incidentTypes' },
      ]"
      :items="responders"
      sort-by="escalationLevel"
      disable-pagination
      hide-default-footer
      no-data-text="No recipients are assigned"
    >
      <template #item.name="{ item }">
        <div>{{ item.user.firstName }} {{ item.user.lastName }}</div>
        <small>
          <v-icon x-small class="pr-1">
            {{ item.contactMethodIcon }}
          </v-icon>
          <strong>{{ item.contactMethodType }}</strong> to
          {{ item.contactMethodValue }}
        </small>
      </template>

      <template #item.escalationLevel="{ item }">
        <div>
          {{ /*item.escalationLevel*/ item.escalationType }}
          {{ titleCase(item.type) }}
        </div>
        <small v-if="item.accessHours">
          <v-icon x-small>
            {{ item.accessHoursIcon }}
          </v-icon>
          {{ item.accessHours }}
        </small>
      </template>
    </v-data-table>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import { ZONE_RESPONDERS } from "./graphql";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "CardZoneResponders",
  components: { CardLayout },
  setup() {
    return {
      formatPhoneNumber,
      titleCase,
    };
  },
  data: () => ({
    responders: [],
  }),
  computed: {
    loading() {
      if (!this.responders) return true;
      return this.$apollo.queries.responders.loading;
    },
  },
  apollo: {
    responders: {
      query: ZONE_RESPONDERS,
      variables() {
        return {
          id: this.$route.params.smart_zone_id,
          includeAncestors: true,
        };
      },
      update({ organizationResponders }) {
        return organizationResponders.map((responder) => {
          let contactMethodType = titleCase(responder?.contactMethod?.type);
          let contactMethodIcon = undefined;
          let contactMethodValue = responder?.contactMethod?.value;
          switch (responder?.contactMethod?.type) {
            case "phone":
              contactMethodType = "Voice call";
              contactMethodIcon = "fas fa-phone-volume";
              contactMethodValue = formatPhoneNumber(contactMethodValue);
              break;
            case "mobile":
              contactMethodType = "Text message";
              contactMethodIcon = "fas fa-mobile-alt";
              contactMethodValue = formatPhoneNumber(contactMethodValue);
              break;
            case "email":
              contactMethodIcon = "far fa-envelope";
              break;
          }

          let accessHours = null;
          let accessHoursIcon = undefined;
          switch (responder.accessHours) {
            case "not_during_access_hours":
              accessHours = "After hours";
              accessHoursIcon = "fas fa-moon";
              break;
            case "during_access_hours":
              accessHours = "During access hours";
              accessHoursIcon = "fas fa-sun";
              break;
          }
          const escalationType =
            responder.escalationLevel % 2 === 1 ? "Primary" : "Secondary"; // Odd levels 1,3,5 - primary; even 2,4,6 - secondary
          return {
            ...responder,
            contactMethodType,
            contactMethodIcon,
            contactMethodValue,
            accessHours,
            accessHoursIcon,
            escalationType,
            incidentTypes: responder.incidentTypes?.map(titleCase)?.join(", "),
          };
        });
      },
    },
  },
};
</script>
