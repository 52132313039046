<template>
  <v-container
    :style="{
      position: 'sticky',
      top: '65px',
      zIndex: 100,
      backgroundColor: $vuetify.theme.dark ? '' : 'white',
    }"
  >
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="selectedRegions"
          :items="regions"
          label="Select Regions"
          outlined
          item-text="name"
          item-value="id"
          multiple
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedRegions.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedFacilities"
          :items="facilities"
          label="Select Facilities"
          multiple
          outlined
          clearable
          item-text="name"
          item-value="id"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.name }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ facilities.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3" />
      <v-col cols="3">
        <v-select
          v-model="dashboard"
          :items="dashboards"
          label="Select Dashboard"
          outlined
          item-text="name"
          item-value="id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  setup() {
    return {};
  },
  data() {
    return {
      selectedRegions: ["Grace Combs"],
      regions: ["Grace Combs", "Region 2", "Region 3"],
      dashboards: [
        { id: 1, name: "Management Summary" },
        { id: 2, name: "Monitoring & Control Hub" },
        { id: 3, name: "Operations Summary" },
      ],
      dashboard: 3,
      selectedFacilities: [
        { id: 1, name: "Facility 1" },
        { id: 2, name: "Facility 2" },
        { id: 3, name: "Facility 3" },
      ],
      facilities: [
        { id: 1, name: "Facility 1" },
        { id: 2, name: "Facility 2" },
        { id: 3, name: "Facility 3" },
      ],
    };
  },
  watch: {
    dashboard(newDashboard) {
      if (newDashboard === 1) {
        this.$router.push({ path: "/demo/management" });
      }
      if (newDashboard === 2) {
        this.$router.push({ path: "/demo/call-center" });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
