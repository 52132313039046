<template>
  <CardLayout title="Incident Information" :loading="loading">
    <dl
      v-if="incident"
      class="property-list my-4"
      :class="{ 'two-columns': !singleColumn }"
    >
      <dt>Renter Name</dt>
      <dd>
        <UserIcon
          v-if="incident.hasDisabledLienResponder"
          left
          :responder="incident.responder"
        />
        {{ incident.responder.name }}
      </dd>
      <dt>Smart Unit</dt>
      <dd>{{ incident.organization.name }}</dd>

      <dt>Incident Type</dt>
      <dd>
        <IconIncidentType :incident="incident" />
        {{ incident.typeName }}
      </dd>
      <dt>Incident Status</dt>
      <dd>
        <IconIncidentStatus small :incident="incident" />
        {{ incident.statusName }}
      </dd>

      <dt>Start Time</dt>
      <dd>{{ incident.startedAt }}</dd>
      <dt>Duration</dt>
      <dd>{{ incident.duration }}</dd>
      <dt>Incident Disposition</dt>
      <dd><v-select v-model="selectedItem" dense :items="['Closed']" /></dd>
      <dt>Assignee</dt>
      <dd>
        <v-select
          v-model="selectedAssignee"
          dense
          :items="['Helen(Facility Manager)', 'John (Facility Admin)']"
        />
      </dd>
    </dl>
    <div class="incident">
      <p>Incident Disposition History</p>
      <div class="event">
        <p>Open:</p>
        <p class="timestamp">Tue, Apr 23 2024, 2:50 p.m</p>
        <div class="delta-time">
          <v-icon>mdi-delta</v-icon>
          <p>24Hours</p>
        </div>
        <p>In Progress:</p>
        <p class="timestamp">Tue, Apr 24 2024, 2:50 p.m.</p>
        <div class="delta-time">
          <v-icon>mdi-delta</v-icon>
          <p>1Hour</p>
        </div>
        <p>In Review:</p>
        <p class="timestamp">Tue, Apr 24 2024, 3:50 p.m.</p>
        <div class="delta-time">
          <v-icon>mdi-delta</v-icon>
          <p>1Hour</p>
        </div>
        <p>Closed:</p>
        <p class="timestamp">Tue, Apr 24 2024, 4:50 p.m.</p>
      </div>
    </div>

    <template v-if="!loading && !incident.needsSurvey" #actions>
      <v-btn color="grey lighten-2" @click="$router.go(-1)"> Done </v-btn>
    </template>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import UserIcon from "../renters/UserIcon.vue";
import useIncident from "./useIncident";

export default {
  name: "CardIncidentInfo",
  components: { CardLayout, IconIncidentStatus, IconIncidentType, UserIcon },
  props: {
    singleColumn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { incident, loading } = useIncident();
    return { incident, loading };
  },
  data() {
    return {
      selectedItem: "Closed",
      selectedAssignee: "Helen(Facility Manager)",
    };
  },
};
</script>

<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}

.event {
  display: flex;
  gap: 1rem;
}
.timestamp {
  font-weight: bold;
}

.delta-time {
  margin-top: 2rem;
}
</style>
