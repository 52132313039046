<template>
  <LayoutPage title="Facility Reports">
    <v-tabs v-model="tab">
      <v-tab v-if="!isFacilityManager" key="summaries">
        {{ organizationSummaryTitle }}
      </v-tab>
      <v-tab v-if="!isFacilityManager" key="monthly_subscriptions">
        {{ organizationMontlySubscriptionTitle }}
      </v-tab>
      <v-tab key="summary"> {{ facilitySummaryTitle }} </v-tab>
      <v-tab key="incidents"> Incidents </v-tab>
      <v-tab v-if="facilityFeatureEnabled('smart_zones')" key="zones">
        Smart Zones
      </v-tab>
      <v-tab key="units"> Smart Units </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-if="!isFacilityManager" key="summaries">
        <OrganizationFacilitySummaries />
      </v-tab-item>
      <v-tab-item v-if="!isFacilityManager" key="monthly_subscriptions">
        <OrganizationFacilitySubscriptions />
      </v-tab-item>
      <v-tab-item key="summary">
        <FacilityManagementReport />
      </v-tab-item>
      <v-tab-item key="incidents">
        <v-expansion-panels v-model="accordion" accordion>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              {{ open ? "" : "Incident History" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <IncidentHistory />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              {{ open ? "" : "Incident Surveys" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <IncidentSurveys />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>

      <v-tab-item v-if="facilityFeatureEnabled('smart_zones')" key="zones">
        <v-expansion-panels v-model="accordion" accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Zone Traffic Last Week
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ZoneTrafficReport />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Zone Incidents Report
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ZoneIncidentReport />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Zone Climate Report
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ZoneEnvironmentReport />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>

      <v-tab-item key="units">
        <v-expansion-panels v-model="accordion" accordion>
          <v-expansion-panel>
            <v-expansion-panel-header> Visit Trends </v-expansion-panel-header>
            <v-expansion-panel-content>
              <WeeklyAverageIncidentReport />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Visit Trends Per Day
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DailyIncidentReport />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Visits Per Week
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <WeeklyIncidentReport />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import FacilityManagementReport from "./FacilityManagementReport.vue";
import IncidentHistory from "./IncidentHistory.vue";
import IncidentSurveys from "./IncidentSurveys.vue";
import WeeklyIncidentReport from "./WeeklyIncidentReport.vue";
import DailyIncidentReport from "./DailyIncidentReport.vue";
import ZoneTrafficReport from "./ZoneTrafficReport.vue";
import WeeklyAverageIncidentReport from "./WeeklyAverageIncidentReport.vue";
import ZoneEnvironmentReport from "./ZoneEnvironmentReport.vue";
import ZoneIncidentReport from "./ZoneIncidentReport.vue";
import OrganizationFacilitySummaries from "./OrganizationFacilitySummaries.vue";
import OrganizationFacilitySubscriptions from "./OrganizationFacilitySubscriptions.vue";

import useFacility from "../authentication/useFacility";
import useUser from "../authentication/useUser";
import useRouteQuery from "@components/common/useRouteQuery";
import { computed, watch } from "vue";

export default {
  name: "PageReports",
  components: {
    LayoutPage,
    FacilityManagementReport,
    IncidentHistory,
    IncidentSurveys,
    WeeklyIncidentReport,
    DailyIncidentReport,
    ZoneTrafficReport,
    WeeklyAverageIncidentReport,
    ZoneEnvironmentReport,
    ZoneIncidentReport,
    OrganizationFacilitySummaries,
    OrganizationFacilitySubscriptions,
  },
  setup() {
    const { currentFacility, facilityFeatureEnabled } = useFacility();
    const { isFacilityManager, defaultOrganization } = useUser();
    const facilitySummaryTitle = computed(
      () =>
        `${
          !isFacilityManager.value ? currentFacility.value?.name + " " : ""
        }Facility Summary`
    );
    const organizationSummaryTitle = computed(
      () => `${defaultOrganization.value.name} Summary`
    );

    const organizationMontlySubscriptionTitle = computed(
      () => `${defaultOrganization.value.name} Subscriptions`
    );

    const { tab, accordion } = useRouteQuery();

    //reset accordion value on tab switch
    watch(tab, (_value) => {
      accordion.value = undefined;
    });

    return {
      facilityFeatureEnabled,
      isFacilityManager,
      facilitySummaryTitle,
      organizationSummaryTitle,
      organizationMontlySubscriptionTitle,
      tab,
      accordion,
    };
  },
};
</script>
