<template>
  <LayoutPage :title="pageTitle" :loading="devicesLoading">
    <form @submit.prevent="addSmartUnit">
      <v-row>
        <v-col lg="6">
          <CardLayout title="Smart Unit Details">
            <v-alert
              v-model="unitExistsAlert"
              dark
              color="indigo darken-3"
              transition="scale-transition"
            >
              <h3>Smart Unit "{{ smartUnit.name }}" already exists</h3>

              <p class="subtitle-1">Would you like to...</p>

              <ul style="list-style: none; padding: 0px">
                <li v-if="renterId">
                  <v-btn text @click="selectSmartUnit">
                    <v-icon left small>fas fa-chevron-circle-right</v-icon>
                    Select Smart Unit "{{ smartUnit.name }}" to Assign to
                    {{ renterName }}
                  </v-btn>
                </li>
                <li v-else>
                  <v-btn text @click="selectSmartUnit">
                    <v-icon left small>fas fa-chevron-circle-right</v-icon>
                    Select Smart Unit "{{ smartUnit.name }}"
                  </v-btn>
                </li>
                <li>
                  <v-btn text @click="useDifferentName">
                    <v-icon left small>fas fa-chevron-circle-right</v-icon>
                    Try a Different Unit #
                  </v-btn>
                </li>
              </ul>
            </v-alert>

            <v-autocomplete
              ref="unitName"
              v-model="smartUnit"
              :disabled="unitExistsAlert"
              :items="existingUnits"
              :loading="existingUnitsLoading"
              :search-input.sync="search"
              auto-select-first
              clearable
              clear-icon="far fa-times-circle"
              hide-no-data
              :item-disabled="(item) => item.nameTooShort"
              item-text="name"
              item-value="id"
              label="Unit #"
              no-filter
              return-object
              @change="unitSelected"
              @click:clear="onClearClicked"
            >
              <template #item="{ item, on, attrs }">
                <!-- Existing, assigned unit -->
                <template v-if="item.assigned">
                  <v-list-item
                    :to="{
                      name: 'ManageSmartUnitDevices',
                      params: { zone_id: item.id },
                    }"
                    v-bind="attrs"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text caption">
                        {{ assignedDevices(item) }}
                        <span v-if="item.renter" class="ml-2">
                          <strong>
                            {{ item.renter.firstName }}
                            {{ item.renter.lastName }}
                          </strong>
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1" small>
                        fas fa-user-shield
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>

                <!-- Existing, unassigned unit -->
                <template v-else-if="item.id">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption">
                        {{ assignedDevices(item) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon title="Existing Smart Unit">
                      <v-icon color="" small> fas fa-check-circle </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>

                <!-- Nonexistent unit, ok to create -->
                <template v-else>
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.nameTooShort"
                        class="caption font-italic"
                      >
                        Smart Unit # must be at least
                        {{ unitNameMinLength }} characters
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else class="caption font-italic">
                        Click to continue creating Smart Unit {{ item.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon title="Click to Create Smart Unit">
                      <v-icon
                        :color="item.nameTooShort ? 'grey' : 'success'"
                        small
                      >
                        fas fa-plus-circle
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </template>
            </v-autocomplete>

            <SelectUnitType
              ref="unitType"
              v-model="smartUnit.unitTypeId"
              :disabled="
                !smartUnit.name || unitExistsAlert || existingUnitSelected
              "
            />
          </CardLayout>
        </v-col>

        <v-col lg="6">
          <CardLayout
            v-model="deviceFilter"
            title="Select Devices"
            :loading="devicesLoading"
            :disabled="!smartUnit.name"
            header-type="search"
          >
            <template #load>
              <em>Loading available devices</em>
            </template>

            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th>Assign Device #</th>
                    <th>Device Health</th>
                    <th>Last Motion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="device in availableDevices" :key="device.id">
                    <td style="width: 40%">
                      <v-checkbox
                        v-model="selectedDeviceIds"
                        dense
                        :disabled="device.status !== 'ok'"
                        :label="device.name"
                        :value="device.id"
                      />
                    </td>
                    <td style="width: 30%; white-space: nowrap">
                      <DeviceGaugeCluster :device="device" />
                    </td>
                    <td style="width: 30%; white-space: nowrap">
                      {{ device.lastMotion }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <template #actions>
              <v-btn outlined @click="cancel">Cancel</v-btn>

              <template v-if="existingUnitSelected == false">
                <v-btn :disabled="!allowCreation" color="success" type="submit">
                  Create Smart Unit
                </v-btn>
              </template>
              <template v-else>
                <v-btn :disabled="!allowCreation" color="success" type="submit">
                  Update Existing Smart Unit
                </v-btn>
              </template>
            </template>
          </CardLayout>
        </v-col>
      </v-row>
    </form>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CardLayout from "@layout/CardLayout.vue";

import { userDisplayName } from "../renters/helper";

import { assignZoneToRenter } from "../renters/renter";
import { addSmartUnit } from "../../interactors/smartUnit";

import { findUser, userBillingAccounts } from "../../graphql/users";

import { addOrganizationDevices } from "../../graphql/organizations";

import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import SelectUnitType from "../common/SelectUnitType.vue";
import { deviceLastMotion } from "../common/helpers/devices";
import useFacility from "../authentication/useFacility";
import useInventory from "./useInventory";
import { computed, onUnmounted, ref, unref, watch } from "vue";
import { stringSearch } from "@tod-ui/helpers/strings";
import useDevice from "./useDevice";
import useSmartUnits from "./useSmartUnits";
import usePMS from "../common/usePMS";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "PageSmartUnitAdd",
  components: {
    DeviceGaugeCluster,
    SelectUnitType,
    LayoutPage,
    CardLayout,
  },
  setup() {
    const unitNameMinLength = 2;
    const { currentFacilityId } = useFacility();
    const { hasPMS } = usePMS();
    const { searchExistingUnits } = useSmartUnits();

    const {
      healthyFacilityDevices,
      devicesLoading,
      fetchFacilityDevices,
      refetchInventory,
      cleanUpInventoryRefs,
    } = useInventory();

    const {
      device: transferringDevice,
      deviceName,
      fetchDevice,
    } = useDevice({}, currentFacilityId);

    const smartUnit = ref({});
    const selectedDeviceIds = ref([]);
    const deviceFilter = ref("");

    const availableDevices = computed(() => {
      const fetchedDevice = transferringDevice.value?.id
        ? {
            ...transferringDevice.value,
            name: deviceName.value,
            lastMotion: deviceLastMotion(transferringDevice.value),
          }
        : null;

      const facilityDevices = unref(healthyFacilityDevices);

      // Devices already assigned to the selected unit
      const smartUnitDevices = smartUnit.value?.devices?.length
        ? smartUnit.value.devices
        : null;

      const devices = smartUnitDevices
        ? [...smartUnitDevices, ...facilityDevices]
        : fetchedDevice
        ? [
            fetchedDevice,
            ...facilityDevices.filter((d) => d.id !== fetchedDevice.id),
          ]
        : facilityDevices;

      const deviceSearch = stringSearch(deviceFilter.value);
      return deviceFilter.value
        ? devices?.filter((device) => deviceSearch.includedIn(device.name))
        : devices;
    });

    // Pre-select devices already assigned to the selected unit
    watch(smartUnit, (unit) => {
      if (unit?.devices?.length) {
        selectedDeviceIds.value = [
          ...selectedDeviceIds.value,
          ...unit.devices.map((d) => d.id),
        ];
      }
    });

    // Pre-select transferring device
    watch(transferringDevice, (device) => {
      if (device?.id) {
        selectedDeviceIds.value = [...selectedDeviceIds.value, device.id];
      }
    });

    onUnmounted(cleanUpInventoryRefs);
    const { addAlert } = useAlerts();
    return {
      currentFacilityId,
      hasPMS,
      devicesLoading,
      fetchFacilityDevices,
      refetchInventory,
      fetchDevice,
      searchExistingUnits,
      smartUnit,
      selectedDeviceIds,
      deviceFilter,
      availableDevices,
      unitNameMinLength,
      addAlert,
    };
  },
  data: () => ({
    existingUnits: [],
    search: null,
    unitExistsAlert: false,
    user: null,
    billingAccount: null,
    existingUnitSelected: false,
    focusNameInput: true,
  }),
  computed: {
    allowCreation() {
      if (!this.smartUnit.name) {
        return false;
      }
      if (this.selectedDeviceIds.length < 1) {
        return false;
      }
      if (this.unitExistsAlert) {
        return false;
      }
      return true;
    },
    renterId() {
      return this.$route.params.renter_id;
    },
    renterLoading() {
      return this.$apollo.queries.user.loading;
    },
    renterName() {
      if (this.renterLoading) return "...";
      return userDisplayName(this.user);
    },
    pageTitle() {
      return this.renterId
        ? ["Renters", this.renterName, "Create Smart Unit"]
        : ["Smart Units", "Inventory", "Create Smart Unit"];
    },
    existingUnitsLoading() {
      return this.$apollo.queries.organizationDescendants?.loading;
    },
  },
  apollo: {
    user: {
      query: findUser,
      variables() {
        return { id: this.$route.params.renter_id };
      },
      skip() {
        // Only run query if there's a renter_id
        return !this.$route.params.renter_id;
      },
    },
    billingAccount: {
      query: userBillingAccounts,
      variables() {
        return {
          userId: this.$route.params.renter_id,
          parentOrganizationId: this.currentFacilityId,
        };
      },
      update({ userBillingAccounts }) {
        return userBillingAccounts.find(
          (account) => account.billingAccount.type == "consumer"
        );
      },
      skip() {
        // Only run query if there's a renter_id
        return !this.renterId;
      },
    },
  },
  watch: {
    async search(searchText) {
      // Search is nothing, or items have already been requested
      if (!searchText) {
        this.existingUnits = [];
        return;
      }

      try {
        // Reduce and transform the data
        const units = await this.searchExistingUnits(this.$apollo, searchText);

        // If there's not an exact match, append a "new" unit
        const matchSearchText = stringSearch(searchText);
        const exactMatch = units.find((unit) =>
          matchSearchText.exactMatch(unit.name)
        );

        if (!exactMatch) {
          this.existingUnits = [
            {
              name: searchText.trim(),
              type: "zone",
              assigned: false,
              devices: [],
              unitTypeId: null,
              nameTooShort: searchText.trim().length < this.unitNameMinLength,
            },
            ...units,
          ];
        } else {
          this.existingUnits = units;
        }
      } catch (errorMessage) {
        this.addAlert({
          message: errorMessage,
        });
      }
    },
  },
  created() {
    if (this.hasPMS) {
      this.$router.replace({ name: "SmartUnits" });
    }
  },
  beforeMount() {
    const smartUnitName = this.$route.query?.name;
    if (smartUnitName) {
      this.smartUnit = {
        name: smartUnitName,
        type: "zone",
        assigned: false,
        devices: [],
        unitTypeId: null,
      };
      this.existingUnits = [this.smartUnit];
    }
    this.fetchFacilityDevices(this.$apollo);

    const deviceId = this.$route.query?.device;
    if (deviceId) {
      this.fetchDevice(this.$apollo, deviceId);
    }
  },
  mounted() {
    if (this.smartUnit.name) {
      this.$refs.unitType.focus();
    } else {
      this.$refs.unitName.focus();
    }
  },
  methods: {
    selectSmartUnit() {
      this.existingUnitSelected = true;
      this.unitExistsAlert = false;
    },
    async onClearClicked() {
      if (!this.existingUnitSelected) return;
      this.existingUnitSelected = false;
      this.selectedDeviceIds = [];
      await this.refetchInventory();
    },

    // Mutation methods
    async addSmartUnit() {
      if (this.existingUnitSelected) {
        this.updateSmartUnit();
        return;
      }

      try {
        // Create the new zone/unit with the selected devices
        const zone = await addSmartUnit(
          this.$apollo,
          {
            ...this.smartUnit,
            parent: { id: this.currentFacilityId },
          },
          this.selectedDeviceIds
        );

        if (zone && this.user) {
          // Add the zone/unit to the renter and make them a responder
          await assignZoneToRenter(
            this.$apollo,
            zone,
            this.user,
            this.billingAccount
          );

          // let's go back to the renter
          this.$router.go(-1);
        } else {
          // let's go to inventory
          this.$router.push({
            name: "Inventory",
          });
        }
      } catch (error) {
        this.addAlert({ message: "There was an error adding the smart unit" });
      }
    },

    async updateSmartUnit() {
      if (!this.existingUnitSelected) return;

      // Split the IDs into buckets for remove/ignore
      const deviceIds = this.smartUnit.devices.reduce(
        (acc, device) => {
          if (!this.selectedDeviceIds.includes(device.id)) {
            acc.removedIds.push(device.id);
          } else {
            acc.ignoreIds.push(device.id);
          }
          return acc;
        },
        { removedIds: [], ignoreIds: [] }
      );

      // Get device IDs to add to the selected unit, ignoring already assigned
      const addIds = this.selectedDeviceIds.filter(
        (id) => !deviceIds.ignoreIds.includes(id)
      );

      try {
        if (deviceIds.removedIds.length > 0) {
          await this.$apollo.mutate({
            // "add" it back to facility inventory
            mutation: addOrganizationDevices,
            variables: {
              devices: deviceIds.removedIds,
              id: this.currentFacilityId,
            },
          });
        }

        if (addIds.length > 0) {
          await this.$apollo.mutate({
            // Add new devices to smart unit
            mutation: addOrganizationDevices,
            variables: {
              devices: addIds,
              id: this.smartUnit.id,
            },
          });
        }

        if (this.user) {
          // Interactor needs this for refetchQueries
          this.smartUnit.parentId = this.currentFacilityId;
          // Add the zone/unit to the renter and make them a responder
          await assignZoneToRenter(
            this.$apollo,
            this.smartUnit,
            this.user,
            this.billingAccount
          );
          //Go to assigned smart unit drilldown here.
          this.gotoRenter(this.user);
        } else {
          this.gotoSmartUnit(this.smartUnit);
        }
      } catch (error) {
        this.addAlert({ message: "There was an error adding the smart unit" });
      }
    },
    async assignSmartUnit(zone) {
      try {
        if (zone && this.user) {
          // Interactor needs this for refetchQueries
          zone.parentId = this.currentFacilityId;

          // Add the zone/unit to the renter and make them a responder
          await assignZoneToRenter(
            this.$apollo,
            zone,
            this.user,
            this.billingAccount
          );

          // let's go back to the renter
          this.$router.go(-1);
        } else {
          this.addAlert({
            message: "Something went wrong assigning the smart unit",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    cancel() {
      this.selectedDeviceIds = [];
      this.smartUnit.name = "";
      this.smartUnit.unitTypeId = null;

      this.$router.go(-1);
    },

    // Name Collision Option methods
    gotoSmartUnit({ id: zoneId }) {
      this.$router.push({
        name: "ViewSmartUnit",
        params: {
          zone_id: zoneId,
        },
      });
    },

    gotoRenter({ id: renterId }) {
      this.$router.push({
        name: "ViewRenter",
        params: {
          renter_id: renterId,
        },
      });
    },

    useDifferentName() {
      this.smartUnit = {};
      this.unitExistsAlert = false;
      this.$nextTick(() => this.$refs.unitName.focus());
    },

    unitSelected(unit) {
      if (!unit) {
        this.unitExistsAlert = false;
        this.smartUnit = {};
      } else if (unit.id) {
        this.unitExistsAlert = true;
        this.$refs.unitName.blur();
      } else {
        // The "new" unit is selected
        this.unitExistsAlert = false;
        this.$refs.unitType.focus();
      }
    },
    // Helper methods
    assignedDevices({ devices }) {
      if (devices && devices.length > 0) {
        return devices
          .map((device) => device.shortId || device.externalId)
          .sort()
          .join(" | ");
      }
    },
  },
};
</script>
