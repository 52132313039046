<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row dense justify="end">
        <v-col cols="12" xs="12" md="5">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View incidents for..."
            :items="smartZones"
            item-text="name"
            item-value="id"
            single-line
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            v-model="selectedType"
            :disabled="loading"
            placeholder="of type..."
            :items="incidentTypes"
            item-text="name"
            item-value="value"
            single-line
          />
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <ZoneIncidentReportChart
          :chart-data="incidents"
          :max="chartMax"
          :min="chartMin"
        />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import { getDaysAgo } from "@tod-ui/helpers/datetime";
import { titleCase } from "@tod-ui/helpers/strings";

import ZoneIncidentReportChart from "./ZoneIncidentChart.vue";

import { organizationSmartZones } from "../../graphql/organizations";
import { zoneIncidents } from "../../graphql/organizations/zone";
import useFacility from "../authentication/useFacility";

export default {
  name: "ZoneIncidentReport",
  components: {
    ZoneIncidentReportChart,
  },
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    incidents: [],
    chartMin: 0,
    chartMax: 6,
    bucketsPerDay: 24,
    zoneOptions: [],
    smartZones: [],
    incidentTypes: [
      { name: "All Types", value: "all" },
      { divider: true },
      { name: "Entry", value: "entry" },
      { name: "Motion", value: "motion" },
      { name: "Service Request", value: "service_request" },
      { name: "Temperature", value: "temperature" },
      { name: "Humidity", value: "humidity" },
    ],
    selectedType: null,
    selectedZone: null,
  }),
  computed: {
    loading() {
      const { smartZones, incidents } = this.$apollo.queries;
      return smartZones.loading || incidents.loading;
    },
    dateRange() {
      return {
        after: getDaysAgo(7),
      };
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          sort: "name",
        };
      },
      update({ smartZones }) {
        const selectableZones = [
          {
            id: "all",
            name: "All Zones",
          },
          {
            divider: true,
          },
        ];

        smartZones.reduce((acc, zone) => {
          // must have devices and at least one incident-type config enabled
          if (zone.devices.length) {
            let enabled = false;

            if (
              zone.profile.motionConfigs?.find(
                ({ mode, disabled }) => mode === "motion" && !disabled
              )
            ) {
              enabled = true;
            } else if (
              enabled ||
              zone.profile.entryConfig?.disabled === false
            ) {
              enabled = true;
            } else if (
              enabled ||
              zone.profile.climateConfig?.disabled === false
            ) {
              enabled = true;
            } else if (
              enabled ||
              zone.profile.serviceRequestConfig?.disabled === false
            ) {
              enabled = true;
            }

            if (enabled) acc.push({ id: zone.id, name: zone.name });
          }

          return acc;
        }, selectableZones);

        return selectableZones;
      },
    },
    incidents: {
      query: zoneIncidents,
      variables() {
        const vars = {
          dateTimeFilter: this.dateRange,
          sort: "startedAt desc",
        };

        if (this.selectedType && this.selectedType !== "all") {
          vars.type = this.selectedType;
        }

        if (!this.selectedZone || this.selectedZone === "all") {
          vars.parentId = this.currentFacilityId;
        } else {
          vars.organizationId = this.selectedZone;
        }

        return vars;
      },
      update({ incidents }) {
        // Track the max event count for the chart scale/boundary
        let maxCount = 0;

        let data_array = this.initializeDataArray(0);
        for (let inc of incidents) {
          let ts = this.formatDateTime(inc.startedAt);
          const dataElem = data_array.find(({ x }) => x == ts);
          if (dataElem) {
            dataElem.y += 1;
            if (dataElem.y > maxCount) {
              maxCount = dataElem.y;
            }
          }
        }
        this.chartMax = maxCount + Math.ceil(maxCount / 20);
        if (this.chartMax == 0) {
          this.chartMax = 1;
        }
        let color = this.getColor();
        return {
          datasets: [
            {
              label: `${titleCase(
                this.selectedType
              )} Incidents from the last Week`,
              borderColor: color,
              pointBackgroundColor: color,
              pointBorderColor: color,
              showLine: true,
              data: data_array,
            },
          ],
        };
      },
    },
  },
  methods: {
    formatDateTime(datetime) {
      if (!datetime) return datetime;
      const now = DateTime.local();
      const dt = DateTime.fromISO(datetime, {
        zone: this.facilityTimeZone,
      });
      const diff = now.diff(dt, "hours");
      return Math.floor(diff.hours);
    },
    initializeDataArray(initialValue) {
      let data_array = [];
      for (let i = 0; i < 7 * 24; i++) {
        data_array.push({
          x: i,
          y: initialValue,
        });
      }
      return data_array;
    },
    getColor() {
      if (this.selectedType == "all") {
        return "#F44336";
      } else if (this.selectedType == "entry") {
        return "#673AB7";
      } else if (this.selectedType == "motion") {
        return "#03A9F4";
      } else if (this.selectedType == "humidity") {
        return "#4CAF50";
      } else if (this.selectedType == "tempertature") {
        return "#FFEB3B";
      } else {
        return "#FF5722";
      }
    },
    titleCase,
  },
};
</script>
