<template>
  <DialogLayout
    v-model="isIncidentHistoryOpen"
    :title="task.name"
    width="1000px"
  >
    <dl class="property-list my-4 two-columns">
      <dt>Task ID</dt>
      <div>
        {{ task.id }}
      </div>
      <dt>Task Name</dt>
      <div>
        {{ task.name }}
      </div>
      <dt>Task Status</dt>
      <div>
        <v-select
          v-model="selectedStatus"
          style="max-width: 250px"
          dense
          :items="['To Do', 'In Progress', 'In Review', 'Done']"
        />
      </div>
      <dt>Task Type</dt>
      <div>
        {{ task.type }}
      </div>
      <dt>Due Date</dt>
      <div>
        <v-text-field
          v-model="date"
          style="max-width: 250px"
          prepend-icon="mdi-calendar"
          readonly
          solo
          dense
        />
      </div>
      <dt>Assignee</dt>
      <div>
        <v-select
          v-model="selectedAssignee"
          style="max-width: 250px"
          dense
          :items="['Helen(Facility Manager)', 'John (Facility Admin)']"
        />
      </div>
      <dt>Task Priority</dt>
      <div>
        <SeverityChip :severity="task.priority" />
      </div>
      <dt>Task Description</dt>
      <div>
        <v-textarea v-model="selectedComment" rows="3" row-height="15" />
        <input type="file" />
      </div>
    </dl>
    <div class="d-flex justify-end mb-5">
      <v-btn color="grey lighten-2" @click="isIncidentHistoryOpen = false">
        Done
      </v-btn>
    </div>
    <v-tabs v-model="selectedTab">
      <v-tab>History</v-tab>
      <v-tab>Comments</v-tab>
    </v-tabs>

    <CardLayout v-if="selectedTab === 0" title="History">
      <v-timeline>
        <v-timeline-item color="rgba(204, 31, 36, 1)">
          <CardLayout title="Open" style="width: 250px">
            <p>Tue, Apr 23 2024, 2:50 p.m</p>
            <p><v-icon>mdi-account-check</v-icon> Helen(Facility Manager)</p>
          </CardLayout>
        </v-timeline-item>

        <v-timeline-item
          v-if="selectedDisposition !== 'Open'"
          class="text-right"
          icon="mdi-delta"
          color="grey"
        >
          24 Hours
        </v-timeline-item>

        <v-timeline-item
          v-if="selectedDisposition !== 'Open'"
          color="rgba(249, 203, 0, 1)"
        >
          <CardLayout title="In Progress" style="width: 250px">
            <p>Wed, Apr 24 2024, 2:50 p.m</p>
            <p><v-icon>mdi-account-check</v-icon> Helen(Facility Manager)</p>
          </CardLayout>
        </v-timeline-item>

        <v-timeline-item
          v-if="['In Review', 'Closed'].includes(selectedDisposition)"
          class="text-right"
          icon="mdi-delta"
          color="grey"
        >
          24 Hours
        </v-timeline-item>

        <v-timeline-item
          v-if="['In Review', 'Closed'].includes(selectedDisposition)"
          color="rgba(45, 46, 117, 1)"
        >
          <CardLayout title="In Review" style="width: 250px">
            <p>Thu, Apr 25 2024, 2:50 p.m</p>
            <p><v-icon>mdi-account-check</v-icon> John(Facility Admin)</p>
          </CardLayout>
        </v-timeline-item>

        <v-timeline-item
          v-if="selectedDisposition === 'Closed'"
          class="text-right"
          icon="mdi-delta"
          color="grey"
        >
          24 Hours
        </v-timeline-item>

        <v-timeline-item
          v-if="selectedDisposition === 'Closed'"
          color="rgba(10, 199, 60, 1)"
        >
          <CardLayout title="Closed" style="width: 250px">
            <p>Fri, Apr 26 2024, 2:50 p.m</p>
            <p><v-icon>mdi-account-check</v-icon> John(Facility Admin)</p>
          </CardLayout>
        </v-timeline-item>
      </v-timeline>
    </CardLayout>
    <CardLayout v-else title="Comments">
      <div>
        <v-textarea
          rows="2"
          row-height="15"
          style="max-width: 300px"
          label="Add Comment"
        />
        <v-btn small color="primary">Save</v-btn>
        <v-btn small>Cancel</v-btn>
      </div>
      <v-card>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(comment, index) in comments" :key="index">
              <v-list-item-avatar>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{
                  comment.author
                }}</v-list-item-title>
                <v-list-item-subtitle class="grey--text">{{
                  comment.date
                }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ comment.text }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list>
        </v-card-text>
      </v-card>
    </CardLayout>
  </DialogLayout>
</template>

<script>
import DialogLayout from "@layout/DialogLayout.vue";
import CardLayout from "@layout/CardLayout.vue";
import SeverityChip from "./SeverityChip.vue";
export default {
  components: {
    DialogLayout,
    CardLayout,
    SeverityChip,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    selectedDisposition: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      selectedTab: "history",
      comments: [
        {
          author: "Helen(Facility Manager)",
          date: "June 14, 2024",
          text: "This is a dummy comment 1",
        },
        {
          author: "John(Facility Admin)",
          date: "June 14, 2024",
          text: "This is a dummy comment 2",
        },
        {
          author: "Helen (Facility Manager)",
          date: "June 14, 2024",
          text: "This is a dummy comment 3",
        },
      ],

      selectedAssignee: this.task.assignee,
      selectedStatus: "To Do",
      date: this.task.dueDate,
      selectedComment: this.task.comments,
    };
  },
  computed: {
    isIncidentHistoryOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    "task.status": function (newStatus) {
      this.selectedStatus = newStatus;
    },
    "task.dueDate": function (newDate) {
      this.date = newDate;
    },
    "task.comments": function (newComment) {
      this.selectedComment = newComment;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}
</style>
